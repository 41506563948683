import { NgModule } from '@angular/core';
import { InfoModalComponent } from './info-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';
import { GenericModalModule } from '../generic-modal/generic-modal.module';

@NgModule({
    declarations: [
        InfoModalComponent
    ],
    exports: [
        InfoModalComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        GenericModalModule,
    ]
})

export class InfoModalModule { }
