<ng-container *ngIf="!isLoading">
    <button
        (click)="loginWithSSO()"
        type="button"
        class="bg-white text-customGray-dark font-normal px-4 py-3 rounded-md shadow-card w-full"
    >
        <div class="flex justify-center items-center gap-6">
            <span>{{ i18n.login_with_sso }}</span>
        </div>
    </button>
</ng-container>

<ng-container *ngIf="isLoading">
    <div class="py-3">
        <app-bullets-loader></app-bullets-loader>
    </div>
</ng-container>
