import { Component, EventEmitter, Input, Output, ElementRef, ViewChild, AfterViewInit } from '@angular/core';

export interface TreeNode {
    id: number;
    label: string;
    external_id: string;
    parent_external_id?: string;
    children: TreeNode[];
    selected?: boolean;
    expanded?: boolean;
}

@Component({
    selector: 'app-custom-tree-select',
    templateUrl: './custom-tree-select.component.html',
    styleUrls: ['./custom-tree-select.component.scss'],
})
export class CustomTreeSelectComponent implements AfterViewInit {
    @Input() treeData: any[] = [];
    @Input() treeDataOriginal: any[] = [];
    @Input() isRoot = true;
    @Input() level = 0;
    @Input() position = 'bottom';
    @Input() selected: TreeNode | null = null;
    @Output() nodeSelected = new EventEmitter<TreeNode | null>();
    @Output() closeTree = new EventEmitter<boolean>();
    @Input() readonly = false;

    @ViewChild('treeContainer', { static: false }) treeContainer!: ElementRef;

    isOpenTree = true;

    ngAfterViewInit() {
        this.scrollToSelectedNode();
    }

    ngOnChanges() {
        if (this.isOpenTree) {
            setTimeout(() => this.scrollToSelectedNode(), 100);
        }
    }

    toggleNode(node: TreeNode): void {
        node.expanded = !node.expanded;
    }

    selectNode(node: TreeNode): void {
        if (this.readonly) {
            return;
        }

        if (node.selected) {
            node.selected = false;
            this.nodeSelected.emit(null);
        } else {
            this.clearSelection(this.treeDataOriginal);
            node.selected = true;
            this.nodeSelected.emit(node);
        }
    }

    clearSelection(nodes: TreeNode[]): void {
        nodes.forEach((node) => {
            node.selected = false;
            if (node.children && node.children.length > 0) {
                this.clearSelection(node.children);
            }
        });
    }

    close(): void {
        this.isOpenTree = false;
        this.closeTree.emit(true);
    }

    getPositionClass(): string {
        return `position-${this.position}`;
    }

    private scrollToSelectedNode(): void {
        if (this.readonly && this.selected) {
            setTimeout(() => {
                const selectedElement = document.querySelector('.tree-node.selected');
                if (selectedElement) {
                    selectedElement.scrollIntoView({ behavior: 'auto', block: 'center' });
                }
            }, 100);
        }
    }
}
