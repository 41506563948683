import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateServiceLocale } from 'src/app/components/translate/translate.service';
import { AccountsService } from 'src/app/pages/accounts/accounts.service';
import { SharedService } from 'src/app/shared/shared.service';
import { App_settings } from 'src/environments/environment';

import { PlatformModalsService } from '../modals/platform-modals.service';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    searchKeys: any = {
        keyDisciplines: '',
        keyDisciplinesModel: '',
        keyUsers: ''
    };

    i18n: any = [];
    isCookieEnabled$: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

    constructor(
        private sharedService: SharedService,
        public platformModalsService: PlatformModalsService,
        private translateServiceLocale: TranslateServiceLocale,
        private accountsService: AccountsService,
        private http: HttpClient,

    ) { }

    getLocalStorageItem(key) {
    // Verifica se a key é válida
        if (
            localStorage.getItem(key) !== 'null'
        && localStorage.getItem(key) !== null
        && localStorage.getItem(key) !== undefined
        && localStorage.getItem(key) !== ''
        ) {
            return true;
        }
        return false;
    }

    checkVersion() {
        let updatedVersion: any = this.getUpdatedVersion();
        updatedVersion = this.tranformVersionIntoNumeric(updatedVersion);

        let currentVersion: any = this.getCurrentVersion();
        currentVersion = this.tranformVersionIntoNumeric(currentVersion);

        this.updateCurrentLanguage();

        if (currentVersion !== updatedVersion && this.getLocalStorageItem('appVersion')) {
            this.updateLocalStorage();
        }
    }

    updateLocalStorage() {
        this.platformModalsService.toggle('loading');
        this.updateTranslationsLocalStorage();
        this.updateSiteMetadataLocalStorage();
    }

    updateCurrentLanguage() {
        this.sharedService.getSelectedLanguage();
        const currentLanguage = localStorage.getItem('currentLanguage');

        if (currentLanguage) {
            if (currentLanguage.startsWith('pt') && currentLanguage !== 'pt-BR') {
                localStorage.removeItem('currentLanguage');
                this.sharedService.getSelectedLanguage();
                this.updateLocalStorage();
            }
        }
    }

    updateSiteMetadataLocalStorage() {
        this.accountsService.whoami().subscribe({
            next: (whoami) => {
                if (whoami?.customization) {
                    const settingsGeneral = whoami?.customization;
                    localStorage.setItem('site_metadata', JSON.stringify({ settingsGeneral }));
                    location.reload();
                }
            },
            error: (error) => {
                this.platformModalsService.toggle('loading', '', 'close');
                this.platformModalsService.toggle('message', error.error.error, 'close');
                this.sharedService.throwError('Error trying to fetch whoami endpoint');
            }
        });
    }

    updateTranslationsLocalStorage(callback?: (file?: any) => void) {
        const uri = localStorage.getItem('gcsInfraPath');
        const locale: any = this.sharedService.getSelectedLanguage();
        this.translateServiceLocale.getTranslationsFile(uri, locale)
            .subscribe({
                next: (file) => {
                    localStorage.setItem('translations', JSON.stringify(file));
                    this.setVersionApp();
                    if (callback) callback(file);
                },
                error: (error) => {
                    this.platformModalsService.toggle('loading', '', 'close');
                    this.platformModalsService.toggle('message', error.error.error, 'close');
                    this.sharedService.throwError('Error while trying to get translations file');
                    if (callback) callback();
                }
            });
    }
    setVersionApp() {
        localStorage.setItem('appVersion', JSON.stringify(App_settings.VERSION));
    }

    getUpdatedVersion(): string {
        return App_settings.VERSION;
    }

    getCurrentVersion(): string {
        let appVersion = JSON.parse(localStorage.getItem('appVersion') || 'null');

        if (appVersion == null) {
            this.setVersionApp();
            appVersion = App_settings.VERSION;
        }

        return appVersion;
    }

    tranformVersionIntoNumeric(version: any): number {
        const v = version.replaceAll('.', '');
        return parseFloat(v);
    }

    getCalendarTranslation(): Observable<any> {
        const currentLanguage = localStorage.getItem('currentLanguage');
        return this.http.get(`./assets/i18n-calendar/${currentLanguage}.json`);
    }

    // Verifica se cookies do navegador estão habilitados
    // passando valor para o observable isCookieEnabled$
    checkCookiesEnabled() {
        this.isCookieEnabled$.next(this.sharedService.isCookiesEnabled());
        if (!this.sharedService.isCookiesEnabled()) {
            throw new Error('Error: Cookies are disabled');
        }
    }

    isNotStandalone(): boolean {
        if (this.getLocalStorageItem('provider')) {
            return localStorage.getItem('provider') !== 'GOOGLE_CLOUD_STORAGE';
        }

        return true;
    }

    isGoogle(): boolean {
        return localStorage.getItem('provider') === 'GOOGLE_DRIVE';
    }

    checkAppIsLoaded(): boolean {
        const requiredKeys = [
            'redirect_option',
            'allowedProviders',
            'provider',
            'languages',
            'gcsInfraPath',
            'main_logo',
            'login_background',
            'login_image',
            'support_email',
            'advanced',
            'translations',
            'authentication'
        ];

        return requiredKeys.every((key) => this.getLocalStorageItem(key));
    }
}
