import {
    Component, Input,
    OnInit
} from '@angular/core';

@Component({
    selector: 'app-progress-bar',
    templateUrl: './progress-bar.component.html',
    styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent implements OnInit {
    @Input() value = 0;

    radius = 20;
    circumference: number = 2 * Math.PI * this.radius;

    ngOnInit(): void {
        if (this.value < 0) this.value = 0;
        if (this.value > 100) this.value = 100;
    }
}
