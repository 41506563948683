<app-generic-modal>
    <div class="container">
        <div class="message">
            <span
                class="iconify"
                [style]="{ width: '60px', height: '60px', color: icon_color }"
                [attr.data-icon]="custom_icon"
            ></span>
            <span [innerHtml]="message"></span>
        </div>

        <div class="decision-btns center">
            <button
                class="btn backward"
                (click)="emitClose($event)"
                tabindex="1"
            >
                {{ backward_btn }}
            </button>
        </div>
    </div>
</app-generic-modal>
