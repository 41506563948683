import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomTreeSelectComponent } from './custom-tree-select.component';

@NgModule({
    declarations: [
        CustomTreeSelectComponent
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CustomTreeSelectComponent
    ]
})
export class CustomTreeSelectModule { }
