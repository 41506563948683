import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SettingsAdvancedService {
    i18n: any;

    constructor(
        private http: HttpClient,
        public sharedService: SharedService,
    ) {
        this.i18n = sharedService.getTranslationsOf('Settings');
    }

    getAdvancedSettings(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/settings/advanced/`);
    }

    getClassroomSettings(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/settings/classroom/`);
    }

    getUrlScopes(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/settings/get-classroom-scopes/`);
    }

    confirmClassroomScopes(): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/settings/confirm-classroom-scopes/`);
    }

    postAdvancedSettings(params: any): Observable<any> {
        return this.http.patch(`${API_Routes.URL}/settings/advanced/`, params);
    }

    postAdvancedGoogleService(params: any): Observable<any> {
        return this.http.post(`${API_Routes.URL}/settings/advanced/activate-google-service`, params);
    }
}
