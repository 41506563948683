<!-- Ementa Modal  -->
<app-generic-modal [isVisible]="isVisibleModalSend">
  <div class="pb-6 pt-4 sm:w-screen container-modal">
    <header class="text-customBlue-default border-b border-customBlue-glass pb-4 px-6">
      <p class="text-center font-bold text-26">{{ title }}</p>
      <p class="text-center">{{ subtitle }}</p>
    </header>
    <section class="p-6">
      <div class="relative">
        <input
          type="text"
          name="search_student"
          id="search_student"
          [(ngModel)]="search_student"
          class="border border-customGray-default text-customGray-default pl-4 pr-12 py-1 rounded w-full"
          autocomplete="off"
        />
        <span
          class="iconify text-22 text-customGray-dark absolute top-1.5 right-4"
          data-icon="iconamoon:search"
        ></span>
      </div>
      <div class="flex flex-col items-end">
        <button
          type="button"
          *ngIf="listUserFiltered.length"
          (click)="checkUncheckAll()"
          class="text-customBlue-default font-medium py-2 my-1"
        >
          <span *ngIf="!masterSelected">
            {{ i18n.disciplines_settings_participants_select_all }}
          </span>
          <span *ngIf="masterSelected">
            {{ i18n.disciplines_settings_participants_unselect_all }}
          </span>
        </button>
        <ul class="w-full flex flex-col gap-4 overflow-y-auto">
          <ng-container
            *ngFor="let item of listUserFiltered"
            [ngClass]="{
              'opacity-50': item.email === userLogged?.email
            }"
          >
            <li class="flex items-center justify-between" *ngIf="item.email !== userLogged?.email">
              <div
                class="flex items-center gap-2"
                pTooltip="{{ item.email }}"
                tooltipPosition="top"
                tooltipStyleClass="custom-tooltip"
              >
                <app-user-frame [img_src]="item?.image" [user_name]="item?.name"></app-user-frame>
                <p class="text-customGray-dark">{{ item.name }}</p>
              </div>

              <ng-container *ngIf="item?.canBeSubject">
                <app-check-button
                  *ngIf="item.email !== userLogged?.email"
                  [iconSize]="22"
                  [active]="item.isSelected"
                  (change)="
                    item.isSelected = !item.isSelected; isAllSelected(item, item.isSelected)
                  "
                ></app-check-button>
              </ng-container>
              <ng-container *ngIf="!item?.canBeSubject">
                <div
                  pTooltip="{{ i18n.disciplines_settings_participants_cannot_be_subject }}"
                  tooltipPosition="top"
                  tooltipStyleClass="bg-customYellow"
                >
                  <span
                    class="iconify text-22 text-inicieColors-orange mr-2"
                    data-icon="mingcute:alert-fill"
                  ></span>
                </div>
              </ng-container>
            </li>
          </ng-container>
        </ul>
      </div>
    </section>
    <footer class="flex items-center justify-center gap-4 px-6">
      <button
        type="button"
        (click)="submit()"
        [disabled]="!selectedUser.length"
        [ngClass]="{
          'opacity-70 cursor-not-allowed': !selectedUser.length
        }"
        class="px-10 py-2 text-customWhite-default font-bold bg-customBlue-default rounded"
      >
        {{ i18n.disciplines_create_button }}
      </button>
      <button
        type="button"
        (click)="closeModal(type)"
        class="px-6 py-2 text-customBlue-default font-bold"
      >
        {{ i18n.disciplines_cancel }}
      </button>
    </footer>
  </div>
</app-generic-modal>
