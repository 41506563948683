<section
  class="px-8 my-8 py-8 shadow-card round-card transition ease-out duration-180 animate-fade-in-down bg-customWhite-default"

>
  <h1 class="text-customBlue-default font-bold text-xl">
    {{ i18n.disciplines_settings_data }}
  </h1>
  <ng-container *ngIf="!isLoading">
    <form class="mt-5 text-customGray-dark" [formGroup]="settingsDataForm">
      <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
        <div class="flex flex-col gap-4">
          <div>
            <label class="mb-1">{{
              i18n.disciplines_settings_data_class_code
            }}</label>
            <input
              class="text-customGray-dark w-full border border-customGray-grayTwo rounded p-2 bg-customWhite-default"
              type="text"
              required
              formControlName="external_id"
              Disabled
              Readonly
            />
          </div>
          <div *appCheckPermissions="'modalities'; type:'list'">
            <label class="mb-1">{{
              i18n.disciplines_settings_data_modality
            }}</label>
            <select
              class="text-customGray-dark w-full border border-customGray-grayTwo bg-customWhite-default rounded p-2"
              required
              formControlName="modality_external_id"
              [(ngModel)]="settingsModalities.external_id"
            >
              <option
                *ngFor="let modalities of settingsModalities"
                [ngValue]="modalities?.external_id"
              >
              {{ translationsModality(modalities.external_id, modalities.name) }}
              </option>
            </select>
            <!-- {{settingsDataForm.controls.modality_external_id.value | json}}   -->
          </div>
          <div class="p-fluid" *appCheckUrlToHide="'disciplines-models'">
            <label class="mb-1" for="date_start">
              {{ i18n.disciplines_settings_data_start_date }}
            </label>
            <app-picker-calendar
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="true"
            [initialDate]="initialDate"
            [Form]="settingsDataForm"
            [inputNameControlPicker]="'start_date'"
            [inputControlNameMask]="'calendarStartDate'"
            [position]="'absolute'"
          ></app-picker-calendar>

          </div>
          <div>
            <label class="my-1">{{
              i18n.disciplines_settings_data_workload
            }}</label>
            <input
            class="text-customGray-dark w-full border border-customGray-grayTwo rounded p-2"
            type="number"
            required
            formControlName="workload"
          />
          </div>
          <div>
            <div class="w-full flex items-center gap-1" pTooltip="{{i18n.disciplines_text_header}}" tooltipPosition="top" pRipple>
              <label class="my-1 truncate">{{ i18n.disciplines_text_header }}</label>
              <button type="button" (click)="displayPanelBanner.toggle()">
                <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
              </button>
              <!-- Tooltip -->
              <div class="relative">
                <app-display-panel
                  #displayPanelBanner
                  [style]="{ position: 'absolute' }"
                  [custom_radius]="'10px'"
                  [hide_arrow]="true"
                  [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                  [newStyle]="{ left: '-7rem', top: '-17.125rem' }">
                  <small class="text-customGray-dark font-normal">
                    {{ i18n.disciplines_text_header_desc }}
                  </small>
                  <img class="object-contain h-full"
                  src="../../../../../../../assets/png/img-upload-color-header.png" alt="image">
                </app-display-panel>
              </div>
            </div>
            <div class="flex w-full border border-customGray-grayTwo rounded px-2 bg-customWhite-default">
              <input
                #pickerHeaderText
                class="my-2 mr-2 bg-transparent cursor-pointer shadow"
                [style.background]="header_text_color.value"
                [(colorPicker)]="header_text_color.value"
                (colorPickerChange)="onEventLog('colorPickerChange-header_text_color', $event)"
                style="width:1.3rem; border-radius: 50%; height: 1.3em; color: transparent"
              />
                <input (click)="pickerHeaderText.click()" #header_text_color formControlName="header_text_color" type="text" class="text-customGray-dark w-full bg-transparent py-2" style="border-color: #A9AEB3;" readonly>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div>
            <label class="mb-1">{{
              i18n.disciplines_settings_data_class_name
            }}</label>
            <input
              class="text-customGray-dark w-full border border-customGray-grayTwo rounded p-2"
              type="text"
              required
              formControlName="title"
            />
          </div>
          <div *appCheckPermissions="'levels'; type:'list'">
            <label class="myb1">{{
              i18n.disciplines_segment
            }}</label>
            <select
              class="text-customGray-dark w-full border border-customGray-grayTwo bg-customWhite-default rounded p-2"
              required
              formControlName="level_external_id"
              [(ngModel)]="settingsLevels.external_id"
            >
              <option
                *ngFor="let levels of settingsLevels"
                [ngValue]="levels?.external_id"
              >
              {{ translationsSegment(levels.external_id, levels.name) }}
              </option>
            </select>
            <!-- {{settingsDataForm.controls.level_external_id.value | json}}   -->
          </div>
          <div class="p-fluid" *appCheckUrlToHide="'disciplines-models'">
            <label class="mb-1" for="date_end">{{
              i18n.disciplines_settings_data_end_date
            }}</label>
            <app-picker-calendar
            [i18n]="i18n"
            [currentLanguage]="currentLanguage"
            [editMode]="true"
            [initialDate]="initialDate"
            [Form]="settingsDataForm"
            [inputNameControlPicker]="'end_date'"
            [inputControlNameMask]="'calendarEndDate'"
            [position]="'absolute'"
          ></app-picker-calendar>
          </div>
          <div>
            <label class="my-1">{{
              i18n.disciplines_settings_data_period
            }}</label>
            <input
              class="text-customGray-dark w-full border border-customGray-grayTwo rounded p-2"
              type="text"
              required
              formControlName="period"
            />
          </div>
          <div>
            <div class="flex items-center gap-1" pTooltip="{{i18n.disciplines_text_card}}" tooltipPosition="top" pRipple>
              <label class="my-1 truncate">{{ i18n.disciplines_text_card }}</label>
              <button type="button" (click)="displayPanelCard.toggle()">
                <span class="iconify-inline text-19 text-customGray-default" data-icon="carbon:help"></span>
              </button>
              <!-- Tooltip -->
              <div class="relative">
                <app-display-panel
                  #displayPanelCard
                  [style]="{ position: 'absolute' }"
                  [custom_radius]="'10px'"
                  [hide_arrow]="true"
                  [panel_style]="{ background: '#FEF8E6', width: '20.5rem'}"
                  [newStyle]="{ left: '-16.5625rem', top: '-17.125rem' }">
                  <small class="text-customGray-dark font-normal">
                    {{ i18n.disciplines_text_card_desc }}
                  </small>
                  <img class="object-contain h-full"
                  src="../../../../../../../assets/png/img-update-color-card.png" alt="image">
                </app-display-panel>
              </div>
            </div>
            <div class="flex w-full border border-customGray-grayTwo rounded px-2 bg-customWhite-default">
              <input
                #pickerCardText
                class="my-2 mr-2 bg-transparent cursor-pointer shadow"
                [style.background]="card_text_color.value"
                [(colorPicker)]="card_text_color.value"
                (colorPickerChange)="onEventLog('colorPickerChange-card_text_color', $event)"
                style="width:1.3rem; border-radius: 50%; height: 1.3em; color: transparent"
              />
                <input (click)="pickerCardText.click()" #card_text_color formControlName="card_text_color" type="text" class="text-customGray-dark w-full bg-transparent py-2" style="border-color: #A9AEB3;" readonly>
            </div>
        </div>
        <div class="flex w-full justify-end mt-2 mb-2">
            <div>
                <app-custom-ou-tree-select
                    class="w-full"
                    [treeData]="orgUnits"
                    [selected]="selectedOrgunit"
                    [titleSize]="12"
                    [readonly]="true"
                    [positionTree]="'bottom-right'"
                    (nodeSelectedEmit)="onNodeSelected($event)"
                ></app-custom-ou-tree-select>
            </div>
        </div>
        </div>
    </div>
      <!-- <h2 class="font-bold mt-4 text-customBlue-default">
        {{ i18n.disciplines_settings_data_options }}
      </h2>
      <div class="flex justify-between mb-4 mt-1">
        <label>{{ i18n.disciplines_settings_data_enable_v_pounds }}</label>
        <input
          class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
          id="toogle"
          type="checkbox"
          formControlName="accessibility_enabled"
        />
      </div> -->
      <div class="flex flex-wrap items-center justify-between gap-2 mt-4" *ngIf="enableSync">
        <p class="text-customGray-dark text-16">{{ i18n.disciplines_stop_replicate_sync }}</p>
        <input
          class="relative w-11 transition-all duration-200 ease-in-out bg-customGray-light rounded-full shadow-inner outline-none appearance-none cursor-pointer"
          id="toogle"
          type="checkbox"
          formControlName="replicate_sync"
          (change)="checkChangeSync()"
          [attr.disabled]="!settingsData.replica_sync ? true : null"
          />
      </div>
      <div class="flex justify-end mt-4">
        <button
          class="rounded-md py-2 px-8 font-bold text-sm bg-customBlue-default"
          [style.color]="'white'"
          [ngClass]="{
            'disabled:opacity-20 cursor-not-allowed': !buttonActive
          }"
          (click)="postDataSettings()"
          [disabled]="!buttonActive"
        >
          {{ i18n.disciplines_settings_data_save }}
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="isLoading">
    <div class="flex justify-center items-center" style="height: 600px;">
      <app-bullets-loader></app-bullets-loader>
    </div>
  </ng-container>
</section>
