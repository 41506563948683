import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { AccountsService } from '../../accounts.service';

declare const google: any;

@Component({
    selector: 'app-sso-login',
    templateUrl: './sso-login.component.html',
    styleUrls: ['./sso-login.component.scss']
})
export class SSOLoginComponent implements OnInit {
    isLoading: boolean;
    i18n: any = [];

    subscription: Subscription[] = [];

    constructor(
        private accountsService: AccountsService,
        private sharedService: SharedService,
        public platModalService: PlatformModalsService,
        private localStorageService: LocalStorageService,
        private router: Router
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.getAllowerProvider();
        this.getGoogleCodeAuthentication();
    }

    getGoogleCodeAuthentication() {
        const sub = this.accountsService.getCodeAuthentication$.subscribe({
            next: (redirectObj) => {
                let loginType = null;

                if (redirectObj) {
                    loginType = redirectObj.loginAuthObj;
                    if (Object.prototype.hasOwnProperty.call(redirectObj, 'queryParams')
                        && Object.prototype.hasOwnProperty.call(loginType, 'ssoLogin')) {
                        this.authGoogle(redirectObj.queryParams.code);
                    }
                }
            }
        });

        this.subscription.push(sub);
    }

    getAllowerProvider() {
        const authenticationObj = JSON.parse(localStorage.getItem('authentication') || '');
        if (authenticationObj !== '' && authenticationObj) {
            this.verifyAllowerProviderExists(authenticationObj);
            return;
        }

        const sub = this.accountsService.update_localstorage.subscribe((authentication) => {
            this.verifyAllowerProviderExists(authentication);
        });

        this.subscription.push(sub);
    }

    verifyAllowerProviderExists(authentication) {
        const providers = authentication?.allowedProviders;
        const backendAccount = authentication?.backendAccount;
        if (providers?.length && backendAccount) {
            const googleProvider = providers.find((item) => item.name === 'google');

            if (googleProvider !== undefined && Object.keys(googleProvider).length) {
                this.getGoogleClientId();

                const whoami = {
                    clientId: googleProvider.client_id,
                    apiKey: googleProvider.api_key,
                    appId: googleProvider.app_id,
                    mail: backendAccount
                };

                this.accountsService.setWhoamiCredencials(whoami);
            }
        }
    }

    getTranslations(): void {
        this.i18n = { ...this.sharedService.getTranslationsOf('Login'), ...this.sharedService.getTranslationsOf('Errors') };
    }

    ssoAuthObj: { client_id: '', hosted_domain: '', state: '' };
    getGoogleClientId() {
        this.isLoading = true;
        const allowedProviders = JSON.parse(localStorage.getItem('allowedProviders') || '');
        if (Array.isArray(allowedProviders) || allowedProviders !== undefined || allowedProviders !== '') {
            allowedProviders.forEach((element) => {
                if (element.name === 'sso' && element.client_id !== '' && element.client_id !== undefined) {
                    this.ssoAuthObj = {
                        client_id: element.client_id,
                        hosted_domain: element.hosted_domain,
                        state: element.state
                    };
                }
            });
        } else {
            throw new Error('Plataform settings is undefined');
        }
        this.isLoading = false;
    }

    loginWithSSO() {
        const ssoObj = { ssoLogin: true };
        localStorage.setItem('loginAuthObj', JSON.stringify(ssoObj));
        this.isLoading = true;
        let oAuthURL = 'https://accounts.google.com/o/oauth2/v2/auth?';
        if (this.ssoAuthObj) {
            oAuthURL += 'response_type=code';
            oAuthURL += `&client_id=${encodeURIComponent(this.ssoAuthObj.client_id)}`;
            oAuthURL += `&redirect_uri=${encodeURIComponent(window.location.origin)}`;
            oAuthURL += '&scope=openid profile email';
            oAuthURL += '&include_granted_scopes=true';
            oAuthURL += '&access_type=offline';
            oAuthURL += '&prompt=select_account';
            oAuthURL += `&hd=${encodeURIComponent(this.ssoAuthObj.hosted_domain)}`;
            oAuthURL += `&state=${encodeURIComponent(this.ssoAuthObj.state)}`;
            location.href = oAuthURL;
            return;
        }

        localStorage.removeItem('googleAuthObj');

        throw new Error('Unable to perform google login. Plataform client_id is undefined');
    }

    checkGoogleUser(user: any) {
        if (user) {
            this.authGoogle(user);
        } else {
            localStorage.setItem('logOut', 'true');
        }
    }

    authGoogle(code: any) {
        this.isLoading = true;
        const params = {
            code
        };

        this.accountsService.loginWithGoogle(params)
            .subscribe({
                next: (response) => {
                    this.accountsService.getCodeAuthentication$.next(null);
                    localStorage.removeItem('loginAuthObj');

                    // Se usuário não aceitou os termos, abre modal e encaminha objeto de
                    // resposta de
                    this.sharedService.storeUserCredentials(response);
                    if (response.terms !== null) {
                        this.accountsService.authObject$.next(response);
                        this.accountsService.openModalPolicyAndPrivacy$.next({ openModal: true, readOnly: false });
                    } else {
                        this.accountsService.setGoogleCredencials(response);
                        this.sharedService.loggedIn.next(true);
                        this.accountsService.isRedirectRoute();
                    }
                },
                error: (err) => {
                    this.isLoading = false;
                    let message = `${this.i18n?.error_system_error}`;

                    if (err.status === 422) {
                        message = `${this.i18n?.[err?.error?.key_error]}`;
                    }

                    if (err.status === 404) {
                        message = `${this.i18n?.error_not_profile_login}`;
                    }

                    this.platModalService.toggle('message', message, 'close');

                    this.sharedService.loggedIn.next(false);
                },
                complete: () => { this.isLoading = false; }
            });
    }
}
