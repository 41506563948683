import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output
} from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';

export interface TreeNode {
    id: number;
    label: string;
    external_id: string;
    parent_external_id?: string;
    children: TreeNode[];
    selected?: boolean;
    expanded?: boolean;
}

@Component({
    selector: 'app-custom-ou-tree-select',
    templateUrl: './custom-ou-tree-select.component.html',
    styleUrls: ['./custom-ou-tree-select.component.scss']
})
export class CustomOuTreeSelectComponent implements OnInit {
    @Input() treeData: any[] = [];
    @Input() readonly = false;
    @Input() selected: TreeNode | null = null;
    @Input() positionTree = 'bottom-right';
    @Input() titleSize = 16;
    @Output() nodeSelectedEmit = new EventEmitter<TreeNode | null>();

    isOpenTree = false;
    i18n: any;

    constructor(
        private elementRef: ElementRef,
        private sharedService: SharedService
    ) { }

    ngOnInit(): void {
        this.getTranslations();
    }

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('OrgUnits') };
    }

    toggleTree(): void {
        if (this.treeData.length === 0) {
            this.sharedService.openInfoModal('info', this.i18n.ou_error_tree_unit);
            return;
        }

        this.isOpenTree = !this.isOpenTree;
    }

    nodeSelected(node: TreeNode | null): void {
        console.log('selected', this.selected);
        if (this.readonly) {
            return;
        }

        this.selected = node;
        this.closeTree();
        this.nodeSelectedEmit.emit(node);
    }

    closeTree(): void {
        this.isOpenTree = false;
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event): void {
        if (this.isOpenTree && !this.elementRef.nativeElement.contains(event.target)) {
            this.closeTree();
        }
    }
}
