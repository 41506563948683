import { SharedService } from 'src/app/shared/shared.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CertificatesValidatorService } from '../../certificates-validator.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';


@Component({
  selector: 'app-validate',
  templateUrl: './validate.component.html',
  styleUrls: ['./validate.component.scss'],
})
export class ValidateComponent implements OnInit {
  ID_certificate: string = '';
  i18n: any = {};
  validData: any = {};
  loading: boolean = true;

  constructor (
    private sharedService: SharedService, 
    private route: ActivatedRoute, 
    private router: Router,
    private certificatesValidatorService: CertificatesValidatorService,
    private platModalService: PlatformModalsService,
    private localStorageService: LocalStorageService
    ) { }

  ngOnInit(): void {
    this.getTranslations();
    this.getIdCertificate();
    this.validateCertificates();
  }

  getTranslations(): void {
    if (this.localStorageService.getLocalStorageItem('translations')) {
      this.i18n = { ...this.sharedService.getTranslationsOf('CertificatesValidate') };
    }
  }

  getIdCertificate(): void {
    this.ID_certificate = this.route.snapshot?.params?.id;
  }
  
  validateCertificates() {
    if (this.ID_certificate.length) {
      this.certificatesValidatorService
      .validateCertificate(this.ID_certificate)
      .subscribe({
        next: (response) => {
          this.validData = response;          
          // Timeout colocado para correção do reload caso não haja tradução 
            setTimeout(() => {
              if (Object.keys(this.i18n)) this.loading = false;
            }, 1000);
          },
          error: () => {
            if (Object.keys(this.i18n)) this.loading = false;
            this.showModalMessage(this.i18n.certificates_validator_verify_notfound);
          },
        });
      }
      return;
    }
    
    showModalMessage(message: string): void {
      this.platModalService.toggle(
        'message',
        {
          message: message,
          custom_icon: 'stash:exclamation-triangle-solid',
          icon_color: '#233674',
        },
        {
          finally: () => {
            this.platModalService.close('message');
            this.backToValidator();
        },
      }
    )
  }

  backToValidator(): void {
    this.router.navigate(['/certificates/validator']);
  }
}
