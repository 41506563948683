import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionService } from 'src/app/pages/disciplines/components/content/section.service';
import { SharedService } from 'src/app/shared/shared.service';

import { ResponseSupportMaterial } from './IsupportMaterialsTypes';
import { SupportMaterialsService } from './support-materials.service';

@Component({
    selector: 'app-add-support-materials',
    templateUrl: './add-support-materials.component.html',
    styleUrls: ['./add-support-materials.component.scss']
})
export class AddSupportMaterialsComponent implements OnInit {
    showModal: boolean;
    showModalSection: boolean;
    editMode: boolean;
    section_external_id: string;
    section_title: string;
    selected = '';

    currentLanguage: string | null;
    i18n: any = [];
    contentTypes: any = [];

    supportMaterials: ResponseSupportMaterial[] = [];

    @Input() isTeacher = false;
    @Input() isAdmin = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private sectionService: SectionService,
        public sharedService: SharedService,
        public supportMaterialService: SupportMaterialsService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnInit(): void {
        this.getTranslations();
        this.loadContentTypes();
        this.loadMaterialsSupport();
    }

    loadMaterialsSupport() {
        this.supportMaterialService.getSupportMaterial().subscribe((response) => {
            this.supportMaterials = response;
        });
    }

    openModalContent() {
        this.showModal = true;
        this.section_external_id = '';
        this.section_title = '';
    }

    closeModalContent() {
        this.showModalSection = false;
        this.showModal = false;
        this.selected = '';
    }

    loadContentTypes() {
        this.contentTypes = [
            { type: 'File', name: `${this.i18n.disciplines_page_file}` },
            { type: 'Youtube', name: `${this.i18n.disciplines_youtube}` },
            { type: 'TextPage', name: `${this.i18n.disciplines_text_page}` },
            { type: 'Link', name: `${this.i18n.disciplines_link}` },
        ];
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
            ...this.sharedService.getTranslationsOf('SupportMaterials'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    selectContent(filter: string) {
        this.selected = filter;
    }

    addAssignment() {
        this.sectionService.criterions = [];
        this.sharedService.getAssignment(this.selected);
        this.router.navigate(
            [
                '../content-assignment/support-materials',
                {
                    type: this.selected
                },
            ],
            { relativeTo: this.route }
        );
    }

    getDescriptionText(description: string) {
        if (!description) {
            return ' ';
        }

        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(description, 'text/html');

        const decodedText = parsedHtml.documentElement.textContent || '';

        return decodedText.replace(/<[^>]+>/g, '');
    }

    goToEditSupportMaterial(material: ResponseSupportMaterial) {
        const queryParams = {
            content: material?.external_id,
            section: '',
            section_title: '',
            type: material?.type,
            edit: 1,
        };

        this.router.navigate(['../content-assignment/support-materials', queryParams], {
            relativeTo: this.route,
        });
    }

    goToSupportMaterial(material: ResponseSupportMaterial) {
        const queryParams = {
            section: '',
            type: material?.type,
            isSupportMaterial: true,
        };

        this.router.navigate(
            [`../support-material-view/${material.external_id}`, queryParams],
            { relativeTo: this.route }
        );
    }

    deleteSupportMaterial(supportMaterialExternalId: string) {
        this.supportMaterialService.deleteSupportMaterial(supportMaterialExternalId).subscribe((response) => {
            this.supportMaterials = this.supportMaterials.filter((supportMaterial) => supportMaterial.external_id !== supportMaterialExternalId);
        });
    }
}
