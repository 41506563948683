<app-box-login [background_login]="backgroundLogin" [image_side_login]="imageSideLogin" [hasBackButton]="authentication.enable_app_communication ? true : false" [i18n]="i18n" *ngIf="!userHasToken">
    <img [src]="logoLogin" class="accounts__logo_width" alt="Logo" onerror="this.src='../../../assets/png/empty_img.png'"/>

    <div class="text-center">
        <p class="text-22 font-medium">{{ i18n.login_welcome }}</p>
        <p class="text-14">{{ i18n.login_access_account }}</p>
    </div>

    <div class="w-full flex flex-col gap-6">
        <app-standalone-login *ngIf="allowedProviders.password" class="block"></app-standalone-login>

        <div class="text-12 text-center" *ngIf="providers.length > 1 && allowedProviders.password">
            <hr class="text-customGray-mediumGray" />
            <p class="mt-2">{{ i18n.login_with }}</p>
        </div>

        <app-google-login *ngIf="allowedProviders.google" class="block mx-auto w-full max-width-form"></app-google-login>

        <app-sso-login *ngIf="allowedProviders.sso" class="block mx-auto w-full max-width-form"></app-sso-login>

        <app-microsoft-login *ngIf="allowedProviders.microsoft" class="block mx-auto w-full max-width-form"></app-microsoft-login>
    </div>

    <app-contact-support [i18n]="i18n" [support_email]="supportEmail"></app-contact-support>

    <app-use-terms></app-use-terms>
</app-box-login>

<app-modal-reset-password></app-modal-reset-password>
<app-modal-reset-validation-code></app-modal-reset-validation-code>
<app-modal-use-terms></app-modal-use-terms>
<app-modal-policy-and-privacy></app-modal-policy-and-privacy>
  