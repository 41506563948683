import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { DisciplineSettingsGradesService } from './discipline-settings-grades.service';
import { collapseAnimation } from 'src/app/utils/animations/animations';

@Component({
  selector: 'app-discipline-settings-grades',
  templateUrl: './discipline-settings-grades.component.html',
  styleUrls: ['./discipline-settings-grades.component.scss'],
  animations: [
    collapseAnimation('250')
  ],
})
export class DisciplineSettingsGradesComponent implements OnInit {
  settingsGradesForm: UntypedFormGroup;
  gradesSettings: any = [];
  isLoading: boolean = false;
  buttonUnlocked: boolean = false;
  approval_grade_select: boolean = false;
  discipline_external_id: any;
  isCheck = {
    isCheckTrueConcept: false,
    isCheckTrueNumber: false,
  };

  i18n: any = [];

  constructor(
    private disciplineSettingsGradesService: DisciplineSettingsGradesService,
    private FormBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private platModalService: PlatformModalsService
  ) {}

  ngOnInit(): void {
    this.getTranslations();
    this.getDisciplineExternalId();
    this.getGradesSettings();
    this.createGradesForm();
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Grades'), ...this.sharedService.getTranslationsOf('Disciplines') };
  }

  createGradesForm() {
    this.settingsGradesForm = this.FormBuilder.group({
      concept_enable: ['', Validators.required],
      numeric_enable: ['', Validators.required],
      rubric_enable: ['', Validators.required],
      can_edit_rubric_grade: ['', Validators.required],
      average_rounding_enable: ['', Validators.required],
    });
  }

  get concept_enable() {
    return this.settingsGradesForm.get('concept_enable');
  }

  get numeric_enable() {
    return this.settingsGradesForm.get('numeric_enable');
  }

  get rubric_enable() {
    return this.settingsGradesForm.get('rubric_enable');
  }

  get can_edit_rubric_grade() {
    return this.settingsGradesForm.get('can_edit_rubric_grade');
  }

  get average_rounding_enable() {
    return this.settingsGradesForm.get('average_rounding_enable');
  }

  getDisciplineExternalId() {
    this.route?.parent?.parent?.params.subscribe((params) => {
      this.discipline_external_id = params.id;
    });
  }

  setValueForm(settingsGrades: any) {
    this.settingsGradesForm.controls['concept_enable'].setValue(
      settingsGrades?.concept_enable
    );
    this.settingsGradesForm.controls['numeric_enable'].setValue(
      settingsGrades?.numeric_enable
    );
    this.settingsGradesForm.controls['rubric_enable'].setValue(
      settingsGrades?.rubric_enable
    );
    this.settingsGradesForm.controls['can_edit_rubric_grade'].setValue(
      settingsGrades?.can_edit_rubric_grade
    );
    this.settingsGradesForm.controls['average_rounding_enable'].setValue(
      settingsGrades?.average_rounding_enable
    );
  }

  ableCheckEvaluation(checkbox: any) {
    if (checkbox == 'isCheckTrueConcept') {
      this.settingsGradesForm.controls['concept_enable'].setValue(true);
      this.settingsGradesForm.controls['numeric_enable'].setValue(false);
      this.gradesSettings.concept_enable = true;
      this.gradesSettings.numeric_enable = false;
    } else {
      this.settingsGradesForm.controls['concept_enable'].setValue(false);
      this.settingsGradesForm.controls['numeric_enable'].setValue(true);
      this.gradesSettings.numeric_enable = true;
      this.gradesSettings.concept_enable = false;
    }
  }

  getGradesSettings() {
    this.isLoading = true;
    this.disciplineSettingsGradesService
      .getGradesSettings(this.discipline_external_id)
      .subscribe((gradesSettings) => {
        this.gradesSettings = gradesSettings;
        this.setValueForm(gradesSettings);
        this.isLoading = false;
      });
  }

  patchGradesSettings() {
    this.isLoading = true;
    let concept_enable = this.settingsGradesForm.controls.concept_enable.value;;
    let numeric_enable = this.settingsGradesForm.controls.numeric_enable.value;
    let rubric_enable = this.settingsGradesForm.controls.rubric_enable.value;
    let can_edit_rubric_grade = this.settingsGradesForm.controls.can_edit_rubric_grade.value;
    let average_rounding_enable = this.settingsGradesForm.controls.average_rounding_enable.value;

    const params = {
      concept_enable: concept_enable,
      numeric_enable: numeric_enable,
      rubric_enable: rubric_enable,
      can_edit_rubric_grade: can_edit_rubric_grade,
      average_rounding_enable: average_rounding_enable,
      content_weight_enable: false
    };

    this.disciplineSettingsGradesService.patchGradesSettings(this.discipline_external_id, params)
    .subscribe({
      next: () => {
        this.isLoading = false;
      },
      error: (err) => {
        this.isLoading = false;
        if (err.status === 500 || err.status === 409) {
          this.showModalError(this.i18n.grades_error_only_one);
        } else {
          this.showModalError(this.i18n.grades_error_default);
        }
      }
    })
  }

  showModalError(message: string): void {
    this.platModalService.toggle(
      'message',
      {
        message: message,
        custom_icon: 'stash:exclamation-triangle-solid',
        icon_color: '#233674',
      },
      {
        finally: () => {
          this.platModalService.close('message');
        },
      }
    )
  }
}
