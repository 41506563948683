import {
    Component, EventEmitter, Input, OnChanges, Output
} from '@angular/core';
import {
    FormBuilder, FormControl, FormGroup, Validators
} from '@angular/forms';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { GuardiansUsers } from '../../interfaces/user-guardian.interface';
import { UserGuardianService } from '../../services/user-guardian.service';

@Component({
    selector: 'app-modal-add-guardian',
    templateUrl: './modal-add-guardian.component.html',
    styleUrls: ['./modal-add-guardian.component.scss']
})
export class ModalAddGuardianComponent implements OnChanges {
    @Output() closeModalAddGuardian = new EventEmitter();
    @Output() callListGuardian = new EventEmitter();
    @Output() updatedListGuardian = new EventEmitter();
    @Output() sendCPF = new EventEmitter();
    @Input() isVisibleModalAddGuardian = false;
    @Input() sonOfGuardianExternalID = '';
    @Input() CPF = '';
    @Input() i18n: any = {};
    form: FormGroup;
    editMode = false;

    // Loading
    isLoading = false;

    // Validate
    IsInvalidCPF = false;
    isAlreadyExistsCPF = false;

    // Upload
    modalOpen$ = this.sharedService.modalOpen$;
    fileName = '';
    fileImage = '';
    files = [];

    constructor(private userGuardianService: UserGuardianService, private fb: FormBuilder, private platModalService: PlatformModalsService, private sharedService: SharedService) {
        this.createForm();
    }

    ngOnChanges(): void {
        this.isEditMode();
    }

    createForm(): void {
        this.form = this.fb.group({
            cpf: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11), this.validateSearchGuardian.bind(this)]],
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            telephone: [''],
            cellphone: ['', Validators.required],
            image: ['']
        });
    }

    validateSearchGuardian(formControl: FormControl): void {
        this.IsInvalidCPF = false;
        this.isAlreadyExistsCPF = false;

        if (formControl?.value?.length === 11) {
            this.getSearchGuardian(formControl.value);
        }
    }

    getSearchGuardian(CPF: string): void {
        if (CPF) {
            this.userGuardianService.getSearchGuardian(CPF).subscribe({
                next: (response) => {
                    this.setValueForm(response);

                    if (this.editMode) {
                        this.form.controls.email.disable();
                    } else {
                        this.disableInputs(['name', 'email', 'telephone', 'cellphone', 'image']);
                    }
                },
                error: (err) => {
                    if (!this.editMode) {
                        this.enableInputs(['name', 'email', 'telephone', 'cellphone', 'image']);
                    }

                    if (err.error?.errCode === 400 && err.error?.error?.includes('Invalid Cpf')) {
                        this.IsInvalidCPF = true;
                    }

                    if (err.error?.error?.includes('User with this CPF already exists')) {
                        this.isAlreadyExistsCPF = true;
                    }
                }
            });
        }
    }

    setValueForm(data: GuardiansUsers): void {
        this.form.controls.name.setValue(data.name);
        this.form.controls.email.setValue(data.email);
        this.form.controls.telephone.setValue(data.telephone);
        this.form.controls.cellphone.setValue(data.cellphone);
        this.form.controls.image.setValue(data.image_id);

        this.fileName = data.image_name;
        this.fileImage = data.image;
    }

    onSubmit(): void {
        if (this.form.valid && this.sonOfGuardianExternalID && !this.IsInvalidCPF) {
            this.isLoading = true;

            const payload = {
                name: this.form.controls.name.value,
                email: this.form.controls.email.value,
                cpf: this.form.controls.cpf.value,
                cellphone: this.form.controls.cellphone.value,
                telephone: this.form.controls.telephone.value,
                image: this.form.controls.image.value,
                can_impersonate: false
            };

            const method = this.editMode ? 'editGuardian' : 'postGuardian';
            this.userGuardianService[method](this.sonOfGuardianExternalID, payload).subscribe({
                next: () => {
                    this.updatedListGuardian.emit();
                    this.callListGuardianEmitter();
                    this.closeModal();
                },
                error: () => {
                    this.isLoading = false;
                    let message = '';

                    message = this.editMode ? this.i18n.communication_app_error_edit_guardian : this.i18n.communication_app_error_create_guardian;

                    this.platModalService.toggle('message', message, 'close');
                }
            });
        }
    }

    openModalUploadImage(): void {
        this.sharedService.modalOpen();
    }

    receiveFiles($event): void {
        this.files = $event;
        const file = $event[0];

        this.sharedService.replaceLinkFile(this.files);
        this.form.controls.image.setValue(file.id);
        this.fileName = file.file_name;
        this.fileImage = file.public_url;
    }

    callListGuardianEmitter(): void {
        this.callListGuardian.emit(true);
        this.resetForm();
        this.enableInputs(['name', 'email', 'telephone', 'cellphone', 'image']);
    }

    enableInputs(inputsNames: string[]): void {
        if (inputsNames && inputsNames.length) {
            inputsNames.forEach((inputName) => {
                this.form.controls[inputName].enable();
            });
        }
    }

    disableInputs(inputsNames: string[]): void {
        if (inputsNames && inputsNames.length) {
            inputsNames.forEach((inputName) => {
                this.form.controls[inputName].disable();
            });
        }
    }

    resetForm(): void {
        this.form.reset();
        // Upload
        this.files = [];
        this.fileName = '';
        this.fileImage = '';

        // EditMode and CPF
        this.CPF = '';
        this.IsInvalidCPF = false;
        this.isAlreadyExistsCPF = false;
        this.sendCPF.emit(this.CPF);

        this.isLoading = false;
    }

    closeModal(): void {
        this.closeModalAddGuardian.emit(false);
    }

    isEditMode(): void {
        if (this.isVisibleModalAddGuardian && this.CPF) {
            this.editMode = true;
            this.form.controls.cpf.setValue(this.CPF);
        } else {
            this.editMode = false;
        }
    }
}
