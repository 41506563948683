import {
    ChangeDetectorRef,
    Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output
} from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';
import { Subscription } from 'rxjs';
import { AccountsService } from 'src/app/pages/accounts/accounts.service';
// import { SocialAuthService } from '@abacritt/angularx-social-login';
import { NotificationsService } from 'src/app/pages/notifications/notifications.service';
import { SettingsGeneralService } from 'src/app/pages/settings/components/settings-general/settings-general.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    // Outputs
    @Output() onToggleMenu = new EventEmitter();

    // Variables
    buttonLogOut = false;
    user;
    name;
    profiles: any = [];
    selected: any = null;
    profileSelected: any = '';
    socialAuth: any = [];
    display_panel: any = {};
    isNotification = false;

    modalOpenTranslate$ = this.sharedService.modalOpenTranslate$;

    minimumRoleNumber = 1;
    breakpointObserverKey: symbol;

    userIsImpersonating = false;
    i18n: any = [];
    settingsGeneral: any = [];

    defaultImage = '../../assets/svg/loading.svg';
    errorImage = '';

    isAccessibilityVisible = false;
    hasVizualized = true;
    notificationsData: any[] = [];
    isMobile = false;
    subscriptions: Subscription[] = [];
    notificationPermissionStatus = '';
    isIOS = false;

    constructor(
    // private socialAuthService: SocialAuthService,
        private accountsService: AccountsService,
        private sharedService: SharedService,
        private element: ElementRef,
        public platModalService: PlatformModalsService,
        private settingsGeneralService: SettingsGeneralService,
        private notificationsService: NotificationsService,
        private router: Router,
        private cd: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.getSiteMetadata();
        this.getTranslations();
        this.checkUser();
        this.getUser();
        this.sharedService.checkTranslateActive();
        this.getProfiles();

        this.checkProfile();
        this.getMediaQuerySubscription();
        this.getImpersonatingStatus();
        this.getNotifications();
        this.errorImage = '../../assets/png/empty_img.png';
        this.isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        const sub1 = this.notificationsService.getNotifications$.subscribe((isGetNotifications) => {
            if (isGetNotifications) {
                this.getNotifications();
            }
        });

        this.subscriptions.push(sub1);
    }
    currentLanguage: any;

    getTranslations() {
        this.i18n = { ...this.sharedService.getTranslationsOf('Errors'), ...this.sharedService.getTranslationsOf('Header') };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    receiveNotificationBrowserPermissionStatus(notificationBrowserStatus: string) {
        if (notificationBrowserStatus !== '') {
            this.notificationPermissionStatus = notificationBrowserStatus;
        }
        this.cd.detectChanges();
    }

    getImpersonatingStatus() {
        this.sharedService.impersonating.subscribe((impersonate:boolean) => {
            this.userIsImpersonating = impersonate;
        });
    }

    checkUser() {
    // Checa se o usuário está logado verificando se existe um token criado no cookie
        if (localStorage.getItem('token') !== null) {
            this.buttonLogOut = true;
            this.sharedService.loggedIn.next(true);
        }
    }

    logOut() {
        this.sharedService.logOut();
    }

    getUser() {
        if (localStorage.getItem('token') !== null) {
            const data: any = localStorage.getItem('token');
            this.user = jwt_decode(data);
            this.letter();
        }
    }

    letter() {
        const { name } = this.user;
        const initials = name.charAt(0);
        this.name = initials;
    }

    getProfiles() {
        const message = this.i18n.permission_unauthorized;

        if (!this.sharedService.checkPermission('auth_myRoles', 'list')) {
            this.sharedService.showUnauthorizedModal(message);
            this.sharedService.logOut();
            return;
        }
        this.accountsService.getProfiles().subscribe((profiles) => {
            this.sharedService.getUser().subscribe((user:any) => {
                const profileSelected = user?.role?.external_id;
                const profilesArray = [...profiles];
                const indexOfTheProfile = profilesArray.findIndex((profile) => profile.external_id === profileSelected);
                const activeProfile = profilesArray[indexOfTheProfile];

                profilesArray.splice(indexOfTheProfile, 1);
                profilesArray.unshift(activeProfile);

                // Uncomment this to test
                // |
                // v
                // profilesArray.push({name: "teste", external_id: "teste"})
                // this.profiles = profilesArray

                // Comment this to test
                // |
                // v
                profilesArray.length === profiles.length
                    ? this.profiles = profilesArray
                    : this.profiles = profiles;
            });
        });
    }

    checkProfile() {
        this.sharedService.getUser().subscribe((user:any) => {
            this.profileSelected = user?.role?.external_id;
        });
    }

    changeProfile(): void {
        this.accountsService.changeProfile(this.selected).subscribe({
            next: (response) => {
                this.sharedService.storeUserCredentials(response);

                this.router.navigate(['']).then(() => {
                    window.location.reload();
                });
            },
            error: (err) => {
                let message = '';

                if (err.status === 404) {
                    message = this.i18n.header_error_profile_not_found;
                } else if (err.status === 403) {
                    message = this.i18n.header_unauthorized_to_impersonate_admin;
                } else {
                    message = err.error.error;
                }

                this.platModalService.toggle('message', message, 'close');
            },
        });
    }

    selectProfile($event:any, filter: any) {
        $event.stopPropagation();

        if (this.profileSelected !== filter) {
            this.selected = filter;
            this.changeProfile();
        }
    }

    toogleAccessibility() {
        this.isAccessibilityVisible = !this.isAccessibilityVisible;
    }

    toggleMenu() {
        const $expandMenuBtn = this.element.nativeElement.querySelector('.header__expand-btn');
        this.onToggleMenu.emit();
        $expandMenuBtn.classList.toggle('active');
    }

    toggleExpandBtn() {
        const $expandMenuBtn = this.element.nativeElement.querySelector('.header__expand-btn');
        $expandMenuBtn.classList.toggle('active');
    }

    openSwitchRoleSection() {
        const $invisibleFullPageDiv = this.element.nativeElement.querySelector('.invisible-full-page-div');
        const $switchRoleDiamond = this.element.nativeElement.querySelector('.switch-role-diamond');
        const $switchRole = this.element.nativeElement.querySelector('.switch-role');
        const $roleName = this.element.nativeElement.querySelector('.user-profile__role-name');
        $roleName?.classList.toggle('active');
        $switchRole?.classList.toggle('active');
        $switchRoleDiamond?.classList.toggle('active');
        $invisibleFullPageDiv?.classList.toggle('active');
    }

    getMediaQuerySubscription() {
        const breakpointForDisplayPanel = '944px';

        this.breakpointObserverKey = this.sharedService.callFunctionsOnBreakpoint(breakpointForDisplayPanel, {
            after: () => {
                this.display_panel = {
                    arrow: '100%',
                    right: '-15px',
                    top: '60px',
                    trans: 'translateX(0)',
                };
            },
            before: () => {
                this.display_panel = {
                    arrow: '50%',
                    right: '50%',
                    top: '53px',
                    trans: 'translateX(50%)',
                };
            }
        });
    }

    getSiteMetadata() {
        const getSettingsGeneral = setInterval(() => {
            if (localStorage.getItem('site_metadata')) {
                const data = JSON.parse(localStorage.getItem('site_metadata')!);
                if (data.settingsGeneral) {
                    this.settingsGeneral = data.settingsGeneral;
                    clearInterval(getSettingsGeneral);
                }
            }
        }, 200);
    }

    notificationToggle() {
        if (this.isNotification == false) {
            setTimeout(() => {
                this.isNotification = true;
            }, 100);
        } else {
            setTimeout(() => {
                this.isNotification = false;
            }, 100);
        }
    }

    closeNotifications(event) {
        this.isNotification = event;
        setTimeout(() => { this.getNotifications(); }, 500);
    }

    getNotifications() {
        this.notificationsService
            .getNotificationsAll()
            .subscribe({
                next: (notificationsData: any[]) => {
                    this.notificationsService.notificationAll$.next(notificationsData);
                    this.notificationsData = notificationsData;
                    if (notificationsData.length) {
                        this.hasVizualized = notificationsData.every((e) => e.seen === 1);
                    }
                },
                error: (e) => {
                    console.error('Erro no notification do header', e);
                },
                complete: () => {}
            });
    }

    ngOnDestroy() {
        this.sharedService.removeBreakpointObserver(this.breakpointObserverKey);
        if (this.subscriptions.length) {
            this.subscriptions.forEach((subscription) => {
                subscription.unsubscribe();
            });
        }
    }
}
