import { formatDate } from '@angular/common';
import {
    Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';

import { ClassDiaryService } from '../../class-diary.service';

@Component({
    selector: 'app-class-registers',
    templateUrl: './class-registers.component.html',
    styleUrls: ['./class-registers.component.scss']
})
export class ClassRegistersComponent implements OnChanges {
    @ViewChild('classDetailsDropdown') classDetailsDropdown!: ElementRef;

    @Input() classDiaryData;
    @Input() frequencyCalendar;
    @Output() studentsOcorrences = new EventEmitter();
    @Output() updateClassScheduleDetail = new EventEmitter();
    @Output() updateDate = new EventEmitter();
    @Output() textInputs = new EventEmitter();
    @Output() sendDisciplineSettingsDiaryClassRoute = new EventEmitter();

    i18n;
    currentLanguage;

    classRegisterForm: FormGroup;
    editMode = false;
    classScheduleDetails: any = [];

    selectedOption;

    isOpen = false;
    inputDate = '';
    selectedValue: any | null = null;

    classRegisterObject: any = {};
    oldDate = '';

    constructor(
        private sharedService: SharedService,
        private fb: FormBuilder,
        private classDiaryService: ClassDiaryService,
    ) {
        this.i18n = classDiaryService.i18n;
        this.currentLanguage = sharedService.getSelectedLanguage();
        this.classRegisterObject = classDiaryService.classRegisterObject;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (this.classDiaryData) {
                if (Object.prototype.hasOwnProperty.call(this.classDiaryData, 'frequency')) {
                    const { frequency } = this.classDiaryData;

                    this.classRegisterObject.class_schedule_detail = frequency.class_schedule_detail;

                    const stringToDate = formatDate(this.classDiaryService.convertStringToDate(frequency.date, this.currentLanguage), 'yyyy-MM-dd', 'en-US');
                    this.inputDate = this.currentLanguage === 'en' ? formatDate(frequency.date, 'MM/dd/yyyy', 'en-US') : formatDate(frequency.date, 'dd/MM/yyyy', 'pt-BR');
                    this.classRegisterObject.date = stringToDate;
                    this.setValues(frequency);

                    // only get new values from class_schedule_details if is a new date or get new grade schedule attachement
                    if ((frequency.date !== this.oldDate) || this.classDiaryData.isGradeScheduleUpdated) {
                        this.oldDate = frequency.date;
                        this.selectedValue = null;
                        if (Object.prototype.hasOwnProperty.call(this.classDiaryData, 'class_schedule_details')) {
                            this.classScheduleDetails = this.classDiaryData.class_schedule_details;
                        }
                    }
                }
            }
        }
    }

    createForm() {
        this.classRegisterForm = this.fb.group(
            {
                classDate: ['', Validators.required],
            }
        );
    }

    setValues(classRegisters) {
        this.classRegisterObject.intended_content = classRegisters.intended_content;
        this.classRegisterObject.covered_content = classRegisters.covered_content;
        this.classRegisterObject.class_schedule_detail = this.selectedValue;
    }

    toStringHourAndMinutes(time: string): string {
        if (!time) {
            return '';
        }
        const [hours, minutes] = time.split(':');
        if (minutes === '00') {
            return `${hours}h`;
        }

        return `${hours}h${minutes}min`;
    }

    handleChangeDate(formatedDate) {
        this.classRegisterObject.date = formatedDate;

        this.updateDate.emit(this.classRegisterObject);
    }

    changeMonth(event) {
        console.log('event ', event);
        this.classDiaryService.frequencyCalendar$.next(event);
    }

    async updateInput(value, property: string) {
        this.classRegisterObject[property] = await this.sharedService.debounce(
            value
        );
        this.textInputs.emit(this.classRegisterObject);
    }

    toggleDropdown(): void {
        this.isOpen = !this.isOpen;
    }

    selectOption(option: string): void {
        this.selectedValue = option;

        this.classRegisterObject.class_schedule_detail = this.selectedValue;
        this.isOpen = false;
        this.updateClassScheduleDetail.emit(this.classRegisterObject);
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        if (!this.classDetailsDropdown.nativeElement.contains(event.target)) {
            this.isOpen = false;
        }
    }

    openModalOcurrences() {
        const ocurrences = {
            type: 'occurrences',
        };
        this.studentsOcorrences.emit(ocurrences);
        this.classDiaryService.isModalStudentRegistersOpen$.next(true);
    }

    routeToDisciplineSettingsDiaryClass() {
        this.sendDisciplineSettingsDiaryClassRoute.emit();
    }
}
