import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { TitlePageService } from 'src/app/services/titlePageService/title-page.service';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
    selector: 'app-accounts',
    templateUrl: './accounts.component.html',
    styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
    supportEmail = 'https://suporte.inicie.digital/';
    backgroundLogin = '';
    imageSideLogin = '';
    logoLogin = '';

    // Traductions
    i18n: any = {};

    constructor(private sharedService: SharedService, private router: Router, private localStorageService: LocalStorageService, private titleService: TitlePageService) {}

    ngOnInit(): void {
        this.getTranslations();
        this.getWhoamiData();
    }

    getTranslations(): void {
        this.i18n = this.sharedService.getTranslationsOf('Login');
    }

    getWhoamiData(): void {
        // set undefined title to get default title from settings
        this.titleService.setTitleSite(undefined);

        if (this.localStorageService.getLocalStorageItem('main_logo')) {
            this.logoLogin = JSON.parse(localStorage.getItem('main_logo') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_background')) {
            this.backgroundLogin = JSON.parse(localStorage.getItem('login_background') || '');
        }

        if (this.localStorageService.getLocalStorageItem('login_image')) {
            this.imageSideLogin = JSON.parse(localStorage.getItem('login_image') || '');
        }
    }

    accessLogin(isGuardian: boolean): void {
        localStorage.setItem('canAccessLogin', 'true');
        this.router.navigate(isGuardian ? ['./accounts/login-guardian'] : ['/']);
    }
}
