import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RestoreFormattingService {
    // Acessar até a segunda camada do elemento HTML passado e restaura as configurações

    restoreFormatting(parentElement: ElementRef): void {
        const observable = new Observable<void>((observer) => {
            observer.next();
            observer.complete();
        });

        observable.subscribe(() => {
            if (parentElement) {
                [...parentElement.nativeElement.children].forEach((child) => {
                    this.resetChild(child);

                    if ([...child.children].length) {
                        [...child.children].forEach((e) => {
                            this.resetChild(e);
                        });
                    }
                });
            }
        });
    }

    resetChild(child): void {
        const field = child;
        field.style.fontSize = 'revert';
        field.style.fontWeight = 'revert';
        field.style.listStyle = 'revert';

        if (field.tagName.toLowerCase() === 'a') {
            field.style.color = '#3656BF';
            field.style.textDecoration = 'underline';
        }

        if (field.tagName.toLowerCase().includes('ol') || field.tagName.toLowerCase().includes('ul')) {
            field.style.margin = 'revert';
            field.style.padding = 'revert';
        }

        if (field.tagName.toLowerCase() === 'iframe' || field.tagName.toLowerCase() === 'img') {
            field.style.maxWidth = '100%';
            field.style.objectFit = 'contain';
        }
    }
}
