import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AccountsComponent } from './accounts.component';
import { AccountsGuard } from './accounts.guard';
import { LoginGuardianComponent } from './components/login-guardian/login-guardian.component';
import { LoginGuardianCodeComponent } from './components/login-guardian-code/login-guardian-code.component';
import { LoginNormalComponent } from './components/login-normal/login-normal.component';

const routes: Routes = [
    { path: '', component: LoginNormalComponent },
    { path: 'login-guardian', component: LoginGuardianComponent, canActivate: [AccountsGuard] },
    { path: 'login-guardian-code', component: LoginGuardianCodeComponent, canActivate: [AccountsGuard] },
    { path: 'login-choose-access-type', component: AccountsComponent, canActivate: [AccountsGuard] },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountsRoutingModule { }
