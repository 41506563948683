<div class="create-content-quiz">
    <div class="flex flex-col sm:flex-row justify-between gap-3">
        <div class="flex flex-col gap-1 text-customGray-dark">
            <p class="font-bold">{{ i18n.question_bank_selected }}</p>
            <div class="flex items-center gap-2 relative">
                <p>
                    {{ i18n.question_bank_you_have }}
                    <span class="font-bold">{{ points_available.grades }}</span>
                    {{ getTraslatePoints(points_available.grades) }}
                </p>
                <button type="button" (click)="displayPanel.toggle()">
                    <span
                        class="iconify-inline text-18"
                        data-icon="carbon:help"
                    ></span>
                </button>
                <!-- Tooltip -->
                <div class="relative">
                    <app-display-panel
                        #displayPanel
                        [style]="{ position: 'absolute' }"
                        [custom_radius]="'10px'"
                        [hide_arrow]="true"
                        [panel_style]="{
                            background: '#FEF8E6',
                            width: '15.5rem'
                        }"
                        [newStyle]="{ left: '-0.1875rem', top: '-4.125rem' }"
                    >
                        <small class="text-customGray-dark">{{
                            i18n.question_bank_available_points_tooltip
                        }}</small>
                    </app-display-panel>
                </div>
            </div>
            <small
                class="text-customOrange-default"
                *ngIf="points_available.grades"
            >
                {{ i18n.content_quiz_validation_weight }}
            </small>
        </div>
        <div
            class="flex gap-2 flex-col sm:flex-row justify-center sm:justify-end w-full"
        >
            <button
                class="border rounded-md border-customBlue-default w-full leading-4 bank-question"
                (click)="questionPickerVisibility = true"
                style="height: 44px; width: 230px"
            >
                <span
                    class="text-16 text-customBlue-default font-Roboto font-bold"
                >
                    {{ i18n.question_bank_add_question_bank }}
                </span>
            </button>
            <button
                *appCheckPermissions="'trieduc';type:'list'"
                class="border rounded-md border-customBlue-default leading-4 bank-question"
                (click)="questionPickerTrieducVisibility = true"
                style="height: 44px; width: 230px"
            >
                <span
                    class="text-16 text-customBlue-default font-Roboto font-bold"
                >
                    {{ i18n.question_bank_add_question_bank_external }}
                </span>
            </button>
        </div>
    </div>
    <div class="quiz__body">
        <div
            class="questions"
            drag-type="container"
            (mouseenter)="bootstrapDragAndDrop()"
        >
            <div
                class="questions__question"
                *ngFor="let question of questions; let index = index"
                drag-name="question"
                drag-type="item"
                [attr.drag-id]="question.external_id"
            >
                <app-question-bank-question
                    [data]="question"
                    [noEdit]="true"
                    [noSelect]="true"
                    [noTag]="true"
                    [bypassDeletion]="true"
                    [order]="index"
                    (deleteQuestion)="removeQuestion(index)"
                    (sendWeightQuestion)="receiveWeightQuestion($event)"
                >
                </app-question-bank-question>

                <i
                    class="drag-drop-key-icon drag-key"
                    drag-type="key"
                    *ngIf="questions.length > 1"
                ></i>
            </div>
        </div>
    </div>
</div>

<app-pick-questions-from-bank
    *ngIf="questionPickerVisibility"
    [selectedQuestions]="questions"
    (addQuestions)="addQuestions($event)"
    (close)="questionPickerVisibility = false"
>
</app-pick-questions-from-bank>

<app-pick-question-from-trieduc
    *ngIf="questionPickerTrieducVisibility"
    [selectedQuestions]="questions"
    (addQuestions)="addQuestionsTrieduc($event)"
    (closePicker)="questionPickerTrieducVisibility = false"
>
</app-pick-question-from-trieduc>

<app-info-modal></app-info-modal>
