import { CdkDragDrop, CdkDragEnd } from '@angular/cdk/drag-drop';
import { formatDate } from '@angular/common';
import {
    Component, ElementRef, HostListener, OnDestroy, OnInit,
    QueryList,
    ViewChildren
} from '@angular/core';
import {
    FormControl, UntypedFormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, PrimeNGConfig } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ConditionalAndCriteriaService } from 'src/app/components/conditional-advance/conditional-and-criteria.service';
import { DisplayPanelComponent } from 'src/app/components/display-panel/display-panel.component';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { MessengerToastBaseService } from 'src/app/components/messenger-toast/messenger-toast-base/messenger-toast-base.service';
import { DragAndDropListLib } from 'src/app/libs/drag-drop-list.lib';
import { SubmissionsService } from 'src/app/pages/disciplines/components/content/components/homework-submissions/submissions.service';
import { SectionService } from 'src/app/pages/disciplines/components/content/section.service';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { DisciplinesModelsService } from 'src/app/shared/disciplines-models.service';
import { SharedService } from 'src/app/shared/shared.service';
import { collapseAnimation } from 'src/app/utils/animations/animations';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators';

import { ContentService } from './content.service';

@Component({
    selector: 'app-content',
    templateUrl: './content.component.html',
    styleUrls: ['./content.component.scss'],
    providers: [ConfirmationService],
    animations: [
        collapseAnimation('250')
    ],
})
export class ContentComponent implements OnInit, OnDestroy {
    sections: any = [];
    contentForm: UntypedFormGroup;
    count = 0;
    completeContentTotal = 0;
    date: Date = new Date();
    now: number = this.date.getTime();
    user: any;
    showModal: boolean;
    showModalSection: boolean;
    showModalExam: boolean;
    editMode: boolean;
    selected = '';
    externalIds: any = [];
    section: any;
    section_external_id: string;
    section_title: string;
    public discipline_external_id: string;
    countFirst = 0;
    hasHideContents: boolean;
    isContentLocked: any = [];
    isLockedClass: any;
    selectedValue: any;
    contentTypes: any = [];
    currentLanguage: string | null;
    i18n: any = [];
    isShowConditionalAdv = true;

    thisSectionId: any;
    filteredSelect: any = [];
    conditionalAdvance = false;
    toggle_hidden_section = false;
    sectionPinnedOrder: any = [];
    currentDate: string;

    // Export disciplines
    isExportContentsModalOpen$ = this.disciplinesModelsService.isExportContentsModalOpen$;
    disciplnesToModal: any = [];
    disciplineCode: string | number;
    templatesSections: any = [];
    paramsToImport: any = {};
    templates: any = [];
    sendExternalIDContentToExport = '';
    isModalFileSendOpen = false;

    subscription: Subscription[] = [];

    initialDate: Date = new Date();

    sessionExpanded = false;

    isLoading = false;
    disciplineDetail: any = {};

    classInHover = '';

    selectedExam: any;
    contentSelected: any;

    loadContentTypes() {
        this.contentTypes = [
            { type: 'File', name: `${this.i18n.disciplines_page_file}` },
            { type: 'Youtube', name: `${this.i18n.disciplines_youtube}` },
            { type: 'TextPage', name: `${this.i18n.disciplines_text_page}` },
            { type: 'Link', name: `${this.i18n.disciplines_link}` },
            { type: 'Assignment', name: `${this.i18n.disciplines_docs_assignment}` },
            { type: 'Forum', name: `${this.i18n.disciplines_forum}` },
            { type: 'InternalQuiz', name: `${this.i18n.disciplines_upload_quiz}` },
            { type: 'UploadQuiz', name: `${this.i18n.disciplines_upload_quiz_external}` },
            // { type: 'External', name: `${this.i18n.disciplines_external}` },
            { type: 'Scorm', name: `${this.i18n.disciplines_scorm}` },
            { type: 'LTI', name: `${this.i18n.disciplines_page_lti}` },
            { type: 'Conference', name: `${this.i18n.disciplines_conference}` },
        ];

        if (this.disciplineDetail?.tabs_settings?.assessments_visible) {
            this.contentTypes.splice(8, 0, { type: 'PrintedExam', name: `${this.i18n.disciplines_printed_exam}` });
        }
    }

    assignment: string;

    constructor(
        private conditionalAndCriteriaService: ConditionalAndCriteriaService,
        private primengConfig: PrimeNGConfig,
        private contentService: ContentService,
        private sectionService: SectionService,
        public sharedService: SharedService,
        private route: ActivatedRoute,
        private router: Router,
        private FormBuilder: UntypedFormBuilder,
        private confirmationService: ConfirmationService,
        private submissionsService: SubmissionsService,
        private elementRef: ElementRef,
        private dragDropListLib: DragAndDropListLib,
        private platModalsService: PlatformModalsService,
        private disciplineDetailService: DisciplineDetailService,
        private disciplinesModelsService: DisciplinesModelsService,
        private toastMessenger: MessengerToastService,
        private toastBaseMessenger: MessengerToastBaseService,
        private localStorageService: LocalStorageService,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        this.getProvider();
        this.getDisciplineDetail();
        this.getTranslations();
        this.getDisciplineId();
        this.primengConfig.ripple = true;
        this.getSections();
        this.getUser();
        this.createContentForm();
        // this.filterSelectSections(this.sections)
        this.elementRef.nativeElement.ownerDocument.body.style.overflow = 'clip';
        this.loadContentTypes();
        this.platModalsService.close('loading');
        setTimeout(this.getScreenSize.bind(this), 500);
    }

    provider = '';
    getProvider() {
        this.provider = (localStorage.getItem('provider') || '');
    }

    getDisciplineDetail() {
        const sub1 = this.disciplineDetailService.disciplineBasic$
            .subscribe({
                next: (disciplineDetail) => {
                    this.disciplineDetail = disciplineDetail;
                }
            });

        this.subscription.push(sub1);
    }

    getDisciplineId() {
        this.discipline_external_id = this.router?.url.split('/')[2];
    }

    bootstrapDragAndDrop() {
        const data = {
            elementRef: this.elementRef,
            callBackFn: this.handleDragAndDrop.bind(this),
        };

        this.dragDropListLib.bootstrap(data);
    }

    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Disciplines'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
            ...this.sharedService.getTranslationsOf('Upload'),
            ...this.sharedService.getTranslationsOf('Reports'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    // Forms
    createContentForm() {
        this.contentForm = this.FormBuilder.group({
            nomeSecao: ['', Validators.required],
            hidden_until: [''],
            hidden_section: [''],
            pinned: [''],
            conditional_section_enable: [''],
            conditional_section_external_id: [''],

            startDate: [new Date()],
            calendarHiddenUntilDate: ['', [this.minDateValidator.bind(this)]],
        });

        this.contentForm
            .get('conditional_section_external_id')
            ?.valueChanges.subscribe((value) => {
                this.selectedValue = value;
            });

        this.contentForm
            .get('hidden_section')
            ?.valueChanges.subscribe((value) => {
                this.toggle_hidden_section = value;
            });

        this.contentForm
            .get('conditional_section_enable')
            ?.valueChanges.subscribe((value) => {
                this.conditionalAdvance = value;
            });

        this.contentForm.get('startDate')?.valueChanges.subscribe((value) => {
            const StartDate = value;
            const EndDate = this.contentForm.get('hidden_until');

            if (EndDate !== null) {
                if (StartDate > EndDate.value) {
                    this.contentForm.controls.calendarHiddenUntilDate.setValue(EndDate.value);
                }
            }
        });
    }

    get nomeSecao() {
        return this.contentForm.get('nomeSecao');
    }

    get startDate() {
        return this.contentForm.get('startDate');
    }

    get endDate() {
        return this.contentForm.get('hidden_until');
    }

    getSections() {
        this.isLoading = true;
        this.sectionService.getSections(this.discipline_external_id).subscribe({
            next: (sections) => {
                if (sections.exception !== undefined) {
                    this.sections = [];
                } else {
                    this.sections = sections;
                    this.currentDate = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');

                    this.sections.forEach((item: any) => {
                        this.reorderContents(item.contents);
                    });

                    this.orderSections(this.sections);
                }
                this.isLoading = false;
            },
            error: (err) => {
                this.isLoading = false;
                this.platModalsService.toggle('message', err.error.error, 'close');
            }
        });
    }

    orderSections(items: any) {
        items.sort((a, b) => {
            if (a.pinned !== b.pinned) {
                return a.pinned ? -1 : 1;
            }
            return a.order - b.order;
        });
    }

    getUser() {
        this.sharedService.getUser().subscribe((user: {}) => {
            this.user = user;
        });
    }

    contentComplete(content: { status: string }[]) {
        return (this.count = content.filter((content: { status: string }) => content?.status == 'COMPLETE').length);
    }

    sectionConditionalExplanetion(section) {
        const conditional_external_id = section?.conditional_section_external_id;
        let indexSectionDepends: any;
        let sectionDependsTitle: any;

        indexSectionDepends = this.sections
            ?.findIndex(
                ({ external_id }) => external_id == conditional_external_id
            );
        sectionDependsTitle = this.sections[indexSectionDepends]?.title;

        return ` ${sectionDependsTitle}`;
    }

    openModalContent(section: { external_id: string; title: string }) {
        this.showModal = true;
        this.section_external_id = section?.external_id;
        this.section_title = section?.title;
    }

    closeModalContent() {
        this.showModalSection = false;
        this.showModal = false;
        this.selected = '';
    }

    filterSelectSections(sectionSelect, section_id) {
        this.filteredSelect = sectionSelect
            // .filter(({ external_id }) => external_id != this.section[0].external_id)
            .filter(({ external_id }) => external_id != section_id)
            .map((element) => ({
                id: element.external_id,
                title: element.title,
            }));
    }

    openModalAddSection() {
        this.sections;
        if (this.sections.length == 0) {
            this.isShowConditionalAdv = false;
        } else {
            this.isShowConditionalAdv = true;
        }

        const sectionExternalId = '';
        this.filterSelectSections(this.sections, sectionExternalId);
        this.contentForm.reset();
        this.contentForm.controls.nomeSecao.setValue('');
        this.contentForm.controls.hidden_until.setValue('');
        this.contentForm.controls.hidden_section.setValue(false);
        this.contentForm.controls.pinned.setValue('');
        this.contentForm.controls.conditional_section_enable.setValue(false);
        this.contentForm.controls.conditional_section_external_id.setValue('');

        this.editMode = false;
        this.showModalSection = true;
    }

    editSection(section: { external_id: string }, index) {
        this.section = section;
        this.editMode = true;

        if (this.sections.length <= 1) {
            this.isShowConditionalAdv = false;
        } else {
            this.isShowConditionalAdv = true;
        }

        this.filterSelectSections(this.sections, section.external_id);
        this.contentForm.controls.nomeSecao.setValue(this.section?.title);

        if (this.section?.hidden_until === '') {
            this.contentForm.controls.hidden_until.setValue('');
            this.contentForm.controls.hidden_section.setValue(false);
        } else {
            const end_date_Date = new Date(this.section?.hidden_until);
            this.contentForm.controls.hidden_section.setValue(true);
            this.contentForm.controls.hidden_until.setValue(end_date_Date);
            this.contentForm.controls.calendarHiddenUntilDate.setValue(end_date_Date);
            this.fnPickDateToInput();
        }

        if (this.section?.conditional_section_external_id === '') {
            this.contentForm.controls.hidden_until.setValue('');
            this.contentForm.controls.conditional_section_enable.setValue(false);
        } else {
            this.contentForm.controls.conditional_section_enable.setValue(true);
            this.contentForm.controls.conditional_section_external_id.setValue(
                this.section?.conditional_section_external_id
            );
        }

        this.contentForm.controls.pinned.setValue(this.section?.pinned);
        this.showModalSection = true;
    }

    returnDate(date: string): string {
        return formatDate(date, this.currentLanguage === 'en' ? 'MM/dd/yyyy HH:mm' : 'dd/MM/yyyy HH:mm', this.currentLanguage === 'en' ? 'en-US' : 'pt-BR');
    }

    formataStringDataToEnUS(dateTime) {
        const date = dateTime.split(' ')[0];

        const dia = date.split('/')[0];
        const mes = date.split('/')[1];
        const ano = date.split('/')[2];

        return `${ano}-${(`0${mes}`).slice(-2)}-${(`0${dia}`).slice(-2)} ${dateTime.split(' ')[1]}`;
        // Utilizo o .slice(-2) para garantir o formato com 2 digitos.
    }

    saveSection() {
        this.showModalSection = false;
        this.isLoading = true;
        const nomeSecao = this.contentForm.controls.nomeSecao.value;
        const hidden_section = this.contentForm.controls.hidden_section.value;
        let hidden_until = this.contentForm.controls.hidden_until.value == ''
            && hidden_section == true
            ? ''
            : this.contentForm.controls.hidden_until.value;
        const pinned = this.contentForm.controls.pinned.value == ''
            ? false
            : this.contentForm.controls.pinned.value;
        const conditional_section_enable = this.contentForm.controls.conditional_section_enable.value == ''
            ? false
            : this.contentForm.controls.conditional_section_enable.value;
        const conditional_section_external_id = this.contentForm.controls.conditional_section_external_id.value == ''
            ? null
            : this.contentForm.controls.conditional_section_external_id.value;

        if (hidden_until != '') {
            hidden_until = formatDate(hidden_until, 'yyyy-MM-dd HH:mm:ss', 'en-US');
        }

        if (hidden_section == false) {
            hidden_until = null;
        }

        const params = {
            title: nomeSecao,
            hidden_until,

            pinned,
            conditional_section_enable,
            conditional_section_external_id,
        };

        const paramsPath = {
            title: nomeSecao,
            hidden_until,

            pinned,
            conditional_section_enable,
            conditional_section_external_id: conditional_section_enable ? conditional_section_external_id : null,
        };

        if (this.editMode) {
            this.sectionService
                .updateSection(
                    this.discipline_external_id,
                    this.section.external_id,
                    paramsPath
                )
                .subscribe({
                    next: () => {
                        this.getSections();
                        this.isLoading = false;
                    },
                    error: (err) => {
                        let messageError: string;
                        switch (err.error.message) {
                            case 'Deadlock of Sections.':
                                console.log('TESTE 400: ', err.error.message);
                                messageError = this.i18n.section_deadlock;
                                break;
                            default:
                                console.log(err.error.message);
                                messageError = this.i18n.content_update_section;
                        }
                        this.platModalsService.toggle(
                            'message',
                            {
                                message: messageError,
                                icon_existence: true,
                                icon_color: '#F36C48',
                                custom_icon: 'stash:exclamation-triangle-solid',
                            },
                            'close'
                        );
                        this.isLoading = false;
                        this.verifyModalState();
                    },
                });
        } else {
            this.sectionService
                .postSection(this.discipline_external_id, params)
                .subscribe({
                    next: () => {
                        this.getSections();
                        this.isLoading = false;
                    },
                    error: (err) => {
                        let messageError: string;
                        switch (err.error.message) {
                            case 'Deadlock of Sections.':
                                messageError = this.i18n.section_deadlock;
                                break;
                            default:
                                console.log(err.error.message);
                                messageError = this.i18n.content_add_section;
                        }
                        this.platModalsService.toggle(
                            'message',
                            {
                                message: messageError,
                                icon_existence: true,
                                icon_color: '#F36C48',
                                custom_icon: 'stash:exclamation-triangle-solid',
                            },
                            'close'
                        );
                        this.isLoading = false;
                        this.verifyModalState();
                    },
                });
        }
    }

    verifyModalState() {
        const verifyModal = setInterval(() => {
            const modalState = this.platModalsService.getModalState('message');
            if (modalState.source._value === false) {
                this.showModalSection = true;
                clearInterval(verifyModal);
            }
        }, 500);
    }

    selectContent(filter: string) {
        this.selected = filter;
    }

    goToContent(content, section) {
        if (content?.type === 'PrintedExam') {
            this.showModalExam = false;
            this.selectedExam = content?.exam;
            this.contentSelected = content;
            this.showModalExam = true;
            return;
        }

        const isStudent = this.user === 'student';
        const contentLocked = content?.status === 'LOCKED';

        if (isStudent && contentLocked) return;

        const queryParams = {
            section: section.external_id,
            type: content?.type,
        };

        this.router.navigate(
            [`../content-view/${content.external_id}`, queryParams],
            { relativeTo: this.route }
        );
    }

    addAssignment() {
        this.sectionService.criterions = [];
        this.sharedService.getAssignment(this.selected);
        this.router.navigate(
            [
                '../content-assignment/assignment',
                {
                    type: this.selected,
                    section: this.section_external_id,
                    section_title: this.section_title,
                    edit: 0,
                },
            ],
            { relativeTo: this.route }
        );
    }

    editContent(content, section) {
        console.log('content ', content);

        this.sectionService.criterions = [];
        this.sharedService.getContent(content, section.external_id);
        this.sharedService.getAssignment(content?.type);

        const queryParams = {
            content: content?.external_id,
            section: section.external_id,
            section_title: section.title,
            type: content?.type,
            edit: 1,
        };

        this.router.navigate(['../content-assignment/assignment', queryParams], {
            relativeTo: this.route,
        });
    }

    removeSection(section_external_id: string) {
        this.isLoading = true;
        this.sectionService
            .deleteSection(this.discipline_external_id, section_external_id)
            .subscribe({
                next: () => {
                    this.getSections();
                    // this.isLoading = false
                },
                error: (err) => {
                    let messageError: string;
                    switch (err.error.message) {
                        case 'There is link another section.':
                            console.log('TESTE 400: ', err.error.message);
                            messageError = this.i18n.section_depends_err;
                            break;
                        default:
                            console.log(err.error.message);
                            messageError = this.i18n.content_remove_content;
                    }
                    this.platModalsService.toggle(
                        'message',
                        {
                            message: messageError,
                            icon_existence: true,
                            icon_color: '#F36C48',
                            custom_icon: 'stash:exclamation-triangle-solid',
                        },
                        'close'
                    );
                },
            });
    }

    askToDeleteSection(section_external_id: string) {
        const modalType = 'decision';
        const translationKey = 'deletion_subject_section';
        const functionHandlers = {
            forward: () => this.removeSection(section_external_id),
            finally: () => this.platModalsService.close(modalType),
        };
        this.platModalsService.toggle(modalType, translationKey, functionHandlers);
    }

    removeContent(content_external_id: string, section_external_id: string) {
        this.isLoading = true;
        this.contentService
            .deleteContent(
                this.discipline_external_id,
                section_external_id,
                content_external_id
            )
            .subscribe({
                next: () => this.getSections(),
                error: (err) => {
                    let messageError: string;
                    switch (err.error.error) {
                        case 'There is link another content.':
                            console.log('TESTE 400: ', err.error.error);
                            messageError = this.i18n.content_depends_err;
                            break;
                        default:
                            console.log(err.error.error);
                            messageError = this.i18n.content_remove_content;
                    }
                    this.platModalsService.toggle(
                        'message',
                        {
                            message: messageError,
                            icon_existence: true,
                            icon_color: '#F36C48',
                            custom_icon: 'stash:exclamation-triangle-solid',
                        },
                        'close'
                    );
                    this.isLoading = false;
                },
            });
    }

    askToDeleteContent(section_external_id: string, content_external_id: string, has_students_with_grade: boolean, has_substitute_activity: boolean) {
        if (has_students_with_grade) {
            this.platModalsService.toggle('message', this.i18n.disciplines_error_has_students_with_grade, 'close');
            return;
        }

        const modalType = 'decision';
        let translationKey = 'deletion_subject_content';

        if (!has_students_with_grade && has_substitute_activity) {
            translationKey = this.i18n.disciplines_has_substitute_acitivity_are_you_sure_delete;
        }

        const functionHandlers = {
            forward: () => {
                this.removeContent(section_external_id, content_external_id);
            },
            finally: () => this.platModalsService.close(modalType),
        };

        this.platModalsService.toggle(modalType, translationKey, functionHandlers);
    }

    reorderContents(contents: { order: number }[]): void {
        contents.sort((a, b) => a.order - b.order);
    }

    sectionsDragAndDrop(dragData) {
        const { order } = dragData;
        const params = { order };

        this.toastBaseMessenger.messengerToast('base-info', this.i18n.disciplines_saving_changes, true);
        this.sectionService
            .postSectionsOrder(this.discipline_external_id, params)
            .subscribe({
                next: () => {
                    this.toastBaseMessenger.messengerToast('base-success', this.i18n.disciplines_saved_changes, true);
                    this.getSections();// get sections after reorder is necessary because limitation of pinned item placed between no pinned items
                },
                error: (err) => {
                    this.getSections();
                    let messageError: string;
                    switch (err.status) {
                        default:
                            messageError = this.i18n.content_error_default;
                    }
                    this.platModalsService.toggle(
                        'message',
                        {
                            message: messageError,
                            icon_existence: true,
                            icon_color: '#F36C48',
                            custom_icon: 'stash:exclamation-triangle-solid',
                        },
                        'close'
                    );
                },
            });
    }

    handleDragAndDrop(dragData) {
        console.log('dragData ', dragData);

        // extract name property from dragData
        const { name } = dragData;

        // call sectionsDragAndDrop or contentsDragAndDrop
        // method depending on name
        this[`${name}sDragAndDrop`](dragData);
    }

    previousList;
    currentList;
    hasConditionalContent: any = [];
    drop(event: CdkDragDrop<string[]>) {
        this.previousList = event.previousContainer.data;
        this.currentList = event.container.data;
        if (event.previousIndex == event.currentIndex && this.previousList == this.currentList) {
            console.log('event.previousIndex ', event.previousIndex);
            console.log('event.currentIndex', event.currentIndex);
            console.log('this.previousList ', this.previousList);
            console.log('this.currentList ', this.currentList);

            return;
        }
        this.hasUserAcceptedMoveContent(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }

    onDragStarted(event: any): void {
        console.log('onDragStarted');

        document.documentElement.style.setProperty('cursor', 'grabbing');
    }

    onDragEnded(event: CdkDragEnd): void {
        document.documentElement.style.removeProperty('cursor');
    }

    moveContent(previousArray, currentArray, previousIndex, currentIndex) {
        // check if move content between sections or in the same section
        const container = previousArray === currentArray ? currentArray : previousArray;

        const elemento = container.contents.splice(previousIndex, 1)[0];
        currentArray.moved_external_id = elemento.external_id;
        currentArray.contents.splice(currentIndex, 0, elemento);
        console.log('container antes ', container);

        this.saveMoveContent(currentArray);
    }

    hasUserAcceptedMoveContent(previousArray, currentArray, previousIndex, currentIndex) {
        const elemento = previousArray.contents[previousIndex];

        console.log('elemento ', elemento);
        console.log('currentArray ', currentArray);

        if (Object.keys(currentArray).length) {
            if (previousArray.contents) {
                this.hasConditionalContent = previousArray.contents
                    .filter((item) => item.external_id == elemento.external_id && item.conditional_content_enable);

                console.log('this.hasConditionalContent ', this.hasConditionalContent);
                let messageWarning = this.i18n.disciplines_order_contents_updated_warning;
                if (this.hasConditionalContent.length) {
                    messageWarning = this.i18n.disciplines_order_contents_conditional_content_warning.replace('$', this.hasConditionalContent[0].title);
                }

                this.showDecisionModalWarning(
                    messageWarning,
                    () => {
                        // Foward
                        this.moveContent(previousArray, currentArray, previousIndex, currentIndex);
                    },
                    () => {
                        // Backward
                    },
                );
            }
        }
    }

    showDecisionModalWarning(messageError, fowardCallback, backwardCallback) {
        this.platModalsService.toggle(
            'decision',
            {
                message: messageError,
                text_size: 'text-16',
                text_color: 'text-customGray-dark',
                icon_existence: true,
                icon_color: '#F36C48',
                custom_icon: 'stash:exclamation-triangle-solid',
            },
            {
                forward: () => {
                    fowardCallback();
                },
                backward: () => {
                    backwardCallback();
                },
                finally: () => {
                    this.platModalsService.close('decision');
                },
            }
        );
    }

    saveMoveContent(dragData) {
        console.log('dragData ', dragData);
        if (!Object.keys(dragData).length) {
            return;
        }

        const content_external_id = dragData.moved_external_id;
        const section_external_id = dragData.external_id;
        let order = [];

        if (dragData.contents.length) {
            order = dragData.contents.map((item) => item.external_id);
        }

        const params = {
            section_external_id,
            order
        };

        this.contentService
            .postContentsOrder(this.discipline_external_id, section_external_id, content_external_id, params)
            .subscribe({
                next: () => {
                    if (this.hasConditionalContent.length) {
                        this.hasConditionalContent = [];
                        this.getSections();
                    }
                },
                error: (err) => {
                    let messageError: string;
                    switch (err.status) {
                        default:
                            messageError = this.i18n.content_error_default;
                    }
                    this.platModalsService.toggle(
                        'message',
                        {
                            message: messageError,
                            icon_existence: true,
                            icon_color: '#F36C48',
                            custom_icon: 'stash:exclamation-triangle-solid',
                        },
                        'close'
                    );
                },
            });
    }

    seeSubmissions(feed, sectionExternalID: string | number) {
        if (feed?.has_submission) {
            this.router.navigate([
                `disciplines/${this.discipline_external_id}/${sectionExternalID}/homework-submissions/${feed?.external_id}`,
            ]);
        }
    }

    textCut(limit: any, textToCut: any) {
        let text = textToCut;
        const overLimit = text.length > limit;
        const elipsisOrEmpty = overLimit ? '...' : '';
        text = text.substring(0, limit) + elipsisOrEmpty;
        return text;
    }

    contentStatus(status: string, begin: boolean | undefined, locked: boolean) {
        if (locked === true) {
            return '../../../../../assets/svg/padlock.svg';
        } if (locked === false) {
            return this.isContentStatus(status, begin);
        }
    }

    isContentStatus(status: string, begin: boolean | undefined) {
        if (status === 'LOCKED' && begin === true) {
            return '../../../../../assets/svg/circle-empty.svg';
        } if (status === 'LOCKED' && begin === undefined) {
            return '../../../../../assets/svg/padlock.svg';
        } if (status === 'PENDING' && begin === undefined) {
            return '../../../../../assets/svg/circle-empty.svg';
        } if (status === 'COMPLETE' && begin === undefined) {
            return './../../../../assets/svg/circle-check.svg';
        } if (status === 'PENDING' && begin === true) {
            return '../../../../../assets/svg/circle-empty.svg';
        } if (status === 'COMPLETE' && begin === true) {
            return './../../../../assets/svg/circle-check.svg';
        }
    }

    isLocked(status: string, begin: boolean | undefined, locked: boolean) {
        if (locked === true) {
            return true;
        } if (locked === false) {
            return this.isLockedStatus(status, begin);
        }
    }

    isLockedStatus(status: string, begin: boolean | undefined) {
        if (status === 'LOCKED' && begin === true) {
            return false;
        } if (status === 'LOCKED' && begin === undefined) {
            return true;
        } if (status === 'PENDING' && begin === undefined) {
            return false;
        } if (status === 'COMPLETE' && begin === undefined) {
            return false;
        } if (status === 'PENDING' && begin === true) {
            return false;
        } if (status === 'COMPLETE' && begin === true) {
            return false;
        }
    }

    // Função para formatar a data removendo os segundos e timeZone...
    searchFirstPathDate(text: any): string {
        if (!text || text === null || text === undefined) return '';

        let primeiraParte = '';
        let index = 0;

        // Encontrar o índice do segundo ':' na string
        for (let i = 0; i < text.length; i++) {
            if (text[i] == ':' && index < 2) {
                index++;
                if (index == 2) {
                    primeiraParte = text.slice(0, i);
                    break;
                }
            }
        }

        // Se o segundo ':' não for encontrado, retornar a string original
        if (primeiraParte === '') {
            primeiraParte = text;
        }

        return primeiraParte;
    }

    // Export disciplines
    receivesImportData(event) {
        this.postToImportDiscipline(this.paramsToImport, this.templates[0]);
    }

    receivesImportTemplate(modalImportData: any) {
        console.log('modalImportData ', modalImportData);

        this.templates = modalImportData.targetDisciplines;
        const { sourceContents } = modalImportData;
        const { sourceSections } = modalImportData;
        const { targetDisciplines } = modalImportData;
        const targetSections = !modalImportData.targetSections.length ? null : modalImportData.targetSections;

        let { keepDisciplineInSync } = modalImportData;

        keepDisciplineInSync == true ? keepDisciplineInSync = false : keepDisciplineInSync = true;

        this.paramsToImport = {
            keepDisciplineInSync,
            sourceContents,
            sourceSections,
            targetDisciplines,
            targetSections,
            keepDates: this.datesResourcesValues
        };
    }

    getTemplatesSections() {
        this.disciplinesModelsService.isLoadingModalTrue();
        this.templates?.forEach((template) => {
            this.getSectionImportModal(template);
        });
    }

    getDisciplinesToModal(event) {
        this.disciplinesModelsService.isLoadingModalTrue();
        let { page } = event;
        let perPage = event.per_page;

        let { query } = event;
        query == undefined ? query = '' : query = query;

        page != undefined ? page = page : page = 1;
        perPage != undefined ? perPage = perPage : perPage = 8;

        this.disciplinesModelsService
            .getDisciplines(
                'private',
                query,
                '',
                '',
                '',
                '',
                page,
                perPage
            )
            .subscribe({
                next: (data) => {
                    this.disciplnesToModal = data;
                    setTimeout(() => {
                        this.disciplinesModelsService.isLoadingModalFalse();
                    }, 1000);
                },
                error: (err) => console.log('ERROR: ', err?.error?.message),
            });
    }

    getSectionImportModal(discipline: string) {
        this.disciplinesModelsService.isLoadingModalTrue();
        const replicationPermission = '?replication_permission=true';
        this.disciplinesModelsService.getSections(discipline, replicationPermission)
            .pipe().subscribe({
                next: (data) => {
                    this.templatesSections = data;

                    setTimeout(() => {
                        this.disciplinesModelsService.isLoadingModalFalse();
                    }, 1000);
                },
                error: (err) => console.log('ERROR: ', err?.error?.message),
                complete: () => { },
            });
    }

    getSectionsByDisciplines(discipline: string) {
        this.getSectionImportModal(discipline);
    }

    postToImportDiscipline(templateData: any, disciplineCode: string | number) {
        this.toastMessenger.messengerToast('load', this.i18n.disciplines_loading_import, true);
        this.disciplinesModelsService
            .postImportDisciplineOrigin(templateData, this.discipline_external_id)
            .subscribe({
                next: () => {
                    setTimeout(() => {
                        this.toastMessenger.messengerToast('success', this.i18n.disciplines_export_success, true);
                    }, 2000);
                },
                error: (err) => {
                    this.disciplinesModelsService.showMessage(
                        this.i18n.disciplines_have_an_error,
                        `${this.i18n.disciplines_error_message}${err?.error?.message}`,
                        'toast-error'
                    );
                },
            });
    }

    /*  getStatusImport(disciplineCode: any) {
    this.toastMessenger.messengerToast('load', this.i18n.disciplines_loading_import, true)
  } */

    getDisciplineCode(disciplineCode: string | number) {
        this.disciplineCode = disciplineCode;
    }

    openModalImport(): void {
        this.disciplinesModelsService.openExportContentsModal();
    }

    sendContentToExport(external_id: string): void {
        this.sendExternalIDContentToExport = external_id;
    }

    toggleModalFileSend(value: boolean): void {
        this.isModalFileSendOpen = value;
    }

    fnPickDateToInput(calendarDateValue?) {
        // Coloca a data no input vindo do CalendarPicker
        if (calendarDateValue) {
            const format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm', 'en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm', 'pt-BR');
            this.contentForm.controls.calendarHiddenUntilDate.setValue(format);
        }
    }

    minDateValidator(control: FormControl): { [key: string]: any } | null {
        const enteredDate = control;

        let dateMin: Date = new Date();
        let returnValue: any = null;

        if (this.contentForm && !this.editMode) {
            dateMin = this.initialDate;
            returnValue = minDateValidator(dateMin)(enteredDate);
        }

        return returnValue;
    }

    // // Validate Data of p-calendar
    validateMinDate(dateSelected) {
        if (!this.editMode) {
            if (dateSelected < this.initialDate) {
                return this.contentForm.controls.hidden_until.setValue(this.initialDate);
            }
        }
    }

    isExpanded(isExpanded, section_external_id) {
        isExpanded = !isExpanded;
        this.sections
            .filter((section) => section.external_id == section_external_id)
            .map((section) => {
                section.expanded = isExpanded;
            });

        this.updateExpandedSection(section_external_id, isExpanded);
    }

    updateExpandedSection(section_external_id: string, isExpanded: boolean) {
        const params = {
            expanded: isExpanded
        };

        this.contentService.updateExpandedSection(section_external_id, params)
            .subscribe({
                next: () => {

                },
                error: (err) => {
                    this.platModalsService.toggle('message', err.error.error, 'close');
                }
            });
    }

    @ViewChildren('displayPanelMenuMobile') panels!: QueryList<DisplayPanelComponent>;
    displayPanelObject: any = {};

    togglePanel(id: string) {
        const panelArray = this.panels.toArray();
        const panel = panelArray.find((p) => p.uniqueId === id);

        if (panel) {
            panel.toggle();
        }
    }

    isDragBlockOnMobile = false;
    @HostListener('window:resize', ['$event'])
    getScreenSize() {
        if (window.innerWidth < 640) {
            this.isDragBlockOnMobile = true;
            return;
        }
        this.isDragBlockOnMobile = false;
    }

    datesResourcesValues;
    getDatesResourcesValues(datesResourcesValues) {
        this.datesResourcesValues = datesResourcesValues;

        // if (this.isDuplicate) {
        //     this.postDuplicate(this.disciplineCode);
        // }
        // this.disciplinesModelsService.openExportContentsModal();

        // const checkModalImport = setInterval(() => {
        //     console.log('checkModalImport ');

        //     if (this.exportContentsModal) {
        //         console.log('this.exportContentsModal ', this.exportContentsModal);
        //         this.disciplinesModelsService.sectionsAndContentsSelected$.next(this.resourcesData);
        //         this.exportContentsModal.continueImport(false, true);
        //         clearInterval(checkModalImport);
        //     }
        // }, 100);
    }

    checkContentCanReplicate(content): boolean {
        if (content.type === 'Conference' && content.can_replicate_videoconference === false) {
            return false;
        }

        if (content.can_replicate === false) {
            return false;
        }

        return true;
    }

    // -- //
    ngOnDestroy() {
        this.conditionalAndCriteriaService.stopTimer();
        this.elementRef.nativeElement.ownerDocument.body.removeAttribute('style');
        // unsubscribe quando componente for destruído
        if (this.subscription.length) {
            this.subscription.forEach((element) => {
                element.unsubscribe();
            });
        }
    }

    formatDateToExam(dateString: string): string {
        const lang = this.currentLanguage === 'en' ? 'en-US' : 'pt-BR';

        let of = 'de';
        let at = 'às';

        if (lang === 'en-US') {
            of = 'of';
            at = 'at';
        }

        // Converte a string da data para um objeto Date
        const date = new Date(dateString);

        // Opções para formatar a data
        const dayOfWeek = date.toLocaleDateString(lang, { weekday: 'long' });
        const day = date.getDate();
        const month = date.toLocaleDateString(lang, { month: 'long' });
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');

        // Formatar a data no formato desejado
        const formattedDate = `${dayOfWeek} ( ${day} ${of}  ${month} ${at} ${hours}h${minutes})`;

        return formattedDate;
    }

    closeModalExam() {
        this.showModalExam = false;
    }
}
