<section>
    <div class="relative flex flex-col sm:flex-row sm:items-center justify-between mb-5 gap-4">
        <div>
            <h1 class="text-customBlue-default font-bold text-22">{{period?.name}}</h1>
            <p routerLink="../.." class="text-customGray-default text-14 cursor-pointer">{{i18n.grades_back_to_grades}}</p>
        </div>
        <div class="flex items-center p-3 gap-2 bg-customWhite-default border border-customGray-lighterGray rounded-lg text-customGray-default">
            <span class="iconify text-22" data-icon="bi:search"></span>
            <input class="w-full outline-none" [placeholder]="i18n.disciplines_grades_search_for_students" type="text" id="search_student" name="search_student" autocomplete="off" [(ngModel)]="search_post_filter"/>
        </div>
    </div>

    <ng-container *ngIf="period">
        <app-grades-table [grades]="grades" [dad]="'period-detail'" [countCategories]="countCategories"></app-grades-table>
    </ng-container>
</section>
