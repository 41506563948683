import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { DisciplineService } from 'src/app/pages/disciplines/discipline.service';
import { AnnouncementsService } from './announcements.service';
import { SharedService } from 'src/app/shared/shared.service';
import { TimeService } from 'src/app/services/time-services.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { RestoreFormattingService } from 'src/app/shared/restore-formatting.service';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss'],
  providers: [ConfirmationService],
})
export class AnnouncementsComponent implements OnInit {
    // ---- Pagination ----
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;
    paginationParams: any = {};
    activePage = 1;
    per_page = 6;

    @ViewChild(PaginationComponent) paginationAnnouncements: PaginationComponent | any;
    paginationParamsAnnouncements: any = {};
    activePageAnnouncements = 1;
    per_pageAnnouncements = 6;

  // Variables
  announcements: any = [];
  discipline_external_id: string;
  announcement_external_id: string;

  filterTerm: string;
  pDisciplines: number = 1;
  showModalAnnouncement: boolean = false;
  showModalUpdate: boolean = false;
  showModalReplicate: boolean = false;
  user: any;
  announcementForm: UntypedFormGroup;
  displayBasic: boolean = false;
  textError: string = '';
  disciplines: any = [];
  types: any = 'private';
  level: any = '';
  query: any = '';
  modalities: any = '';
  months: any = '';
  years: any = '';
  check: boolean = false;
  allSelected: boolean;
  isLoading: boolean = false;
  i18n: any = [];
  currentLanguage: any;

  disciplineSelected: string[] = [];

  editorDescription: any = {
    mobile: true,
    menubar: 'insert',
    resize: false,
    autoresize_bottom_margin: 10,
    paste_data_images: true,
    content_style: `
    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      color: #cdcdcd;
      cursor: text;
    }
    `,
    plugins: [
      'advlist autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste help wordcount',
      'emoticons autoresize',
    ],
    toolbar:
      'bold italic underline strikethrough | forecolor backcolor | alignleft aligncenter alignright alignjustify | lineheight bullist numlist | copy | code | removeformat |  fontselect | fontsizeselect | formatselect',

    height: 260,
    width: 423,
  };

  constructor(
    private announcementsService: AnnouncementsService,
    private router: Router,
    private FormBuilder: UntypedFormBuilder,
    private confirmationService: ConfirmationService,
    private sharedService: SharedService,
    private disciplineService: DisciplineService,
    private timeService: TimeService,
    private platModalsService: PlatformModalsService,
    private restoreFormattingService: RestoreFormattingService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getDisciplineId();
    this.getAnnouncements();
    this.createAnnouncementForm();
    this.getTranslations();
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  //Forms
  createAnnouncementForm() {
    this.announcementForm = this.FormBuilder.group({
     // codigoExterno: ['', Validators.required],
      tituloAviso: ['', Validators.required],
      mensagemTexto: ['', Validators.required],
    });
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal'), ...this.sharedService.getTranslationsOf('Errors')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  /* get codigoExterno() {
    return this.announcementForm.get('codigoExterno');
  } */

  get tituloAviso() {
    return this.announcementForm.get('tituloAviso');
  }

  get mensagemTexto() {
    return this.announcementForm.get('mensagemTexto');
  }
  // get user info
  getUser() {
    this.sharedService.getUser().subscribe((user) => {
      this.user = user;
      console.log('this.user ', this.user);
    });
  }

  getDisciplineId() {
    this.discipline_external_id = this.router?.url.split('/')[2];
  }

  getDisciplines(
    type: any,
    query: any,
    level: any,
    modalities: any,
    months: any,
    years: any,
    page: number,
    per_page: number,
    callback: any
  ) {
    this.disciplineService
      .getDisciplines(type, query, level, modalities, months, years, page, per_page)
      .subscribe({
        next: (disciplines) => {
          if (disciplines['exception'] !== undefined) {
            this.disciplines = [];
            callback();
          } else {
            this.disciplines = disciplines.items.map((item: any) => ({
              ...item,
              selected: false,
            }));

            if (this.disciplines.length) {
              this.refreshPagination(disciplines);
            }
            callback();
          }
        },
        error: (err) => {
          this.displayBasic = true;
          this.textError = JSON.stringify(err.error.error);
          console.log('err', err);
        }
      })
  }

    getAnnouncements(): void {
        this.isLoading = true;
        const params = { page: this.activePageAnnouncements, per_page: this.per_pageAnnouncements };

        this.announcementsService
            .getAnnouncements(this.discipline_external_id, params)
            .subscribe({
                next: (announcements) => {
                    this.announcements = announcements.data;

                    if (this.announcements.length) {
                        this.refreshPaginationAnnouncements(announcements);
                    }

                    this.isLoading = false;
                },
                error: () => {
                    this.isLoading = false;
                }
            });
    }

  openModalAddAnnouncement() {
    setTimeout(() => this.announcementForm.markAsPristine(), 0);
    setTimeout(() => this.announcementForm.markAsUntouched(), 0);
    setTimeout(() => this.announcementForm.updateValueAndValidity(), 0);
    //this.announcementForm.controls['codigoExterno'].setValue('');
    this.announcementForm.controls['tituloAviso'].setValue('');
    this.announcementForm.controls['mensagemTexto'].setValue('');
    this.showModalAnnouncement = true;
  }

  closeModalAnnouncement() {
    this.showModalAnnouncement = false;
  }

  openUpdateAddAnnouncement(announcement) {
    let title = announcement.title
    let text = announcement.text
    this.announcement_external_id = announcement.external_id

    this.announcementForm.controls['tituloAviso'].setValue(title);
    this.announcementForm.controls['mensagemTexto'].setValue(text);
    this.showModalUpdate = true;
  }

  closeModalUpdate() {
    this.showModalUpdate = false;
  }

  openModalAddReplicate(announcement_external_id: any) {
    this.activePage = 1;
    this.announcement_external_id = announcement_external_id;
    this.getDisciplines(
      this.types,
      this.query,
      this.level,
      this.modalities,
      this.months,
      this.years,
      this.activePage,
      this.per_page,

      () => {
        console.log('this.disciplines ', this.disciplines);
        this.showModalReplicate = true;
        this.disciplineSelected = [];
      }
    );
  }
  closeModalReplicate() {
    this.showModalReplicate = false;
  }

  searchDiscipline() {
    this.getDisciplines(
      this.types,
      this.query,
      this.level,
      this.modalities,
      this.months,
      this.years,
      this.activePage,
      this.per_page,
      () => {
        console.log('this.disciplines ', this.disciplines);
      }
    );
  }

  selectAll(boolean: boolean) {
    //Seleciona ou deseleciona todos da lista
    this.check = !this.check;

    if (boolean) {
      this.disciplines.forEach(discipline => {
        if(!this.disciplineSelected.includes(discipline.external_id)) {
          this.disciplineSelected.push(discipline.external_id)
        }
      });
    } else {
      this.disciplineSelected = [];
    }
  }

  checkSelected(external_id: string) {
    if (this.disciplineSelected.includes(external_id)) {
      this.disciplineSelected.splice(this.disciplineSelected.indexOf(external_id), 1);
    } else {
      this.disciplineSelected.push(external_id);
    }
  }

  addAnnouncement() {
    //let external_id = this.announcementForm.controls.codigoExterno.value;
    let title = this.announcementForm.controls.tituloAviso.value;
    let text = this.announcementForm.controls.mensagemTexto.value;

    const params = {
      //external_id: external_id,
      title: title,
      text: text,
    };

    this.announcementsService.postAnnouncements(this.discipline_external_id, params).subscribe(
        (post) => {
          console.log('post ', post);
          this.showModalAnnouncement = false;
          this.showModalSuccess(this.i18n.announcement_success);
          this.getAnnouncements();
        },
        (err) => {
          if (err.status == 409) {
            this.displayBasic = true;
            this.textError = 'Este código externo já existe';
            console.log('err', err);
          } else {
            this.displayBasic = true;
            this.textError = err.error.message;
            console.log('err', err);
          }
        }
      );
  }

  updateAnnouncement() {
    let title = this.announcementForm.controls.tituloAviso.value;
    let text = this.announcementForm.controls.mensagemTexto.value;
    this.showModalUpdate = false;

    const params = {
      title: title,
      text: text,
    };

    this.announcementsService.patchAnnouncements(this.discipline_external_id, this.announcement_external_id, params).subscribe(
        (post) => {
          console.log('patch ', post);
          this.showModalSuccess(this.i18n.announcement_success);
          this.getAnnouncements();
        },
        (err) => {
            this.textError = 'Houve um erro ao editar este aviso';
            console.log('err', err);
        }
      );
  }

  showModalSuccess(message: string): void {
    this.platModalsService.toggle(
      'message',
      {
        message: message,
        icon_existence: true,
        icon_color: '#80CC28',
        custom_icon: 'gg:check-o',
      },
      'close'
    );
  }

  replicateAnnouncement() {
    const params = {
      replicate_to: this.disciplineSelected,
    };

    this.announcementsService
      .replicateAnnouncement(
        this.discipline_external_id,
        this.announcement_external_id,
        params
      )
      .subscribe(
        (response: any) => {
          console.log('response ', response);
          this.showModalReplicate = false;
          this.showModalSuccess(this.i18n.successful_announcement_replicate)
        },
        (err) => {
          this.displayBasic = true;
          this.textError = err.error.error;
        }
      );
  }

  askToDeleteAnnouncement(announcement_external_id: any) {
    const modalType = 'decision';
    const translationKey = 'deletion_subject_announcement';
    const functionHandlers = {
      forward: () => {
        this.removeAnnouncement(announcement_external_id)
        this.getAnnouncements();
      },
      finally: () => {
        this.platModalsService.close(modalType);
      },
    };
    this.platModalsService.toggle(
      modalType,
      translationKey,
      functionHandlers
    );
  }

  removeAnnouncement(announcement_external_id: any) {
    const modalType = 'message';
    const functionHandlers = {
      finally: () => {
        this.platModalsService.close(modalType);
      },
    };
    this.announcementsService
    .removeAnnouncement(this.discipline_external_id, announcement_external_id)
    .subscribe(
      () => {

        console.log('this.announcements ', this.announcements);
        const translationKey = 'successful_announcement_deletion';
        this.platModalsService.toggle(
          modalType,
          {
            message: translationKey,
            icon_existence: true,
            icon_color: '#80CC28',
            custom_icon: 'gg:check-o',
          },
          functionHandlers
        );
        this.getAnnouncements();
      },
      () => {
        const translationKey = 'error_role_deletion';
        this.platModalsService.toggle(
          modalType,
          translationKey,
          functionHandlers
        );
      },
      () => {
        console.log('DELETE: OK');
      }
    );
  }

  getTime(date) {
    return this.timeService.getTime(date);
  }

    // Pagination
    goToPage({ activePage }): void {
        const page = activePage;

        this.disciplineService
            .getDisciplines(this.types, this.query, this.level, this.modalities, this.months, this.years, page, this.per_page)
            .subscribe({
                next: (disciplines) => {
                    if (disciplines["exception"] !== undefined) {
                        this.disciplines = [];
                    } else {
                        this.disciplines = disciplines.items.map((item: any) => ({
                            ...item,
                            selected: false,
                        }));
                        this.disciplines = disciplines.items;
                        this.activePage = disciplines.page;
                    }
                },
                error: (err) => {
                    this.displayBasic = true;
                    this.textError = JSON.stringify(err.error.error);
                }
            });
    }

    goToPageAnnouncements(event): void {
        const params = { page: event.activePage, per_page: this.per_pageAnnouncements };

        this.announcementsService
            .getAnnouncements(this.discipline_external_id, params)
            .subscribe({
                next: (announcements) => {
                    this.announcements = announcements.data;
                    this.activePageAnnouncements = announcements.meta.current_page;
                },
            });
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage,
            query: this.query
        };

        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    refreshPaginationAnnouncements(response): void {
        this.paginationParamsAnnouncements = {
            items: [...Array(response.meta.total).keys()],
            itemsPerPage: this.per_pageAnnouncements,
            visibleButtons: 5,
            activePage: this.activePageAnnouncements,
            query: this.query
        };

        if (this.paginationAnnouncements) {
            this.paginationAnnouncements.params = this.paginationParamsAnnouncements;
            this.paginationAnnouncements.initPaginationLib(true);
        }
    }

    restoreFormatting(text: string, div: HTMLDivElement): string {
        if (div) {
            const el = new ElementRef(div);
            this.restoreFormattingService.restoreFormatting(el);
        }

        return text;
    }
}
