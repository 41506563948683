import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';
import { ReportsService } from './reports.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  @ViewChild('scroll') scroll: ElementRef;

  showMain: string
  title: string
  reportsData: any = []
  external_id: any = []
  showButtonToTop: boolean;
  isLoading: boolean = false;

  i18n: any = [];
  currentLanguage: any;

  constructor(
    public platModalService: PlatformModalsService,
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.showMain = 'show'
    this.getUrlParams();
    this.getTranslations();
    this.getDisciplines(this.external_id);
    this.whatIsTheScreen('show')
    this.isShowTopButton()
  }

  getTranslations() {
    this.i18n = {...this.sharedService.getTranslationsOf('Reports'),
    ...this.sharedService.getTranslationsOf('Modal'),
    ...this.sharedService.getTranslationsOf('Disciplines')};
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }


  getDisciplines(disipline: any) {
    this.reportsService
      .getReports(disipline)
      .subscribe(
       data => {
        this.reportsData = data
        console.log('data', data);
        this.isLoading = false;
       },
       (err) => {
        this.isLoading = false;
        let message = this.i18n.disciplines_have_an_error
        this.platModalService.toggle(
          'message',
          {
            message: message,
            custom_icon: 'stash:exclamation-triangle-solid',
            icon_color: '#233674',
          },
          {
            finally: () => {
              this.platModalService.close('message');
            },
          }
        )
        console.log('err', err);
      },
       ()=> console.log('ok'),
      )
  }

  getUrlParams() {
    this.route?.parent?.parent?.params
      .subscribe((params) => this.external_id = params.id)
    console.log('PARAMS DA ROTA', this.external_id);
  }

  whatIsTheScreen(screen: any) {
    this.isShowTopButton()
    console.log('WHAT SCREEN', screen);
    switch (screen) {
      case 'resources':
        this.title = this.i18n.reports_resources
        return this.showMain = 'resources'
      case 'show':
        this.title = this.i18n.reports_reports_
         return this.showMain = 'show'
      case 'active-students':
        this.title = this.i18n.reports_active_students
        return this.showMain = 'active-students'
      case 'actives':
        this.title = this.i18n.reports_activies
        return this.showMain = 'actives'
      case 'certificate-reports':
        this.title = this.i18n.reports_certificate_reports
        return this.showMain = 'certificate-reports'
      case 'large-grades':
        this.title = this.i18n.reports_grades
        return this.showMain = 'large-grades'
    }
  }

  gotoTop() {
    var scrollEl = document.querySelector('#moveTop');
    scrollEl!.scrollIntoView();
   }

  isShowTopButton() {
    if (this.showMain == 'show') {
     return this.showButtonToTop = true
    } else {
     return this.showButtonToTop = false
    }
  }

}
