import {
    Component,
    OnInit,
    ElementRef,
    OnDestroy,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    Validators,
    UntypedFormArray,
    FormBuilder,
    FormGroup,
} from '@angular/forms';
import { UsersService } from 'src/app/pages/users/users.service';
import { PermissionsService } from 'src/app/pages/permissions/permissions.service';
import { OrgUnitsService } from 'src/app/pages/org-units/org-units.service';
import { SharedService } from 'src/app/shared/shared.service';
import { OrgUnitsDataFormat } from 'src/app/shared/org-units-data-format.service';
import { ConfirmationService } from 'primeng/api';
import { DisciplineService } from '../disciplines/discipline.service';
import { debounceTime, finalize } from 'rxjs/operators';
import { OverlayPanel } from 'primeng/overlaypanel';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { Router } from '@angular/router';
import { PaginationComponent } from 'src/app/components/pagination/pagination.component';
import { fromEvent, Subscription } from 'rxjs';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';
import { UsersInterface, UsersMetadataInterface } from './interfaces/users.interface';
import { UserAssiduityInterface } from './interfaces/user-assiduity.interface';
import { formatDate } from '@angular/common';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';
import { DisplayPanelComponent } from 'src/app/components/display-panel/display-panel.component';
import { ToastrService } from 'ngx-toastr';
import { MessengerToastService } from 'src/app/components/messenger-toast/messenger-toast.service';
import { GuardiansUsers } from './interfaces/user-guardian.interface';
import { AccountsService } from '../accounts/accounts.service';

export interface TreeNode<T = any> {
    external_id?: string;
    label?: string;
    data?: T;
    icon?: string;
    expandedIcon?: any;
    collapsedIcon?: any;
    children?: TreeNode<T>[];
    leaf?: boolean;
    expanded?: boolean;
    type?: string;
    parent?: TreeNode<T>;
    partialSelected?: boolean;
    style?: string;
    styleClass?: string;
    draggable?: boolean;
    droppable?: boolean;
    selectable?: boolean;
    key?: string;
}
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    providers: [ConfirmationService],
    styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('displayPanel', { static: true }) displayPanel: OverlayPanel;
    @ViewChild('displayPanelType', { static: true }) displayPanelType: DisplayPanelComponent;
    @ViewChild('alertIcon') public alertIcon: ElementRef;
    // ---- Pagination ----
    @ViewChild(PaginationComponent) pagination: PaginationComponent | any;

    // Search
    @ViewChild("search_user") search_user: ElementRef;
    users_search_source: any;

    paginationParams: any = {};
    activePage: number = 1;
    per_page: number = 25;

    // Variables
    formSearch: FormGroup;
    isOpen: boolean = false;
    name: any;
    open: boolean;
    showModal: boolean;
    users: UsersInterface[] = [];
    initials: any;
    userForm: UntypedFormGroup;
    profileForm: UntypedFormGroup;
    vinculateDisciplineForm: UntypedFormGroup;
    // newEnrollmentsForm: FormGroup;
    userSelected: any;
    success = false;
    number: any;
    p: number = 1;
    number1: any = 5;
    p1: number = 1;
    filterDisciplines: string;
    isSelected: boolean = false;
    masterSelected = false;
    checkedList: any[] = [];
    roles: any;
    orgunits: any = [];
    enrollments: any = [];
    newEnrollment: any = [];
    selectedOrgunit: any;
    loading: boolean = true;
    i18n: any = {};
    newEnrollmentForm: UntypedFormGroup;
    orgUnitCheck: boolean = false;

    orgUnits: TreeNode[] = [];
    defineOrgunits: any[] = [];
    iterator: number = 0;
    editMode: boolean = false;
    displayBasic: boolean = false;
    textError: string = '';

    modalOpen$ = this.sharedService.modalOpen$;
    maxFiles: number = 1;
    files: any = [];
    modalUploadOverlay: boolean = false;
    imgUser: string;
    userImage: any;

    display_panel: any = {};

    query: any;

    isLoading: boolean = false;
    enableChecked: boolean = false;

    toggleVinculateDiscipline: boolean = false;

    userLoggedIn: any;

    translationsUpdate$ = this.sharedService.translationsUpdate$;

    isModalProgileImpersonateOpen: boolean = false;
    impersonateUser: any = [];
    arrayAssiduity: UserAssiduityInterface[] = [];
    commissionUser: UsersInterface;
    isModalCommissionTeamOpen: boolean = false;
    refreshCommissionTeam: boolean = false;
    assiduityDateFormated: UserAssiduityInterface[] = [];

    subscription: Subscription;
    langsList: string[] = [];
    metadata: UsersMetadataInterface;

    // Responsible (App Comunicações)
    guardiansList: GuardiansUsers[] = [];
    valueModalListGuardian = false;
    valueModalAddGuardian = false;
    isLoadingGuardian = false;
    valueSonOfGuardianExternalID = '';
    cpfGuardian = '';

    constructor(
        private usersService: UsersService,
        public sharedService: SharedService,
        private permissionsService: PermissionsService,
        private disciplineService: DisciplineService,
        private orgUnitsService: OrgUnitsService,
        private FormBuilder: UntypedFormBuilder,
        private element: ElementRef,
        private orgUnitsDataFormat: OrgUnitsDataFormat,
        private confirmationService: ConfirmationService,
        public platModalService: PlatformModalsService,
        private router: Router,
        private accountsService: AccountsService,
        private localStorageService: LocalStorageService,
        private sidebarService: SidebarService,
        private toastMessenger: MessengerToastService
    ) { }

    ngOnInit(): void {
        this.getLangs();
        this.getUserLoggedIn();
        this.createFormSearch();
        this.createUserForm();
        this.createProfileForm();
        this.loadOrgunits();
        this.getTranslations();
        this.element.nativeElement.ownerDocument.body.style.overflow = 'clip';
        this.loadingGetUsers = true;
    }

    ngAfterViewInit(): void {

        // Verifica se contém a chave
        if (this.localStorageService.getLocalStorageItem('searchKeys')) {
            const storedSearchValue = JSON.parse(localStorage.getItem('searchKeys')!).keyUsers;
            // Coloca o valor no input e na query e depois pesquisa
            this.formSearch.controls.search.setValue(storedSearchValue);
            this.query = storedSearchValue;
            this.searchUser(this.formSearch);


        } else {
            // Não tiver chave, seta ela como vazio e pesquisa (buscar todos)
            this.localStorageService.searchKeys.keyUsers = '';
            localStorage.setItem('searchKeys', JSON.stringify(this.localStorageService.searchKeys))
            this.searchUser(this.formSearch);

        }
    }

    initFormArray(roles: any[]) {
        const formArray = this.profileForm.get(
            'newEnrollments'
        ) as UntypedFormArray;
        roles.map((item) => {
            formArray.push(this.createNewEnrollmentForm(item));
            this.validatingAddPermission.push([]);
        });
        this.profileForm.setControl('newEnrollments', formArray);
    }

    //Forms
    createFormSearch(): void {
        this.formSearch = this.FormBuilder.group({
            search: ['']
        })
    }

    createUserForm(): void {
        this.userSelected = null;

        this.userForm = this.FormBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            name: ['', [Validators.required]],
            userCode: ['', [Validators.required]],
            phone: [''],
            newEnrollments: this.FormBuilder.array([]),
            enrollments: this.FormBuilder.array([]),
            lang: ['']
        });
    }

    createProfileForm() {
        // this.userSelected = null;
        this.profileForm = this.FormBuilder.group({
            newEnrollments: this.FormBuilder.array([]),
            enrollments: this.FormBuilder.array([]),
        });
    }

    createNewEnrollmentForm(roles: any): UntypedFormGroup {
        let newEnrollment: UntypedFormGroup = new UntypedFormGroup({
            role_external_id: new UntypedFormControl(roles.role_external_id, [
                Validators.required,
            ]),
            ou_external_id: new UntypedFormControl(roles.ou_external_id),
        });
        return newEnrollment;
    }

    actionEvent(action: string, index: number): void {
        if (action == 'delete') {
            this.arrayAssiduity.splice(index, 1);
        }
        if (action == 'add') {
            this.arrayAssiduity.push({ time_card: '', start_date: '', notDeletable: false });
        }
    }

    //retorna o arrays de roles dentro do formulário de Users
    get newEnrollments() {
        return this.profileForm.get('newEnrollments') as UntypedFormArray;
    }

    get formArrayEnrollments() {
        return this.profileForm.get('enrollments') as UntypedFormArray;
    }

    get userName() {
        return this.userForm.get('name');
    }

    get userCode() {
        return this.userForm.get('userCode');
    }

    get userEmail() {
        return this.userForm.get('email');
    }

    get userPhone() {
        return this.userForm.get('phone');
    }

    getUserLoggedIn() {
        this.sharedService.getUser().subscribe((user) => {
            this.userLoggedIn = user;
        });
    }

    resetForm() {
        this.newEnrollments.controls = [];
        this.formArrayEnrollments.controls = [];
        this.userForm.reset();
    }

    openModal() {
        this.editMode = false;
        this.resetForm();
        this.resetModal();
        this.imgUser = '';
        this.userImage = null;
        this.showModal = true;
    }

    resetModal() {
        this.arrayAssiduity = [{ time_card: '', start_date: '', notDeletable: false }];
        this.assiduityDateFormated = [];
        this.defineOrgunits = [];
        this.files = [];
        this.arrayRemovePermission = [];
        this.validatingAddPermission = [];
        this.arrayAddPermission = [];
    }

    openEditUserModal(user: UsersInterface) {
        this.resetModal();
        this.resetForm();
        this.editUser(user);
        this.arrayAssiduity = (user.assiduity && user.assiduity.length > 0) ? user.assiduity : [{ time_card: '', start_date: '', notDeletable: false }];
        this.showModal = true;
    }

    formEnrollments: any = [];
    user: any;
    // método de edição do usuário
    editUser(user): void {
        this.resetModal();
        this.resetForm();

        this.editMode = true;
        this.user = user;

        this.imgUser = user.image;
        this.userForm.controls.name.setValue(user?.name);
        this.userForm.controls.userCode.setValue(user?.external_id);
        this.userForm.controls.email.setValue(user?.email);
        this.userForm.controls.phone.setValue(user?.telephone);
        this.userForm.controls.lang.setValue(user?.lang);
        this.loadRolesFromUser(user);
    }

    loadRolesFromUser(user: any) {
        this.iterator = 0;
        this.initFormArray(user.roles);

        // Procura pela orgUnit por cada camada do JSON (Recursivamente)
        // e preenche o dropdown de OrgUnit do perfil do usuário
        user.roles.forEach((element: any) => {
            this.findOrgunitRecursive(this.orgUnits, element.ou_external_id);
            this.iterator++;
        });

        // user.roles.includes("sadmin")

        // this.enrollments = JSON.parse(JSON.stringify(user.organizational_units));
        // this.checkOrgunit();
        this.formEnrollments = this.newEnrollments.controls.map((item: any) => {
            return item.value;
        });
    }

    arrayAddPermission: any = [];
    validatingAddPermission: any = [];

    addPermission(index, role_external_id, OU_node) {
        const paramsUpdateEnrollment = {
            role_external_id: role_external_id,
            user_external_id: this.userForm.controls.userCode.value,
            enrollable_external_id: OU_node?.external_id,
            enrollable_type: 'OU',
        };

        this.arrayAddPermission = this.newEnrollments.controls.map((item: any) => {
            return item.value;
        });

        // Verifica se no select de Role e de Orgunit algum não está preenchido
        if (
            typeof role_external_id == null ||
            typeof OU_node == null ||
            role_external_id == undefined ||
            OU_node == undefined
        ) {
            // se não estiver, o botão do formulário é desabilitado
            this.orgUnitCheck = false;
            // this.validatingAddPermission[index]['isInvalid'] = true
        } else {
            // preenche lista com empty para validação
            this.validatingAddPermission.map((item: any) => {
                return (item.isInvalid = 'empty');
            });

            // Se tiver mais que um item no array valida
            if (
                this.newEnrollments.controls.length >= 1 ||
                this.formArrayEnrollments.controls.length >= 1
            ) {
                this.loopProfilePermissions();
                this.validatePermissionsUser();
            } else {
                this.checkOrgunit();
            }
        }
    }

    // método que confere a lista de permissões do User para
    // verificar se existem duplicados
    loopProfilePermissions() {
        if (this.arrayAddPermission.length == 0) {
            this.orgUnitCheck = false;
        }
        this.arrayAddPermission = [];
        this.newEnrollments.controls.map((item: any) => {
            this.arrayAddPermission.push(item.value);
        });
        this.formArrayEnrollments.controls.map((item: any) => {
            this.arrayAddPermission.push(item.value);
        });
        let listPermissions = {};

        // Valida o Array para identificar duplicados
        for (let i = 0; i < this.arrayAddPermission.length; i++) {
            const item = this.arrayAddPermission[i];
            // Verifica se no select de Role e de Orgunit algum não está preenchido
            if (
                typeof item.role_external_id == null ||
                typeof item.ou_external_id == null ||
                item.role_external_id == undefined ||
                item.ou_external_id == undefined
            ) {
                this.validatingAddPermission[i]['isInvalid'] = 'empty';
            } else {
                // faz um chave única para ser testada
                let unique = item.role_external_id + item.ou_external_id;

                // verifica dentro do array se ele existe, ou seja é duplicado
                let test = listPermissions[unique];
                if (test) {
                    // se for repetido insere no índice do array de validação de permissões,
                    //um validador com valor verdadeiro
                    this.validatingAddPermission[i]['isInvalid'] = true;
                    // break;
                } else {
                    //Caso não seja repetido insere um validador com valor falso no array de validação de permissões
                    // e insere o item no array de teste
                    this.validatingAddPermission[i]['isInvalid'] = false;
                    listPermissions[unique] = item;
                }
            }
        }
    }

    // método que verfica se algum perfil é repetido é desabilita botão de salvar formulário
    validatePermissionsUser() {
        if (this.validatingAddPermission.length == 0) {
            this.orgUnitCheck = false;
        } else {
            for (
                let index = 0;
                index < this.validatingAddPermission.length;
                index++
            ) {
                if (
                    this.validatingAddPermission[index].isInvalid == true ||
                    this.validatingAddPermission[index].isInvalid == 'empty'
                ) {
                    this.orgUnitCheck = false;
                    break;
                } else {
                    this.orgUnitCheck = true;
                    // if (this.arrayRemovePermission.length == 0) {
                    //   this.orgUnitCheck = false;
                    // }else{
                    // }
                }
            }
        }
    }

    nodeSelect($event: any, index, role_external_id, OU_node) {
        // this.checkOrgunit();
        console.log(index, role_external_id, OU_node);
        this.formArrayEnrollments.controls[index]['controls'][
            'ou_external_id'
        ].setValue($event?.node?.external_id);
        this.addPermission(index, role_external_id, $event.node);
    }

    nodeUnselect($event: any, index, role_external_id, OU_node) {
        this.orgUnitCheck = false;
        this.validatingAddPermission[
            index + this.newEnrollments.controls.length
        ].isInvalid = false;
        this.formArrayEnrollments.controls[index]['controls'][
            'ou_external_id'
        ].setValue(null);
        // this.loopProfilePermissions()
    }

    //método que adiciona select de role e de orgunit para Perfis de usuário
    addFormEnrollment() {
        this.formArrayEnrollments.push(
            this.createNewEnrollmentForm(this.newEnrollment)
        );
        this.defineOrgunits.push(null);
        this.validatingAddPermission.push([]); //<--- adiciona um array vazio para lista de validação das permissões
        this.checkOrgunit();
    }

    arrayRemovePermission: any = [];

    removePermissionEdit(index: any, role_external_id: any, OU_node: any) {
        if (
            this.newEnrollments.controls.length == 1 &&
            this.formArrayEnrollments.controls.length == 0
        ) {
            this.displayBasic = true;
            this.textError = this.i18n.users_modal_unique_profile;
            return;
        }
        this.confirmationService.confirm({
            header: this.i18n.users_modal_remove_user_confirm,
            message: this.i18n.users_modal_remove_user_explanation,
            accept: () => {
                const paramsRemoveEnrollment = {
                    role_external_id: role_external_id,
                    user_external_id: this.user.external_id,
                    enrollable_external_id: OU_node?.external_id,
                    enrollable_type: 'OU',
                };
                this.arrayRemovePermission.push(paramsRemoveEnrollment);
                this.newEnrollments.removeAt(index);
                this.defineOrgunits.splice(index, 1);
                this.arrayAddPermission.splice(index, 1);
                this.formEnrollments.splice(index, 1); //revisar
                this.validatingAddPermission.splice(
                    index + this.newEnrollments.controls.length,
                    1
                );
                this.validatePermissionsUser();
                // this.checkOrgunit();
                this.orgUnitCheck = true;
            },
            reject: () => { },
        });
    }

    removePermission(index: any, role_external_id: any, OU_node: any) {
        this.formArrayEnrollments.removeAt(index);
        // this.defineOrgunits.splice(index,1);
        this.arrayAddPermission.splice(index, 1);
        this.validatingAddPermission.splice(
            index + this.newEnrollments.controls.length,
            1
        );
        this.loopProfilePermissions();
        this.validatePermissionsUser();
        this.formEnrollments.splice(index, 1); //revisar
        // this.checkOrgunit();
    }

    orgUnitsApart: any = [];
    // metódo que procura por cada OrgUnit do perfil do usuário
    // na primeira camada
    findOrgunitRecursive(node: any, external_id: any) {
        node.forEach((element) => {
            if (!this.orgUnitsApart.includes(element)) {
                this.orgUnitsApart.push(element);
            }
            if (element.external_id == external_id) {
                this.defineOrgunits[this.iterator] = element;
                return;
            }

            if (
                element?.hasOwnProperty('children') &&
                element?.children instanceof Array &&
                element.children?.length > 0 &&
                element.children != null
            ) {
                this.checkChildrenNodes(element, external_id);
            }
        });

        return null;
    }

    // método que procura por cada Orgunit do perfil do usuário
    // nas childrens das OrgUnits
    checkChildrenNodes(node: any, external_id: any) {
        for (let index = 0; index < node.children?.length; index++) {
            let oNode = node.children[index];
            if (!this.orgUnitsApart.includes(oNode)) {
                this.orgUnitsApart.push(oNode);
            }

            if (oNode.external_id == external_id) {
                this.defineOrgunits[this.iterator] = oNode;
                return;
            }

            if (
                oNode?.hasOwnProperty('children') &&
                oNode?.children instanceof Array &&
                oNode.children?.length > 0 &&
                oNode.children != null
            ) {
                this.checkChildrenNodes(oNode, external_id);
            }
        }
    }

    showModalCreateProfile: boolean = false;

    openModalCreateProfile(user: any, editMode) {
        this.editMode = editMode;
        this.defineOrgunits = [];
        this.validatingAddPermission = [];
        this.arrayAddPermission = [];
        if (this.editMode) {
            this.editUser(user);
            this.orgUnitCheck = false;
        }

        this.user = user;

        this.showModalCreateProfile = true;
    }

    closeModalCreateProfile() {
        if (this.editMode) {
            this.showModalCreateProfile = false;
        } else {
            this.showModalCreateProfile = false;
            this.showModal = true;
        }
    }

    // Verifica se no select de OrgUnit algum node está selecionado
    checkOrgunit() {
        setTimeout(() => {
            // Se não existir item null no array check, retorne true, senão false para o array orgUnitsCheck
            // ex:. check[{null},{node}, {node}]
            // ex:. orgUnitsCheck [true,false,false]
            let orgUnitsCheck = this.defineOrgunits.map((item) => {
                return item == null;
            });
            let isOuValid: boolean = false;

            // Verfica no array de orgUnitsCheck se item é válido
            for (let index = 0; index < orgUnitsCheck.length; index++) {
                if (orgUnitsCheck[index] == true) {
                    this.orgUnitCheck = false;
                    break;
                } else {
                    this.orgUnitCheck = true;
                }
            }
        }, 100);
    }

    // Altera o status do user para ativo ou inativo
    changeStatusUser(user: any) {
        if (user.status == 'ACTIVE') {
            this.usersService
                .deactivateUser(user.external_id)
                .subscribe((response) => {
                    user.status = 'INACTIVE';
                });
        } else {
            this.usersService.activateUser(user.external_id).subscribe((response) => {
                user.status = 'ACTIVE';
            });
        }
    }

    openModalAddDiscipline(user) {
        user['isVisible'] = true;
        this.usersService.user$.next(user);
    }

    user_can_impersonate: boolean = false;
    rolesSaveUser: any = [];
    saveAddProfile(force?: boolean) {
        let role: any = {};
        let roles: any = [];

        let name = this.userForm.controls.name.value;
        let email = this.userForm.controls.email.value;
        let userCode = this.userForm.controls.userCode.value;
        let telephone = this.userForm.controls.phone.value;
        let lang = this.userForm.controls.lang.value;

        const formArray = this.formArrayEnrollments;

        // Iteração para pegar os perfis do usuários do formulário e separar num array
        for (const iterator of formArray.controls) {
            if (iterator.value.id == null) {
                role = {
                    role_external_id: iterator.value.role_external_id,
                    user_external_id: this.editMode ? this.user.external_id : userCode,
                    enrollable_external_id: iterator.value.ou_external_id,
                    enrollable_type: 'ou',
                };

                this.rolesSaveUser.push(role);
            }
        }

        roles = this.formatUserRolesToSave(this.rolesSaveUser);

        const { message, valid } = this.validateAssiduityDate();

        if (!valid) {
            this.platModalService.toggle('message', message, 'close')
            return;
        }

        this.formatAssiduityDate();

        // se for o fluxo de adicionar novo usuário
        // é preciso adicionar o Usuário primeiro
        //  e depois cadastrar suas respectivas roles
        if (!this.editMode) {
            const params = {
                name: name,
                email: email,
                external_id: userCode,
                image: this.userImage,
                status: 'ACTIVE',
                telephone: telephone,
                can_impersonate: this.user?.can_impersonate == undefined ? this.user_can_impersonate : this.user?.can_impersonate,
                roles: roles,
                assiduity: this.assiduityDateFormated,
                force: false,
                lang: lang
            };

            if (force) {
                params.force = true;
            }

            this.usersService.postUser(params).subscribe({
                next: (response) => {
                    if (this.rolesSaveUser.length) {
                        this.rolesSaveUser.map((e) => e.user_external_id = response.external_id);
                    }
                    this.isLoading = true;
                    this.addEnrollment(this.rolesSaveUser, () => {
                        this.isLoading = false;
                        roles = [];
                        this.rolesSaveUser = [];
                        this.ngAfterViewInit();
                        this.sharedService.showMessage('', this.i18n.users_succesfully_created, 'toast-success');
                    });
                },
                error: (err) => {
                    this.isLoading = false;
                    this.showModal = false;

                    roles = [];
                    this.rolesSaveUser = [];

                    let errorMessage = `${this.i18n.content_error_default}</br>`;
                    let typeErrors = Object.keys(err.error.error);

                    if (err.status == 409 && err.error.error == 'restore_subject_user') {

                        this.platModalService.toggle('decision', this.i18n[err.error.error], {
                            forward: () => {
                                this.saveAddProfile(true);
                            },
                            finally: () => {
                                this.platModalService.close('decision');
                            },
                        });
                        return
                    }

                    if (err.status == 409 && err.error.error == 'maximum_limit_of_licenses_used' || err.error == 'maximum_limit_of_licenses_used') {
                        this.platModalService.toggle('message', this.i18n['maximum_limit_of_licenses_used'], 'close');
                        return
                    }

                    if (err.error.hasOwnProperty('error')) {
                        if (this.i18n.hasOwnProperty(err.error.error)) {
                            errorMessage = this.i18n[err.error.error];
                        }
                    } else {
                        if (this.i18n.hasOwnProperty(err.error.message)) {
                            errorMessage = this.i18n[err.error.message];
                        }
                    }

                    if (err.status === 422) {
                        typeErrors.forEach(typeError => {
                            let codeMsg = err.error.error[typeError];
                            errorMessage += this.i18n[codeMsg] + '</br>';
                        });
                    }

                    if (err.error?.errCode === 422) {
                        Object.keys(err.error?.error).forEach((e) => {
                            if (e.endsWith('start_date')) {
                                errorMessage = this.i18n.users_assiduity_start_date_invalid
                            }
                        })
                    }

                    this.platModalService.toggle('message', errorMessage, 'close');
                },
            });
        } else {
            if (this.rolesSaveUser.length > 0) {
                this.addEnrollment(this.rolesSaveUser, () => {
                    this.rolesSaveUser = [];
                    this.ngAfterViewInit();
                    this.sharedService.showMessage('', this.i18n.users_succesfully_updated, 'toast-success');
                });
            }

            if (this.arrayRemovePermission.length > 0) {
                this.arrayRemovePermission.forEach((profile: any) => {
                    this.removeEnrollment(profile, () => {
                        this.ngAfterViewInit();
                        this.sharedService.showMessage('', this.i18n.users_succesfully_updated, 'toast-success');
                    });
                });
            }
        }

        this.showModalCreateProfile = false;
    }

    getLicensesButtonBlockedMessage(totalLicenses: number) {
        if (!Object.prototype.hasOwnProperty.call(this.i18n, 'users_message_no_linceses_left')) {
            return;
        }
        const translation = this.i18n.users_message_no_linceses_left;
        return translation.replace('$#', totalLicenses.toString());
    }

    currentLanguage: any;
    getTranslations() {
        this.i18n = {
            ...this.sharedService.getTranslationsOf('Users'),
            ...this.sharedService.getTranslationsOf('Modal'),
            ...this.sharedService.getTranslationsOf('Errors'),
            ...this.sharedService.getTranslationsOf('CommunicationApp'),
        };
        this.currentLanguage = localStorage.getItem('currentLanguage');
    }

    getUpdatedTranslations() {
        this.sharedService.translationsUpdate$.subscribe((translations: any) => {
            this.i18n = translations?.Users;
        });
    }

    //Functions
    toogle() {
        this.isOpen = false;
        this.open = false;
        this.showModal = false;
    }

    letter() {
        let users: any = this.users;

        users = users.map((i: any) => {
            let name = i.name;
            let ini = name.charAt(0);
            i.ini = ini;
        });
    }

    select() {
        let test: any = this.users;
        test = test.map((i: any) => {
            i.isSelected = this.isSelected;
        });
    }

    // Controls all check inputs
    checkUncheckAll() {
        this.users.forEach((user, i) => {
            this.users[i].isSelected = this.masterSelected;
            this.isAllSelected(user, user.isSelected);
        })
    }

    // Controls input check items
    isAllSelected(user, boolean) {
        const index = this.checkedList.findIndex((savedUser) => savedUser.external_id === user.external_id);

        if (boolean && index === -1) {
            this.checkedList.push(user);
        } else if (!boolean && index !== -1) {
            this.checkedList.splice(index, 1);
        } else if (boolean && index !== -1) {
            this.checkedList[index] = user;
        }
    }

    showNumberUsers(number: number) {
        if (number === null) {
            this.per_page = 0;
        }

        if (number > 1) {
            this.activePage = 1;
            this.getUsers({ page: this.activePage, per_page: number }, this.query)
        }
    }

    show(user: any) {
        this.users.forEach((tmp) => {
            tmp.show = false;
        });
        user.show = true;
    }

    verifyStudentOrTeacher(users): void {
        if (users.length) {
            // itera no primeiro array de Users, pois o segundo contém somente a paginação
            users.forEach((e: any) => {
                if (e.roles.length) {
                    e.roles.forEach((element: any) => {
                        if (e['isTeacherOrStudent']) {
                            return;
                        }
                        if (
                            element.role_type == 'teacher' ||
                            element.role_type == 'student'
                        ) {
                            e['isTeacherOrStudent'] = true;
                            return;
                        } else {
                            e['isTeacherOrStudent'] = false;
                        }
                    });
                } else {
                    e['isTeacherOrStudent'] = false;
                }
            });
        }
    }

    loadingGetUsers = false;
    //Requests
    getUsers(request: { page: number, per_page: number }, query?: string, isLoadingSet?: boolean) {
        this.isLoadingGuardian = true;
        // habilita modal de loading
        // TODO refatorar código abaixo chamando o isLoading em rotina diferente
        this.isLoading = isLoadingSet == undefined ? true : isLoadingSet;
        this.loadingGetUsers = true;

        this.usersService.getUsers(request, query)
            .subscribe({
                next: (users) => {
                    this.isLoading = false;
                    this.loadingGetUsers = false;
                    this.users = users.items;


                    if (users.metadata) {
                        this.metadata = users.metadata;
                    }

                    if (!users.metadata) {
                        this.metadata = {
                            licenses_counter: {
                                enabled: false,
                                available: 0,
                                total: 0,
                                used: 0
                            }
                        };
                    }

                    if (this.users.length) {
                        this.refreshPagination(users);
                    }

                    // Verifica se a role de algum usuário é student ou teacher
                    // para habilitar a opção de Vincular turmas à esse usuário
                    this.verifyStudentOrTeacher(this.users);
                    this.checkUserHasStudentProfile();

                    this.updatedGuardians(this.valueSonOfGuardianExternalID);
                    this.isLoadingGuardian = false;

                    this.letter();
                    this.select();
                    this.loadRoles();
                },
                error: () => {
                    this.loadingGetUsers = false;
                    this.isLoadingGuardian = false;
                    this.isLoading = false;
                }
            })
    }

    loadRoles() {
        this.permissionsService.getGroups().subscribe((arr: any) => {
            this.roles = arr.roles;
            // this.users.forEach((element: any) => {

            //   // this.findRoleName(element.roles)
            // });
        });
    }

    //Compara a Role do array recebido e acresenta
    // uma propriedade 'name' dentro de cada
    // objeto de role do usuário
    findRoleName(roles: any) {
        this.roles.map((role: any) => {
            roles.forEach((item: any) => {
                if (role.external_id == item.role_external_id) {
                    item['name'] = role.name;
                }
            });
        });
    }

    loadOrgunits() {
        this.orgUnitsService.getOrgUnits().subscribe((orgUnits) => {
            this.orgUnits = this.orgUnitsDataFormat.jsonReorder(orgUnits);
        });
    }

    formatUserRolesToSave(roles: any) {
        return roles.map((role: any) => {
            return {
                role_external_id: role?.role_external_id,
                ou_external_id: role?.enrollable_external_id
                    ? role?.enrollable_external_id
                    : role?.ou_external_id,
            };
        });
    }

    refreshToken(): void {
        this.accountsService.refreshTokenGoogle().subscribe({
            next: (response) => {
                if (response.hasOwnProperty('token')) {
                    localStorage.setItem('token', response.token);
                    this.getUserLoggedIn();
                    setTimeout(() => { window.location.reload() }, 200);
                }
            }
        })
    }

    validateAssiduityDate(): { message: string, valid: boolean } {
        let message: string = '';
        let valid: boolean = true;

        this.arrayAssiduity.filter((e) => {
            if (!e.start_date.length && e.time_card) {
                message = this.i18n.users_assiduity_start_date_required
                valid = false;
            }
            if (e.start_date.length && !e.time_card) {
                message = this.i18n.users_assiduity_point_card_required
                valid = false;
            }
            if (e.start_date.length && e.start_date.length < 8) {
                message = this.i18n.users_assiduity_start_date_invalid
                valid = false;
            }
        })

        return { message: message, valid: valid };
    }

    formatAssiduityDate() {
        this.assiduityDateFormated = this.arrayAssiduity.map(el => ({ ...el }));
        this.arrayAssiduity.forEach((el, i) => {
            if (el.start_date.length >= 8) {
                const dateParts = el.start_date.match(/(\d{2})[\/]?(\d{2})[\/]?(\d{4})/);

                if (dateParts) {
                    const day = dateParts[1];
                    const month = dateParts[2];
                    const year = dateParts[3];

                    el.start_date = `${day}/${month}/${year}`;
                    this.assiduityDateFormated[i].start_date = `${year}-${month}-${day}`;
                }
            } else {
                this.assiduityDateFormated = [];
                this.arrayAssiduity = [];
            }
        })
    }

    saveUser() {
        const name = this.userForm.controls.name.value;
        const email = this.userForm.controls.email.value;
        const userCode = this.userForm.controls.userCode.value;
        const telephone = this.userForm.controls.phone.value;
        const lang = this.userForm.controls.lang.value;

        const { message, valid } = this.validateAssiduityDate();

        if (!valid) {
            this.platModalService.toggle('message', message, 'close')
            return;
        }

        this.formatAssiduityDate();

        if (this.editMode) {
            this.isLoading = true;
            let roles: any = this.formatUserRolesToSave(this.user?.roles);
            const params = {
                name: name,
                email: email,
                external_id: userCode,
                image: this.userImage,
                status: 'ACTIVE',
                telephone: telephone,
                can_impersonate: this.user?.can_impersonate,
                roles: roles,
                assiduity: this.assiduityDateFormated,
                lang: lang
            };
            this.usersService.patchUser(userCode, params).subscribe({
                next: () => {
                    this.isLoading = false;
                    this.showModal = false;
                    // Update token when user update your impersonate

                    if (this.user.id == this.userLoggedIn.sub) {
                        const myuser = this.users.filter((e) => e.id === this.user.id)[0];

                        if (params.can_impersonate === myuser.can_impersonate) {
                            this.refreshToken();
                        }
                    }
                    this.ngAfterViewInit();
                    this.sharedService.showMessage('', this.i18n.users_succesfully_updated, 'toast-success');

                },
                error: (err) => {
                    this.isLoading = false;
                    this.showModal = false;
                    let errorMessage = this.i18n.content_error_default;

                    if (err.status === 409 || err.status === 500) {
                        errorMessage = this.i18n.users_already_exists;
                    }

                    if (err.error?.errCode === 422) {
                        Object.keys(err.error?.error).forEach((e) => {
                            if (e.endsWith('start_date')) {
                                errorMessage = this.i18n.users_assiduity_start_date_invalid
                            }
                        })
                    }

                    this.platModalService.toggle('message', errorMessage, 'close');
                }
            });
        } else {
            this.showModal = false;
            this.openModalCreateProfile('', (this.editMode = false));
        }
    }

    addEnrollment(enrollment: any, callback: any) {
        // const params = {
        //   'role_external_id': roleExternal,
        //   'user_external_id': userExternal,
        //   'enrollable_external_id': enrollable,
        //   'enrollable_type': type
        // }
        this.usersService.addEnrollment(enrollment).subscribe({
            next: (res) => {
                callback();
            },
            error: (err) => {
                this.platModalService.toggle('message', err.error.message, 'close');
            },
        });
    }

    removeEnrollment(enrollment: any, callback: any) {
        // const params = {
        //   'role_external_id': roleExternal,
        //   'user_external_id': userExternal,
        //   'enrollable_external_id': enrollable,
        //   'enrollable_type': type
        // }
        this.usersService.removeEnrollment(enrollment).subscribe({
            next: (res) => {
                callback();
            },
            error: (err) => {
                this.platModalService.toggle('message', err.error.message, 'close');
            },
        });
    }

    // Remove cada usuário por vez
    removeUsersLoop() {
        this.checkedList.forEach((user: any) => {
            this.delUser(user.external_id);
        });
    }

    // Remove usuários selecionados
    removeSelectedUser() {
        this.platModalService.toggle(
            'decision',
            {
                message: this.checkedList.length > 1 ? 'deletion_subject_all_selected_users' : 'deletion_subject_user',
                custom_icon: 'stash:exclamation-triangle-solid',
                icon_color: '#F36C48',
            },
            {
                forward: () => {
                    //remove o estado de seleção ao objeto(checkbox do user)
                    this.masterSelected = false;
                    this.removeUsersLoop();
                },
                finally: () => {
                    this.platModalService.close('decision');
                    this.checkedList = [];
                },
            }
        );
    }

    // Remove todos usuários
    removeAllUsers() {
        //adiciona o estado de seleção ao objeto(checkbox do user)
        this.masterSelected = true;
        this.checkUncheckAll();

        this.platModalService.toggle(
            'decision',
            {
                message: 'deletion_subject_all_users',
                custom_icon: 'stash:exclamation-triangle-solid',
                icon_color: '#F36C48',
            },
            {
                forward: () => {
                    this.removeUsersLoop();
                },
                finally: () => {
                    this.platModalService.close('decision');
                    //remove o estado de seleção ao objeto(checkbox do user)
                    this.masterSelected = false;
                    this.checkedList = [];
                    this.users.forEach((e, i) => { this.users[i].isSelected = false });
                },
            }
        );
    }

    removeUser(external_id: any) {
        this.platModalService.toggle(
            'decision',
            {
                message: 'deletion_subject_user',
                custom_icon: 'stash:exclamation-triangle-solid',
                icon_color: '#F36C48',
            },
            {
                forward: () => {
                    this.delUser(external_id);
                },
                finally: () => {
                    this.platModalService.close('decision');
                    this.checkedList = [];
                },
            }
        );
    }

    delUser(external_id: any) {
        this.toastMessenger.messengerToast('load', this.i18n.users_deleting_user, true);
        this.usersService.delUser(external_id).subscribe({
            next: () => {
                //remove usuário do array users
                // this.users = this.users.filter((value: any) => {
                //   return value.external_id != external_id;
                // });
                this.ngAfterViewInit();
                this.toastMessenger.messengerToast('success', this.i18n.users_deleted_successfully, true);
            },
            error: (res) => {
                this.platModalService.toggle('message', res.error.message, 'close');
            },
        });
    }

    closeUploadModal() {
        this.modalUploadOverlay = false;
    }

    openUploadModal() {
        this.modalUploadOverlay = true;
        this.sharedService.modalOpen();
    }

    receiveFiles($event: any) {
        this.files = $event;
        this.sharedService.replaceLinkFile(this.files);
        this.userImage = this.files[0].id;
        this.imgUser = 'https://drive.google.com/uc?id=' + this.files[0].path;
    }

    // searchUser(form: FormGroup) {
    //   const query: string = form.value?.search?.toLowerCase();
    //   this.activePage = 1;
    //   this.getUsers({page: this.activePage, per_page: this.per_page}, query);
    // }

    async searchUser(form: FormGroup) {
        // Pega o que o usuário digitou e salva no storage e pesquisa
        this.query = await this.sharedService.debounce(this.formSearch.controls.search.value);
        this.localStorageService.searchKeys.keyUsers = this.query;
        localStorage.setItem('searchKeys', JSON.stringify(this.localStorageService.searchKeys));

        this.activePage = 1;
        this.getUsers({ page: this.activePage, per_page: this.per_page }, this.query, false);
        // this.getDisciplines(this.userVinculate,this.profileSelected);
    }

    onImageError() {
        this.imgUser = '';
    }

    ngOnDestroy(): void {
        this.element.nativeElement.ownerDocument.body.removeAttribute('style');

        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    onChangeCheckBox(can_impersonate: any, event: any) {
        if (can_impersonate) {
            event.toggle(new MouseEvent('click'), this.alertIcon.nativeElement);
        }
        if (this.user) {
            this.user.can_impersonate = can_impersonate;
        }
        this.user_can_impersonate = can_impersonate;
    }

    impersonate(user: any) {
        if (user.roles.length > 1) {
            this.openModalProfileImpersonate(user);
        }

        if (user.roles.length == 1) {
            const params = {
                user_external_id: user.external_id,
                role_external_id: user.roles.map((item: any) => {
                    return item.role_external_id;
                })[0],
            };

            this.postImpersonate(params);
        }
    }

    postImpersonate(params: any) {
        this.usersService.impersonate(params).subscribe({
            next: (impersonate) => {
                this.platModalService.toggle(
                    'decision',
                    {
                        message: 'impersonate_subject_user',
                        custom_icon: 'alert-circle-outline-icon',
                        icon_color: '#3656BF',
                    },
                    {
                        forward: () => {
                            this.sharedService.fnIsImpersonating(impersonate);
                        },
                        finally: () => {
                            this.platModalService.close('decision');
                        },
                    }
                );
            },
            error: (err) => { },
        });
    }

    receiveProfileToImpersonate(params: any) {
        this.closeModalProfileImpersonate();
        this.postImpersonate(params);
    }

    openModalProfileImpersonate(user: any) {
        this.impersonateUser = user;
        this.isModalProgileImpersonateOpen = true;
    }

    closeModalProfileImpersonate() {
        this.isModalProgileImpersonateOpen = false;
    }

    goToPage(event): void {
        const params = { page: event.activePage, per_page: this.per_page }
        const query: string = this.formSearch.controls['search'].value.toLowerCase();

        this.usersService.getUsers(params, query).subscribe({
            next: (response) => {
                this.users = response.items;
                this.activePage = response.page;
                this.verifyStudentOrTeacher(this.users);
                this.checkUserHasStudentProfile();
                this.userIsChecked();
            }
        })
    }

    refreshPagination(response): void {
        this.paginationParams = {
            items: [...Array(response.total_items).keys()],
            itemsPerPage: this.per_page,
            visibleButtons: 5,
            activePage: this.activePage
        }
        if (this.pagination) {
            this.pagination.params = this.paginationParams;
            this.pagination.initPaginationLib(true);
        }
    }

    // Check if inputs are selected and set them visually
    userIsChecked() {
        if (this.checkedList.length) {
            this.checkedList.forEach((element) => {
                this.users.filter((e, i) => {
                    if (element.external_id === e.external_id) {
                        this.users[i].isSelected = element.isSelected;
                    }
                })
            });
        }
        this.everyChecked();
    }

    // Checks whether all check inputs are true or false and checks for the largest
    everyChecked() {
        this.masterSelected = this.users.every((user) => {
            return user.isSelected == true;
        });
    }

    removeSpecialCharacterOfUserCode(event: any) {
        const inputText = event.target.value;

        const textRemoveSpecialCharacters = inputText.replace(/[^a-zA-Z0-9]/g, '');

        this.userForm.controls['userCode'].setValue(textRemoveSpecialCharacters);
    }

    openModalCreateCommission(user: UsersInterface) {
        this.commissionUser = user;
        this.isModalCommissionTeamOpen = true;
    }

    closeModalCommissionTeam() {
        this.isModalCommissionTeamOpen = false;
    }

    receiveCommissionTeam(commission: any) {
        this.closeModalCommissionTeam();
        this.patchCommssionTeam(commission);
    }

    patchCommssionTeam(commission: any) {
        let error: any[] = [];
        for (let i = 0; i < commission.length; i++) {
            const element = commission[i];

            const commissionGroup = {
                id: element.commission_id,
                title: element.commission_title
            }

            let $this = this;
            this.usersService.commissionGroup(commissionGroup).subscribe({
                next(response) {
                    commission[i].commission_id = response.id;
                    commission[i].commission_title = response.title;

                    for (let j = 0; j < element.commission_users.length; j++) {
                        const usersElement = element.commission_users[j];
                        const commissionTeam = {
                            id: usersElement.id ?? null,
                            commission_id: parseInt(usersElement.commission_id),
                            user_id: usersElement.user_id
                        }

                        $this.usersService.commissionTeam(commissionTeam).subscribe({
                            next(responseTeam) {
                                commission[i].commission_users[j].commission_id = responseTeam.commission_id;
                                commission[i].commission_users[j].user_id = responseTeam.user_id;

                                $this.refreshCommissionTeam = true;
                            },
                            error(err) {
                                if (err) {
                                    error.push(err);
                                };
                            },
                        });

                    }

                },
                error(err) {
                    if (err) {
                        error.push(err);
                    };
                }
            });

        }

        if (error.length == 0) {
            this.platModalService.toggle(
                'message',
                {
                    message: this.i18n.users_succesfully_commission,
                    icon_existence: true,
                    icon_color: '#80CC28',
                    custom_icon: 'gg:check-o',
                },
                'close'
            );
        }

    }

    getLangs(): void {
        this.subscription = this.sidebarService.returnLangs().subscribe({
            next: (response) => {
                if (response) {
                    this.langsList = response;
                }
            }
        });
    }

    translateLanguageCode(lang: string): string {
        if (lang) {
            switch (lang) {
                case 'en':
                    return this.i18n.users_english;
                case 'es':
                    return this.i18n.users_spanish;
                case 'pt-BR':
                    return this.i18n.users_portuguese;
                default:
                    return lang;
            }
        }

        return lang;
    }

    convertInputToLowerCase(formControlName: string): void {
        const input = this.userForm.get(formControlName);

        if (input?.value) {
            const lowerCaseValue = input.value.toLowerCase();
            // Seta o valor apenas se ele for diferente para evitar execução desnecessária
            if (input.value !== lowerCaseValue) {
                input.setValue(lowerCaseValue, { emitEvent: false });
            }
        }
    }

    // Responsible (App Comunicações)
    toggleModalListGuardian(boolean: boolean, userExternalID?: string): void {
        if (typeof boolean === 'boolean') {
            this.valueModalListGuardian = boolean;
        }

        if (userExternalID) {
            this.updatedGuardians(userExternalID);
        }
    }

    toggleModalAddGuardian(boolean: boolean): void {
        if (typeof boolean === 'boolean') {
            this.valueModalAddGuardian = boolean;
        }
    }

    updatedGuardians(userExternalID: string): void {
        if (userExternalID) {
            this.cpfGuardian = '';
            this.valueSonOfGuardianExternalID = userExternalID;
            const sonOfGuardian = this.users.find((e) => e.external_id === userExternalID);

            if (sonOfGuardian) {
                this.guardiansList = sonOfGuardian.guardians;
            }
        }
    }

    checkUserHasStudentProfile(): void {
        this.users.map((user) => {
            if (user.roles) {
                user.hasStudentProfile = user.roles.some((item) => item.role_type === "student");
            }
        });
    }

    onNodeSelected(index: number, node: TreeNode | null): void {
        this.formArrayEnrollments.controls[index]['controls']['ou_external_id'].setValue(node?.external_id);
        const rolePermissionId = this.formArrayEnrollments.controls[0]['controls']['role_external_id'].value;
        this.addPermission(index, rolePermissionId, node);
        this.defineOrgunits[index] = node;
    }
}
