import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/shared.service';
import { GradesService } from '../../../../grades.service';

@Component({
  selector: 'app-student-grades-periods-list',
  templateUrl: './student-grades-periods-list.component.html',
  styleUrls: ['./student-grades-periods-list.component.scss'],
})
export class StudentGradesPeriodsListComponent implements OnInit {
  discipline_external_id: string = '';
  periods: any[] = [];
  lastPeriodAvarage: any = {};
  i18n: any = {};
  user: any = {};

  dropdownDefaultStyle = {
    borderWidth: 1,
    closed: {
      borderColor: '#E3E4E4',
      textColor: '#233674',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: 'rgba(54, 86, 191, 0.1)',
    },
    expanded: {
      borderColor: '#F4F4F4',
      textColor: '#233674',
      iconColor: '#233674',
      fontWeight: '700',
      backgroundColor: 'rgba(54, 86, 191, 0.1)',
    },
  };

  constructor(
    private router: Router,
    private gradesService: GradesService,
    private sharedSerivce: SharedService
  ) {}

  ngOnInit(): void {
    this.getUser();
    this.getTranslations();
    this.getDisciplineId();
    this.getPeriods();
  }

  getPeriods(): void {
    this.gradesService.getPeriodsList(this.discipline_external_id)
    .subscribe({
      next: (periods) => {
        this.periods = periods || [];
        if (this.periods.length) {
          this.periods.forEach((period, index: number) => {
            this.getGrades(period.id, index, this.periods.length);
          })
        }
      },
      error: () => {
        this.periods = [];
      }
    })
  }

  getGrades(period_id: number, index: number, periodLenght): void {
    this.gradesService.getGradesList(period_id).subscribe({
      next: (grades) => {
        if(grades) {
          this.periods[index]['student'] = grades.students[0];

          if(periodLenght === (index + 1)) {
            this.lastPeriodAvarage = {
              name: grades.period.name,
              average: grades.students[0].average,
              status: grades.period.status
            }
          }

          this.setCategoryInGrades(this.periods[index]);
        }
      }
    })
  }

  setCategoryInGrades(period): void {
    period.student?.grades.forEach((grade) => {
      period.categories.forEach((category) => {
        if (category.id == grade.grade_category_id) {
          grade['category_name'] = category.name;
          grade['category_weight'] = category.weight;
        }
      });
    });
  }

  getTranslations(): void {
    this.i18n = this.sharedSerivce.getTranslationsOf('Grades');
  }

  getDisciplineId(): void {
    this.discipline_external_id = this.router?.url.split('/')[2];
  }

  getUser(): void {
    this.sharedSerivce.getUser().subscribe((user) => {
      this.user = user;
    });
  }
}
