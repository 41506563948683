<div id="app__container" [ngClass]="{ impersonating: userIsImpersonating }" *ngIf="userHasToken && isTranslationsUploaded" [style]="addStyle">
    <app-header class="header" (onToggleMenu)="toggleMenuOnAside()"></app-header>
    <app-sidebar class="sidebar" (closeMenu)="toggleExpandBtnOnHeader()"></app-sidebar>

    <div class="router-outlet" #routerOutlet>
        <app-third-party-cookie class="third-cookie"></app-third-party-cookie>
        <router-outlet></router-outlet>
    </div>
</div>

<router-outlet *ngIf="!userHasToken"></router-outlet>

<!-- o isTranslationsUploaded$ abaixo funciona somente para tela de login -->
<!-- o isTranslationsUploaded abaixo funciona para o resto da aplicação (obs: isso pode ser melhor implementado no futuro)-->
<ng-container *ngIf="(isTranslationsUploaded$ | async) || isTranslationsUploaded ">
  <app-loading-modal></app-loading-modal>
  <app-impersonfication></app-impersonfication>
  <app-decision-modal></app-decision-modal>
  <app-messenger-toast></app-messenger-toast>
  <app-messenger-toast-base></app-messenger-toast-base>
  <app-message-modal></app-message-modal>
</ng-container>

<div [ngClass]="{'hidden': !canAccessibility }">
    <app-vlibras></app-vlibras>
    <app-user-way></app-user-way>
</div>

<app-cookies-enabled-modal></app-cookies-enabled-modal>

<!-- Loading -->
<app-loading-balls-effect *ngIf="loading" class="absolute py-6 px-10 rounded-2xl bg-customWhite-default transform right-2/4 top-2/4 translate-x-1/2 -translate-y-1/2"></app-loading-balls-effect>

