<app-generic-modal>
  <div class="container flex items-center justify-center flex-col p-7 gap-7">
    <div
      class="message flex items-center justify-center flex-col text-center gap-3"
    >
        <span
            class="iconify w-14 h-14"
            [style]="{ color: icon_color }"
            [attr.data-icon]="custom_icon"
        ></span>
      <span 
        [class]="custom_classes"
        class="font-semibold"
      >
        {{ message }}
      </span>
    </div>

    <div class="decision-btns center">
      <button
        class="btn backward"
        (click)="emitDecision($event, 'backward')"
        tabindex="1"
      >
        {{ btn.backward }}
      </button>
      <button
        class="btn forward justify-center"
        [style.width]="'9.1875rem'"
        (click)="emitDecision($event, 'forward')"
        tabindex="1"
      >
        {{ btn.forward }}
      </button>
    </div>
  </div>
</app-generic-modal>
