<div class="w-full relative">
  <div
    (click)="toggleTree()"
    class="flex items-center justify-start gap-2 text-customBlue-default border border-customBlue-default p-2 pl-3 cursor-pointer rounded-md text-16 w-full truncate"
  >
    <svg width="16" height="15" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.35 11.7H0.65C0.477609 11.7 0.312279 11.6315 0.190381 11.5096C0.0684819 11.3877 3.89157e-09 11.2224 3.89157e-09 11.05V6.16655C-1.01587e-05 6.07367 0.0198841 5.98187 0.0583425 5.89733C0.0968009 5.81279 0.15293 5.73747 0.22295 5.67645L2.6 3.6036V0.65C2.6 0.477609 2.66848 0.312279 2.79038 0.190381C2.91228 0.0684819 3.07761 0 3.25 0H12.35C12.5224 0 12.6877 0.0684819 12.8096 0.190381C12.9315 0.312279 13 0.477609 13 0.65V11.05C13 11.2224 12.9315 11.3877 12.8096 11.5096C12.6877 11.6315 12.5224 11.7 12.35 11.7ZM4.55 10.4H6.5V6.4623L3.9 4.1951L1.3 6.4623V10.4H3.25V7.8H4.55V10.4ZM7.8 10.4H11.7V1.3H3.9V2.68255C4.0521 2.68255 4.20485 2.73585 4.32705 2.8431L7.57705 5.67645C7.64707 5.73747 7.7032 5.81279 7.74166 5.89733C7.78012 5.98187 7.80001 6.07367 7.8 6.16655V10.4ZM9.1 5.2H10.4V6.5H9.1V5.2ZM9.1 7.8H10.4V9.1H9.1V7.8ZM9.1 2.6H10.4V3.9H9.1V2.6ZM6.5 2.6H7.8V3.9H6.5V2.6Z"
        fill="#233674"
      />
    </svg>
    <span [ngStyle]="{'font-size.px': titleSize}" class="tree-label">{{ selected?.label ?? i18n.ou_unit }}</span>
  </div>

  <ng-container *ngIf="isOpenTree">
    <app-custom-tree-select
      [treeData]="treeData"
      [treeDataOriginal]="treeData"
      [position]="positionTree"
      [isRoot]="true"
      [readonly]="readonly"
      [selected]="selected"
      (nodeSelected)="nodeSelected($event)"
      (closeTree)="closeTree()"
    ></app-custom-tree-select>
  </ng-container>
</div>

<app-info-modal></app-info-modal>
