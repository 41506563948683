<ul #treeContainer *ngIf="isOpenTree" class="tree" [ngClass]="getPositionClass()" [class.root-tree]="isRoot">
  <!-- <button class="flex w-full pr-1 justify-end -mt-2.5" *ngIf="level === 0" (click)="close()">
    <span
      class="iconify"
      data-icon="ei:close-o"
      style="color: #334155"
      data-width="22"
      data-height="22"
    ></span>
  </button> -->
  <li *ngFor="let node of treeData" [style.--level]="level" [class.selected]="node.selected">
    <!-- Nó Pai -->
    <div
      class="tree-node cursor-pointer"
      (click)="selectNode(node)"
      [class.selected]="
        node['selected'] && (selected && node['external_id'] === selected['external_id']) || (selected && node['external_id'] === selected['external_id'])
      "
      [ngClass]="{
        'cursor-not-allowed': !!readonly
      }"
    >
      <div class="tree-content">
        <i
          *ngIf="node.children?.length"
          class="icon"
          [class.expanded]="node['expanded']"
          (click)="toggleNode(node)"
        >
          <span
            class="iconify"
            data-icon="iconamoon:arrow-right-2-thin"
            style="color: #334155"
            data-width="22"
            data-height="22"
          ></span>
        </i>
        <span>{{ node.label }}</span>
      </div>
    </div>

    <!-- Nó Filho Recursivo -->
    <ul *ngIf="node.children?.length && node['expanded']">
      <app-custom-tree-select
        [treeData]="node.children ?? []"
        [treeDataOriginal]="treeDataOriginal"
        [position]="position"
        [isRoot]="false"
        [level]="(level || 0) + 1"
        [selected]="selected"
        [readonly]="readonly"
        (nodeSelected)="nodeSelected.emit($event)"
      ></app-custom-tree-select>
    </ul>
  </li>
</ul>
