import { Component, Input, OnInit, Output, EventEmitter, AfterContentChecked, OnDestroy, ElementRef, ViewChild, OnChanges, SimpleChanges, AfterContentInit} from '@angular/core';

import jwt_decode from "jwt-decode";
import {GoogleDrivePickerService} from 'src/app/services/google-drive-picker-service.service';
import { ConfirmationService } from 'primeng/api';
import { UploadService } from './upload.service';
import { SharedService } from 'src/app/shared/shared.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { Subscription, take } from 'rxjs';
import { AccountsService } from 'src/app/pages/accounts/accounts.service';
import { ActivatedRoute, RouterStateSnapshot } from '@angular/router';
import { ContentSharedService } from 'src/app/pages/disciplines/components/content/content-shared.service';
import { LocalStorageService } from 'src/app/services/localStorageService/local-storage.service';

@Component({
  	selector: 'app-upload',
	templateUrl: './upload.component.html',
  	styleUrls: ['./upload.component.scss'],
	providers: [ConfirmationService],
})
export class UploadComponent implements OnInit, AfterContentInit, OnChanges, OnDestroy {
	// inputs para configuração de visualização do componente upload
	@Input() maxFiles: number;//Número máximo de arquivos que o User pode fazer upload
	@Input() multiSelect: boolean = false;//Opção para User selecionar um arquivo por vez ou vários
	@Input() sharingStatus: boolean = false;//Opção para ser compartilhado de forma pública
	@Input() fileType: string;//Tipo do arquivo que será mostrado, se vazio busca todos
	@Input() files: any = [];//arquivos que vem do componente pai
	@Input() syncWithDrive: boolean = true;//arquivos que vem do componente pai
	@Input() isShowUploadTab: boolean = true;

	// outputs dos arquivos para componente pai
	@Output() filesEvent   = new EventEmitter<any[]>();//Emissor dos arquivos que foram feito upload para componente pai
  	@Output() onCloseEvent = new EventEmitter<any[]>();
  	@Output() loadedFile   = new EventEmitter<boolean>();

	// consertar depois pois modais de erro estão ficando sobrepostos
	isVisible: boolean = true;

	//Variables
	googleData: any = [];
	user
	token
	provider: any;
	public picked: File;
	public status: string;
	uploadedFiles: any[] = [];
	dateFile = new Date();
	isLoading: boolean = false;
	isLoaded: boolean = false;
	isPermissionDone: any;
	displayBasic: boolean = false;
	textError: string;
	isUploadLocked: boolean = false;
	select:string;
	date: Date;
	currentLanguage: any;

	destroyCallbackResponse$: any;

	formData = new FormData();

	i18n: any;

	subscription: Subscription[] = []

	constructor(
		private sharedService: SharedService,
		private googleDrivePickerService: GoogleDrivePickerService,
		private uploadService: UploadService,
		public platModalService: PlatformModalsService,
		private element: ElementRef,
		private route: ActivatedRoute,
		private contentSharedService: ContentSharedService,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit(): void {
		this.provider = localStorage.getItem('provider') || null;
        this.sharedService.checkIfApiKeyExists();
		this.getTranslations();
		this.getUser();
		this.getSyncWithDriveValue();
		this.localStorageService.checkCookiesEnabled();
		let sub1 = this.sharedService.files$.subscribe((files)=>{
			console.log('files ', files);
			this.files = files;
			this.formatFiles();
		});

		this.subscription.push(sub1);

	}

	ngAfterContentInit(): void {
		this.emitRemoveFiles();
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log('changes ', JSON.stringify(changes));

		if (changes.hasOwnProperty('files')) {
			console.log('this.files CHANGES', this.files);
			this.formatFiles();
		}
	}

	formatFiles(){
		this.files == null ? this.files = [] : this.files;
		if (this.files) {
			this.files.forEach((file:any) => {
				file['submitted_at'] = file.hasOwnProperty('created_at') ? file.created_at : file.submitted_at;
			});
			this.checkMaxFiles(this.files);
		}
	}

	getSyncWithDriveValue(){
		let subscription = this.contentSharedService.syncWithDrive$.subscribe({
			next:(value)=>{
				console.log('value ', value);
				this.syncWithDrive = value;
			}
		});

		this.subscription.push(subscription)
	}

	defineTypeFile(){
		return 'audio/*,video/*,image/*,application/pdf';

	}

	// ngAfterContentChecked(){
	// 	this.files == null ? this.files = [] : this.files;
	// 	this.checkMaxFiles(this.files);
	// 	this.emitRemoveFiles();
	// }

	closeModal(){
    	this.onCloseEvent.emit(this.files);
		this.sharedService.modalClose();
		this.sharedService.removeFiles$.next({files: this.files, isUpdated: false} )
	}

	getTranslations() {
		this.i18n = this.sharedService.getTranslationsOf('Upload');
		this.currentLanguage = localStorage.getItem('currentLanguage' || '');
	}

  //Functions
	getUser(){
		let data: any = localStorage.getItem('token')
		this.token = data
		this.user = jwt_decode(data);
	}

	offset = 0;
	chunkSize: number = 1000000;
	countLoop: any = 1;

	countFiles: number = 0;
	totalFiles: number = 0;
	filesReceived: any = [];
	progressBar: number = 0;
	progressValue;
	progress;
	isUploadingFiles: boolean = false;

	calculateProgressBar(part, totalPart){
		this.progressValue = this.element.nativeElement.querySelector('.Progressbar__value');
		this.progress = this.element.nativeElement.querySelector('progress');
		console.log('this.progressValue ', this.progressValue);
		

		this.progressBar = Math.ceil(part * 100 / totalPart);
		console.log('this.progressBar ', this.progressBar);
		console.log('part ', part, 'totalPart ', totalPart);
		// console.log('fileSize ', fileSize);
		this.setValue(this.progressBar);

	}

	setValue(value) {
		if (this.progressValue) {
			this.progressValue.style.width = `${value}%`;
		}
		if (this.progress) {
			this.progress.value = value;
		}
	}

	fileSelected(event) {
		console.log('fileSelected event ', event);
		
		this.isUploadingFiles = true;
		this.filesReceived = Array.from(event.target.files);
		this.totalFiles = this.filesReceived.length;
		if (this.totalFiles > this.maxFiles) {
			this.sharedService.modalClose();
			this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close');
			return;
		}
		this.fileSetup(this.filesReceived[this.countFiles]);
	}

	fileSetup(file){
		// Tamanho do arquivo maior que 20MB
		if (file.size > 20000000) {
			this.chunkSize = Math.ceil(file.size / 150);
		}
		if (this.countFiles < this.totalFiles) {
			this.sendChunkPart(this.offset, file);
		}
	}


	sendChunkPart(offset, file){

		let totalPart: any  = Math.ceil(file.size / this.chunkSize) == 0 ? 1 : Math.ceil(file.size / this.chunkSize);

		if (offset < file.size) {
			const chunk = file.slice(offset, offset + this.chunkSize);
			this.formData = new FormData();
			this.formData.append('file[]', chunk, file.name);
			this.formData.append('mime', file.type);
			this.formData.append('part', this.countLoop.toString());
			this.formData.append('totalPart', totalPart.toString());
			this.postFiles(this.formData, file);
			this.calculateProgressBar(this.countLoop, totalPart);
		}


	}

	postFiles(files: any, file){

		this.uploadService.postFile(files).subscribe({
			next:(response:any )=>{
				this.offset += this.chunkSize;


				this.countLoop++;

				if (this.offset < file.size) {
					this.sendChunkPart(this.offset, file);
				}else{
					response.forEach((item:any)=>{
						this.files.push(item)
					});
					this.countFiles++;

					if (this.countFiles < this.totalFiles) {
						this.formData = new FormData();
						this.offset = 0;
						this.countLoop = 1;
						this.fileSetup(this.filesReceived[this.countFiles]);
					}else{
						this.resetPostFileVariables();
						this.checkMaxFiles(this.files);
						this.isUploadingFiles = false;
						this.filesEvent.emit(this.files);
					}
				}
			},
			error:(err)=>{
				if (err.status == 500 || err.status == 413) {
					setTimeout(() => {
						console.log('files ', files);
						console.log('file ', file);

						this.postFiles(files, file);
					}, 10000);
				}
			}
		})
	}

	resetPostFileVariables(){
		this.formData = new FormData();
		this.offset = 0;
		this.countLoop = 1;
		this.countFiles = 0;
		this.totalFiles = 0;
	}

	replaceFileGCS(event, file){
		console.log('event ', event);
		console.log('file ', file);
		

		this.isUploadingFiles = true;
		const fileToReplace = this.files.indexOf(file);

		if (fileToReplace > -1) {
			this.files.splice(fileToReplace, 1);
			this.fileSelected(event);
		}
	}



	showCookieFail(){
		this.isVisible = false;
		this.platModalService.toggle('message',this.i18n.upload_content_help_cookies_blocked, 'close');
	}

	//Requests Provider GOOGLE_DRIVE
	openGoogleDrivePicker() {
		this.isLoading = true;
		let select:string;
		if (this.multiSelect == true) {
			select = 'multiple';
		}else{
			select = 'single';
		}

		this.googleDrivePickerService.open(this.isShowUploadTab, select, this.fileType, (data:any) => {
			console.log('data ', data);

			if (data.action === 'picked') {
				this.googleData = data.docs;
                this.googleDrivePickerService.isPickerAlreadyCreated = false;
			}

			if (data.action === 'cancel') {
				this.isLoading = false;
                this.googleDrivePickerService.isPickerAlreadyCreated = false;
			}
		});

		this.destroyCallbackResponse$ = this.googleDrivePickerService.callbackReponse$.subscribe({
			next:(response)=>{
				if (!this.sharedService.isCookiesEnabled()) {
					this.showCookieFail();
				}
				console.log('callbackReponse ', response);

				if (response.hasOwnProperty('error')) {

					if (response.details == "Cookies are not enabled in current environment.") {
						this.isVisible = false;
						this.platModalService.getModalState('message').pipe(take(1)).subscribe({
							next:(state)=>{
								if (state == false) {
									this.platModalService.toggle('message',this.i18n.upload_content_help_cookies_blocked, 'close');
								}
							}
						});

					}

					if (response.error.errCode == 400) {
						if (response.error.error == 'invalid_grant') {
							// this.sharedService.modalClose();
							this.isVisible = false;

							this.platModalService.toggle('message', this.i18n.upload_content_google_invalid_grant, {
								finally:()=>{
									this.platModalService.close('message');
									this.sharedService.modalClose();
									 const redrectTo = this.route.snapshot['_routerState'].url;

									this.sharedService.logOut(redrectTo);
									this.googleDrivePickerService.callbackReponse$.next({});
								}
							})
						}
					}
				}else{

					let interval = setInterval(() => {
						if (!this.isLoading) {
							clearInterval(interval);
						}

						if(this.googleData.length > 0){

							this.callInsertPermission(this.googleData);

							clearInterval(interval);
						}
					}, 2000);
				}
			}
		})


	}

	countFilesInsertedPermission: number = 0;
	async callInsertPermission(files: any){
		let files_size = files.length;
		await this.googleDrivePickerService.insertPermission(files[this.countFilesInsertedPermission].id, (response)=>{
			console.log('response', response);
			if (response.status == 200) {

				if (this.countFilesInsertedPermission < files_size-1) {
					this.countFilesInsertedPermission++;
					this.callInsertPermission(files);
				} else{
					this.uploadFile(files);

				}
			} else{

				let error: string = '';
				switch (response.status) {
					case 401:
					  error = 'upload_error_unauthorized'
					  break;
					case 403:
					  error = 'uplaod_error_permission'
					  break;
					case 404:
					  error = 'uplaod_error_file_not_found'
					  break;
					default:
					  error = 'upload_error'
					  break;
				}

				this.isLoading = false;
				this.closeModal();
				this.platModalService.toggle('message', this.i18n[error], 'close');
			}


		});
	}

	replaceFile(file:any) {
		this.isLoading = true;
		this.googleDrivePickerService.open(this.isShowUploadTab, 'single',this.fileType, (data:any) => {
			if (data.action === 'picked') {
				this.googleData = data.docs;

				// this.googleDrivePickerService.insertPermission(data.docs);
			}
			if (data.action === 'cancel') {
				this.isLoading = false;
			}
		});

		this.destroyCallbackResponse$ = this.googleDrivePickerService.callbackReponse$.subscribe({
			next:(response)=>{
				if (response.hasOwnProperty('error')) {

					if (response.details == "Cookies are not enabled in current environment.") {
						this.sharedService.modalClose();
						this.platModalService.getModalState('message').pipe(take(1)).subscribe({
							next:(state)=>{
								if (state == false) {
									this.platModalService.toggle('message',this.i18n.upload_content_help_cookies_blocked, 'close');
								}
							}
						});

					}
				}else{

					let interval = setInterval(() => {

						if (!this.isLoading) {
							clearInterval(interval);
						}

						if(this.googleData.length > 0){

							const fileToReplace = this.files.indexOf(file);

							if (fileToReplace > -1) {
								this.files.splice(fileToReplace, 1);
								this.callInsertPermission(this.googleData);
							}

							clearInterval(interval);
						}
					}, 2000);
				}
			}
		})




	}


	checkMaxFiles(files: any){
		if (files !== null) {
			if (files.length >= this.maxFiles) {
				this.isUploadLocked = true;
			}else{
				this.isUploadLocked = false;
			}

			if (files.length > 0) {
				this.isLoaded = true;
				//this.loadedFile.emit(this.isLoaded)
				this.sharedService.isLoadedUploadTrue();
			} else {
				this.isLoaded = false;
				//this.loadedFile.emit(this.isLoaded)
				this.sharedService.isLoadedUploadfalse();
			}
		}
	}



	decisionTypePlatModalService: any = null;
	removeFile(file:any){

		// Fecha o modal para vir novo modal de confirmação de exclusão
		this.sharedService.modalClose();
		this.emitRemoveFiles();

		this.platModalService.toggle('decision', 'deletion_subject_generic', {
			forward: () => {
				if (this.files.length >= 1) {
					const fileToRemove = this.files.indexOf(file);

					if (fileToRemove > -1) {

							this.files.splice(fileToRemove, 1);
							//
							this.sharedService.removeFiles$.next({files: this.files, isUpdated: true} )
							// this.filesEvent.emit(this.files);
					}

					this.checkMaxFiles(this.files);
					this.sharedService.modalOpen();
				}else{

				}
				// this.decisionTypePlatModalService = 'forward';
			},
			finally: () => {
				this.platModalService.close('decision');
				this.sharedService.modalOpen();
			},
		});
	}

	destroyObserver: any;
	emitRemoveFiles(){
		let sub1 = this.sharedService.removeFiles$.subscribe((item: any)=>{
			if (item?.isUpdated) {
				console.log('item?.isUpdated ', item?.isUpdated);
				
				this.filesEvent.emit(item?.files);
				this.sharedService.removeFiles$.next({files: this.files, isUpdated: false} )
			}
		})

		this.subscription.push(sub1);
	}




  	uploadFile(googleData: any){
		let countFiles = this.files.length;
		let countNewFiles = googleData.length;
		let Sharingpublic: any;

		if (countFiles + countNewFiles > this.maxFiles) {
			this.googleData = [];
			this.isLoading = false;
			this.sharedService.modalClose()
			this.platModalService.toggle('message', this.i18n.upload_selected_files_number_exceeds, 'close')
		}else{
			let googleFiles: any = [];
			for (let i = 0; i < googleData.length; i++) {
				const element = googleData[i];
				googleFiles.push(element.id)
			}
			let params: any = {};

			if (this.sharingStatus) {
				params = {
					"public": true,
					"provider": "GOOGLE_DRIVE",
					"file": googleFiles,
				}
			}else{
				params = {
					"provider": "GOOGLE_DRIVE",
					"file": googleFiles,
				}
			}

			if (!this.syncWithDrive) {
				params["syncWithDrive"] = this.syncWithDrive;
			}


			this.uploadService.uploadFile(params).subscribe((response:any )=>{


				response.forEach((item:any)=>{
					this.files.push(item)
				});
				this.checkMaxFiles(this.files);
				this.files.forEach((file:any)=>{

					// // substitui - por /
					// if (file.hasOwnProperty('submitted_at')) {
					// 	file.submitted_at = new Date(file.submitted_at.replace(/-/g,"/"));
					// }

					// if (file.hasOwnProperty('created_at')) {
					// 	file.submitted_at = new Date(file.created_at.replace(/-/g,"/"));
					// }

				});

				this.isLoading = false;
				this.filesEvent.emit(this.files);
				this.googleData = [];
				this.countFilesInsertedPermission = 0;
			},(err) => {
				console.log('err ', err);

				this.googleData = [];
				this.countFilesInsertedPermission = 0;
				this.isLoading = false;
				if (err.status == 403) {
					this.platModalService.toggle('message', this.i18n.upload_no_permission, 'close')
				}else{
					this.platModalService.toggle('message', err.error.error, 'close')
				}
			});
		}
	}

	ngOnDestroy() {
		console.log('Upload destroyed');
		if (this.files.length) {
			this.sharedService.files$.next([]);
		}
		if (this.destroyCallbackResponse$) this.destroyCallbackResponse$.unsubscribe();

		if (this.subscription.length) {
			this.subscription.forEach(element => {
				console.log('element ', element);
				
				element.unsubscribe();
			});
		}
	}

}

