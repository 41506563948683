import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanLoad,
    Router,
    RouterStateSnapshot,
} from '@angular/router';

import { SharedService } from '../../shared/shared.service';
import { AccountsService } from './accounts.service';

@Injectable()
export class AccountsGuard implements CanLoad, CanActivate {
    constructor(
        private sharedService: SharedService,
        private router: Router,
        private accountsService: AccountsService
    ) {}

    canLoad(): boolean {
        if (
            this.sharedService.fnUserHasToken()
            && this.sharedService.fnUserHasValidToken()
        ) {
            this.router.navigate(['/dashboard']);
        }

        return true;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        const authenticationData = localStorage.getItem('authentication');

        const { queryParams } = route;
        const loginAuthObj = localStorage.getItem('loginAuthObj')
            ? JSON.parse(localStorage.getItem('loginAuthObj') || '')
            : null;

        // checks if in the route has code to authenticate
        if (
            Object.prototype.hasOwnProperty.call(queryParams, 'code')
            && (
                (loginAuthObj && Object.prototype.hasOwnProperty.call(loginAuthObj, 'ssoLogin'))
                || (loginAuthObj && Object.prototype.hasOwnProperty.call(loginAuthObj, 'googleLogin'))
                || (loginAuthObj && Object.prototype.hasOwnProperty.call(loginAuthObj, 'microsoftLogin'))
            )
        ) {
            // navigate to root route (loginNormalComponent) passing queryParams and loginAuthObj ex: (googleLogin or ssoLogin)
            this.router.navigate(['/']);
            this.accountsService.getCodeAuthentication$.next({
                loginAuthObj,
                queryParams,
            });
            return false;
        }

        // If user do not clicked in any google authentication buttons proceeds to
        // verification of app_communication_enable setting
        if (authenticationData && !loginAuthObj) {
            const authentication = JSON.parse(authenticationData);

            // Se não tiver app de comunicação e for para a rota accounts
            if (
                !authentication?.enable_app_communication
                && state.url.includes('/accounts')
            ) {
                this.router.navigate(['/']); // Redireciona para a raiz
                return false; // Não deixa acessar
            }

            // Se tiver app de comunicação, e não tem acesso
            const canAccessLogin = localStorage.getItem('canAccessLogin') === 'true';

            if (authentication?.enable_app_communication && !canAccessLogin) {
                if (!state.url.includes('/accounts')) {
                    this.router.navigate([
                        '/accounts/login-choose-access-type',
                    ]);
                    return false; // Bloqueia o acesso à rota raiz
                }
            }
        }

        return true; // Libera o acesso à rota se as condições não forem atendidas
    }
}
