import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SharedService } from 'src/app/shared/shared.service';
import { API_Routes } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ClassDiaryService {
    isModalStudentRegistersOpen$ = new BehaviorSubject<boolean>(false);
    frequencyCalendar$ = new BehaviorSubject<any>({});
    attachedGradeSchedule$ = new Subject<any>();

    daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    i18n;

    classRegisterObject: any = {
        intended_content: '',
        covered_content: '',
        class_schedule_detail: '',
        date: '',
    };

    constructor(
        private http: HttpClient,
        private sharedService: SharedService
    ) {
        this.i18n = {
            ...sharedService.getTranslationsOf('Humanize'),
            ...sharedService.getTranslationsOf('ClassDiary')
        };
    }

    getDayOfTheWeekTranslated(day: string) {
        let nameDay = '';
        switch (day) {
            case 'Mon':
                nameDay = this.i18n.humanize_monday;
                break;
            case 'Tue':
                nameDay = this.i18n.humanize_tuesday;
                break;
            case 'Wed':
                nameDay = this.i18n.humanize_wednesday;
                break;
            case 'Thu':
                nameDay = this.i18n.humanize_thursday;
                break;
            case 'Fri':
                nameDay = this.i18n.humanize_friday;
                break;
            case 'Sat':
                nameDay = this.i18n.humanize_saturday;
                break;
            case 'Sun':
                nameDay = this.i18n.humanize_sunday;
                break;
            default:
                nameDay = '';
                break;
        }

        return nameDay;
    }

    getReasonsTranslate(reasonExternalId: string): string {
        const reasonString = `class_diary_${reasonExternalId.replace(/-/g, '_')}`;

        if (Object.prototype.hasOwnProperty.call(this.i18n, reasonString)) {
            return this.i18n[reasonString];
        }
        return '';
    }

    convertStringToDate(value: string, locale: any): Date {
        let dateValue: Date = new Date();

        if (!value.includes('/')) {
            return dateValue;
        }
        if (value.length) {
            if (locale === 'en') {
                const [datePart] = value.split(' ');
                const [month, day, year] = datePart.split('/').map(Number);

                dateValue = new Date(+year, +month - 1, +day);
            } else {
                const [date] = value.split(' ');
                const [day, month, year] = date.split('/');

                dateValue = new Date(+year, +month - 1, +day);
            }
        }
        return dateValue;
    }

    getClassDiary(disciplineId: string, queryParams: string): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/disciplines/${disciplineId}/class-diary?${queryParams}`);
    }

    postClassDiary(disciplineId: string, params): Observable<any> {
        return this.http.post<any>(`${API_Routes.URL}/disciplines/${disciplineId}/class-diary`, params);
    }

    getFrequencyCalendar(disciplineId: string, params): Observable<any> {
        return this.http.get<any>(`${API_Routes.URL}/disciplines/${disciplineId}/class-diary/frequency-calendar/list?${params}`);
    }
}
