import { NgModule } from '@angular/core';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { ModalConfirmationModule } from '../modal-confirmation/modal-confirmation.module';
import { TranslateComponent } from './translate.component';

@NgModule({
    declarations: [
        TranslateComponent,
    ],
    exports: [
        TranslateComponent,
    ],
    imports: [
        SharedModule,
        ModalConfirmationModule,
        PermissionModule
    ]
})
export class TranslateModule { }
