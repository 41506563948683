<!--body-->
<div>
    <div class="p-4">
        <!-- Standalone GCS Button Upload -->
        <button
            *ngIf="provider === 'GOOGLE_CLOUD_STORAGE'"
            [ngClass]="{
                'disabled:opacity-20 cursor-not-allowed':
                    isUploadLocked || isUploadingFiles
            }"
            [disabled]="isUploadLocked || isUploadingFiles"
            class="flex flex-col md:flex-row items-center w-full gap-2 py-2 px-4 rounded-md border border-customBlue-default cursor-pointer justify-center mt-2"
            type="button"
        >
            <input
                *ngIf="!isUploadLocked"
                id="upload-local"
                type="file"
                [accept]="defineTypeFile()"
                hidden
                multiple
                [disabled]="isUploadLocked || isUploadingFiles"
                (change)="fileSelected($event)"
            />
            <label
                for="upload-local"
                class="cursor-pointer"
                [ngClass]="{
                    'disabled:opacity-20 cursor-not-allowed':
                        isUploadLocked || isUploadingFiles
                }"
            >
                <span
                    class="text-14 md:text-left font-medium text-customBlue-default"
                >
                    {{ i18n.upload_file }}
                </span>
            </label>
        </button>
        <!--FIM Standalone GCS Button Upload -->

        <!-- Google Drive Button Upload -->
        <div class="flex justify-center">
            <button
                #btn_upload_google
                *ngIf="provider === 'GOOGLE_DRIVE'"
                class="w-full flex justify-center my-4 border border-customBlue-default rounded-md cursor-default"
                [style]="{ 'max-width': maxWidthDriveBtn }"
                (click)="openGoogleDrivePicker()"
                [ngClass]="{
                    'disabled:opacity-20 cursor-not-allowed':
                        isUploadLocked || isLoading || delivery_allowed
                }"
                [disabled]="isUploadLocked || isLoading || delivery_allowed"
            >
                <div
                    class="flex items-center h-9 gap-2 cursor-pointer"
                    [ngClass]="{
                        'cursor-not-allowed':
                            isUploadLocked || isLoading || delivery_allowed
                    }"
                >
                    <div>
                        <span
                            class="iconify w-5 h-5 text-customBlue-default"
                            data-icon="mdi:google-drive"
                        ></span>
                    </div>
                    <div class="text-sm font-semibold text-customBlue-default">
                        {{ textUploadBtn }}
                    </div>
                </div>
                <div
                    *ngIf="isShowExplanation"
                    (click)="displayPanelType.toggle()"
                    class="relative pl-4"
                    style="top: 8px; cursor: pointer"
                >
                    <button type="button">
                        <span
                            class="iconify-inline text-19 text-customGray-default"
                            data-icon="carbon:help"
                        ></span>
                    </button>
                    <app-display-panel
                        #displayPanelType
                        [style]="{ position: 'absolute' }"
                        [custom_radius]="'10px'"
                        [hide_arrow]="true"
                        [panel_style]="{
                            background: '#FEF8E6',
                            width: '20.5rem'
                        }"
                        [newStyle]="{ left: '0', top: '-3.125rem' }"
                    >
                        <small class="text-customGray-dark font-normal">
                            {{ textExplanation }}
                        </small>
                    </app-display-panel>
                </div>
            </button>
        </div>
        <!-- FIM Google Drive Button Upload -->

        <!-- MAX files -->
        <div *ngIf="maxFiles > 0 && !hideMaxFileText" class="text-center">
            <p class="text-sm text-customBlue-default mb-4">
                {{ i18n.upload_max_files_number }} {{ maxFiles }}
            </p>
        </div>
    </div>

    <!-- Standalone Loading -->
    <div
        *ngIf="isUploadingFiles"
        class="py-6 px-6"
        style="border: 1px solid #d8e5f8; border-radius: 15px"
    >
        <div
            class="flex justify-start items-center text-sm font-semibold text-customBlue-default"
        >
            {{ i18n.upload_sending }} {{ countFiles + 1 }}{{ "/"
            }}{{ filesReceived.length }}
        </div>
        <ng-container *ngIf="!isProcessingFile">
            <div class="flex justify-center items-center">
                <div class="w-full">
                    <div
                        class="relative progress-bar-container bg-customGray-solitudeGray w-full h-7 rounded-md"
                    >
                        <div
                            class="text-customWhite-default h-full font-bold"
                            style="
                                position: absolute;
                                left: 50%;
                                top: 4px;
                                transform: translateX(-50%);
                            "
                        >
                            {{ progressBar }}%
                        </div>
                        <div
                            class="progress-bar bg-customBlue-default rounded-md"
                            [style.width]="progressBar + '%'"
                        ></div>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="isProcessingFile">
            <div class="flex justify-center items-center">
                <div class="w-full">
                    <div
                        class="flex justify-center font-semibold text-customBlue-default mb-4"
                    >
                        {{ i18n.upload_processing_file }}
                    </div>
                    <div class="flex justify-center">
                        <app-bullets-loader></app-bullets-loader>
                    </div>
                </div>
            </div>
        </ng-container>
        <div
            class="flex items-center mt-2 justify-center gap-2 text-customBlue-default font-medium"
        >
            <span>{{ i18n.upload_wait_until_we_load_yor_file }}</span>
        </div>
    </div>
</div>

<!--FOOTER-->

<!-- Google Drive Loading -->
<div *ngIf="isLoading">
    <div class="flex flex-col items-center justify-center">
        <ng-container *ngIf="isPickingFiles">
            <div class="flex justify-center items-center">
                <div class="w-full">
                    <div class="text-center text-customBlue-default mb-4">
                        {{ i18n.upload_pick_your_drive_files }}
                    </div>
                    <div class="flex justify-center">
                        <app-bullets-loader></app-bullets-loader>
                    </div>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="isUploadingGoogleFiles">
            <div class="text-center text-customBlue-default text-12">
                {{
                    countFilesSize > 1
                        ? i18n.upload_wait_loading_all_files
                        : i18n.upload_wait_loading_the_file
                }}
            </div>
            <div class="text-center text-customBlue-default text-14">
                {{ i18n.upload_loading }}
                <b>{{ countFilesInsertedPermission }}</b>
                {{ i18n.upload_out_of }} <b>{{ countFilesSize }}</b>
                {{
                    countFilesSize > 1
                        ? i18n.upload_description_completion_rule_files
                        : i18n.upload_description_completion_rule_file
                }}
            </div>
            <div class="py-6 px-6 w-full">
                <div class="flex justify-center items-center">
                    <div class="w-full">
                        <div
                            class="relative progress-bar-container border border-customBlue-default bg-customWhite-default w-full h-2 rounded-sm"
                        >
                            <div
                                class="relative progress-bar bg-customBlue-default"
                                [style.width]="progressBar + '%'"
                            >
                                <div class="rounded-dot"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>

<!-- File list -->
<div class="py-2">
    <!-- HEADER file list -->
    <ng-container *ngIf="showSendTitle">
        <div
            *ngIf="files?.length !== 0"
            class="text-sm flex justify-start font-bold text-customGray-dark"
            style="margin-bottom: 1.625rem"
        >
            <span>
                {{ i18n.upload_uploaded }}
            </span>
        </div>
    </ng-container>
    <!-- FIM HEADER file list -->

    <ul
        [ngClass]="{ 'custom_overflow pr-2': files.length > 2 }"
        style="display: grid; gap: 1.125rem"
    >
        <ng-container *ngFor="let file of files; let i = index">
            <li
                class="w-full grid grid-cols-3 gap-2 p-2 border border-customGray-default rounded-md"
            >
                <!-- Icon and Title -->
                <div
                    class="flex justify-start items-center gap-2 xs:col-span-2"
                    [ngClass]="{ 'col-span-2': !showDate }"
                >
                    <div [ngSwitch]="true" class="file-icons">
                        <div
                            *ngSwitchCase="
                                file?.mime_type ===
                                'application/vnd.google-apps.document'
                            "
                        >
                            <span
                                class="iconify w-6 h-6"
                                style="color: #4b8cf5"
                                data-icon="mdi:drive-document"
                            ></span>
                        </div>
                        <div
                            *ngSwitchCase="
                                file?.mime_type ===
                                'application/vnd.google-apps.spreadsheet'
                            "
                        >
                            <span
                                class="iconify w-6 h-6"
                                style="color: #0f9d58"
                                data-icon="mdi:google-spreadsheet"
                            ></span>
                        </div>
                        <div
                            *ngSwitchCase="
                                file?.mime_type ===
                                'application/vnd.google-apps.form'
                            "
                        >
                            <span
                                class="iconify w-6 h-6"
                                style="color: #7f3bb8"
                                data-icon="mdi:form"
                            ></span>
                        </div>
                        <div
                            *ngSwitchCase="
                                file?.mime_type === 'image/png' ||
                                file?.mime_type === 'image/jpg' ||
                                file?.mime_type === 'image/jpeg' ||
                                file?.mime_type ===
                                    'application/vnd.google-apps.photo'
                            "
                        >
                            <span
                                class="iconify w-6 h-6 text-customGray-dark"
                                data-icon="bi:file-earmark-image"
                            ></span>
                        </div>
                        <div
                            *ngSwitchCase="
                                file?.mime_type === 'video/mp4' ||
                                file?.mime_type === 'video/mkv' ||
                                file?.mime_type ===
                                    'application/vnd.google-apps.video'
                            "
                        >
                            <span
                                class="iconify w-6 h-6 text-customGray-dark"
                                data-icon="bi:file-earmark-play"
                            ></span>
                        </div>
                        <div
                            *ngSwitchCase="
                                file?.mime_type === 'application/pdf'
                            "
                        >
                            <span
                                class="iconify w-6 h-6 text-customGray-dark"
                                data-icon="mdi:file-pdf-box"
                            ></span>
                        </div>

                        <div *ngSwitchDefault>
                            <span
                                class="iconify w-6 h-6 text-customGray-dark"
                                data-icon="mdi:file-outline"
                            ></span>
                        </div>
                    </div>
                    <div
                        pTooltip="{{ file?.label }}"
                        tooltipPosition="top"
                        pRipple
                        tooltipEvent="focus"
                        [ngClass]="{ 'lg:w-52 xs:w-28': showDate }"
                        class="styling-tooltip truncate flex text-sm text-customGray-dark items-center justify-start"
                    >
                        <span
                            style="
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            "
                        >
                            {{ file?.label }}
                        </span>
                    </div>
                </div>
                <!-- FIM Icon and Title -->

                <!-- Date Sumbission -->
                <ng-container *ngIf="showDate">
                    <div class="flex justify-center items-center xs:hidden">
                        <ng-container *ngIf="file?.submitted_at">
                            <span
                                class="text-sm text-center text-customGray-dark"
                            >
                                <ng-container
                                    *ngIf="
                                        currentLanguage === 'pt-BR' ||
                                        currentLanguage === 'es'
                                    "
                                >
                                    {{ i18n.upload_submmited_at }}
                                    {{
                                        file?.submitted_at | date : "dd/MM/yyyy"
                                    }}
                                    {{ i18n.upload_hours }}
                                    {{ file?.submitted_at | date : "H:mm" }}
                                </ng-container>
                                <ng-container *ngIf="currentLanguage === 'en'">
                                    {{ i18n.upload_submmited_at }}
                                    {{
                                        file?.submitted_at | date : "MM/dd/yyyy"
                                    }}
                                    {{ i18n.upload_hours }}
                                    {{ file?.submitted_at | date : "h:mm a" }}
                                </ng-container>
                            </span>
                        </ng-container>
                    </div>
                </ng-container>
                <!-- FIM Date Sumbission -->

                <!-- GOOGLE DRIVE feature buttons -->
                <div
                    *ngIf="provider === 'GOOGLE_DRIVE'"
                    class="flex justify-end items-center mr-2"
                >
                    <div
                        *ngIf="!file.isUploading && !file.uploadError"
                        (click)="
                            getLocationToDisplayPanel(
                                $event,
                                returnMessageFlowMethod(file)
                            )
                        "
                        class="cursor-pointer"
                    >
                        <span
                            class="iconify w-5 h-5 text-customBlue-glass"
                            data-icon="entypo:warning"
                        ></span>
                    </div>
                    <ng-container *ngIf="showReplaceBtn">
                        <button
                            (click)="replaceFile(file)"
                            pTooltip="{{ i18n.upload_replace_file }}"
                            tooltipPosition="top"
                            pRipple
                            class="mr-2"
                        >
                            <div>
                                <span
                                    class="iconify w-5 h-5 text-customGray-default"
                                    data-icon="mdi:circle-arrows"
                                ></span>
                            </div>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="showOpenBtn">
                        <a
                            href="{{ file?.public_url }}"
                            pTooltip="{{ i18n.upload_open_file }}"
                            tooltipPosition="top"
                            pRipple
                            class="mr-2"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div>
                                <span
                                    class="iconify w-5 h-5 text-customGray-default"
                                    data-icon="material-symbols:open-in-new-rounded"
                                ></span>
                            </div>
                        </a>
                    </ng-container>
                    <ng-container *ngIf="assignment === 'File'">
                        <div>
                            <div *ngIf="file.isUploading" class="flex">
                                <span
                                    class="iconify w-5 h-5 text-customBlue-default"
                                    data-icon="line-md:uploading-loop"
                                ></span>
                            </div>
                            <div
                                *ngIf="!file.isUploading && !file.uploadError"
                                class="flex"
                            >
                                <span
                                    class="iconify w-5 h-5 text-customGreen-check"
                                    data-icon="heroicons-solid:check"
                                ></span>
                            </div>

                            <div
                                *ngIf="!file.isUploading && file.uploadError"
                                class="flex"
                                [ngClass]="{
                                    'cursor-pointer': file.messageError !== ''
                                }"
                                (click)="
                                    getLocationToDisplayPanel(
                                        $event,
                                        file.messageError
                                    )
                                "
                            >
                                <span
                                    class="iconify w-5 h-5 text-inicieColors-orange"
                                    data-icon="mdi:warning-circle"
                                ></span>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="showRemoveBtn && !delivery_allowed">
                        <button
                            (click)="removeFile(file)"
                            pTooltip="{{ i18n.upload_remove_file }}"
                            tooltipPosition="top"
                            pRipple
                        >
                            <div>
                                <span
                                    class="iconify w-5 h-5 text-customGray-default"
                                    data-icon="bi:trash"
                                ></span>
                            </div>
                        </button>
                    </ng-container>
                </div>
                <!-- FIM GOOGLE DRIVE feature buttons -->

                <!-- STANDALONE feature buttons -->
                <div
                    *ngIf="provider === 'GOOGLE_CLOUD_STORAGE'"
                    class="flex justify-center items-center ml-2"
                >
                    <button
                        pTooltip="{{ i18n.upload_replace_file }}"
                        tooltipPosition="top"
                        pRipple
                        class="mr-2"
                    >
                        <input
                            *ngIf="!isUploadLocked"
                            id="replace-local"
                            type="file"
                            [accept]="defineTypeFile()"
                            hidden
                            multiple
                            (change)="replaceFileGCS($event)"
                        />
                        <label
                            for="replace-local"
                            class="flex flex-col justify-center items-center cursor-pointer"
                        >
                            <div>
                                <span
                                    class="iconify w-5 h-5 text-customGray-default"
                                    data-icon="mdi:circle-arrows"
                                ></span>
                            </div>
                        </label>
                    </button>
                    <a
                        href="{{ file?.path }}"
                        pTooltip="{{ i18n.upload_open_file }}"
                        tooltipPosition="top"
                        pRipple
                        class="mr-2"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div>
                            <span
                                class="iconify w-5 h-5 text-customGray-default"
                                data-icon="ion:open-outline"
                            ></span>
                        </div>
                    </a>
                    <button
                        (click)="removeFile(file)"
                        pTooltip="{{ i18n.upload_remove_file }}"
                        tooltipPosition="top"
                        pRipple
                    >
                        <div>
                            <span
                                class="iconify w-5 h-5 text-customGray-default"
                                data-icon="bi:trash"
                            ></span>
                        </div>
                    </button>
                </div>
                <!-- FIM STANDALONE feature buttons -->
            </li>
        </ng-container>
    </ul>
</div>

<!-- </div>
  </div> -->
<app-display-panel
    #displayPanelWarning
    [style]="{ position: 'absolute' }"
    [custom_radius]="'10px'"
    [hide_arrow]="true"
    [panel_style]="{ background: '#FEF8E6', width: '20.5rem' }"
    [newStyle]="{
        top: displayPanelObject.top,
        left: displayPanelObject.left,
        right: displayPanelObject.right
    }"
>
    <span>
        {{ textFlowMethodWarning }}
    </span>
</app-display-panel>
