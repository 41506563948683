<div class="circular-progress">
    <svg class="progress-ring" width="50" height="50">
        <!-- Background circle -->
        <circle
            class="progress-ring__background"
            stroke="#eff4fa"
            stroke-width="4" 
            fill="transparent" 
            r="20" 
            cx="25" 
            cy="25" 
        ></circle>
        <!-- Progress circle -->
        <circle
            class="progress-ring__circle"
            stroke="#213b76"
            stroke-width="4"
            fill="#f8f8f8"
            r="20"
            cx="25"
            cy="25"
            [attr.stroke-dasharray]="circumference"
            [attr.stroke-dashoffset]="
                circumference - (value / 100) * circumference
            "
        ></circle>
    </svg>
    <div class="progress-label">
        <span>{{ value }}%</span>
    </div>
</div>
