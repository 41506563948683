import { Component, OnInit, AfterContentInit, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { formatDate } from '@angular/common';
import { DisciplinesDataSettingsService } from 'src/app/pages/disciplines/components/settings/components/discipline-settings-data/disciplines-data-settings.service';
import { SharedService } from 'src/app/shared/shared.service';
import { DisciplineDetailService } from 'src/app/pages/disciplines/components/disciplines-detail/discipline-detail.service';
import { minDateValidator } from 'src/app/utils/form-validators/form-validators';
import { DisciplinesModelsService } from 'src/app/shared/disciplines-models.service';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
@Component({
  selector: 'app-discipline-settings-data',
  templateUrl: './discipline-settings-data.component.html',
  styleUrls: ['./discipline-settings-data.component.scss'],
})
export class DisciplineSettingsDataComponent implements OnInit, AfterViewInit {
  external_id: any;
  settingsData: any = [];
  settingsLevels: any = [];
  settingsModalities: any = [];
  isLoading: boolean = false;
  seccess = false;
  settingsDataForm: UntypedFormGroup;
  buttonActive: boolean;
  /* modalitiesOk: boolean = false;
  levelsOk: boolean = false;
  settingsOk: boolean = false; */
  i18n: any = [];
  currentLanguage: string | null;
  initialDate: Date = new Date();
  enableSync: boolean = false;

  constructor(
    private settingsDisciplinesService: DisciplinesDataSettingsService,
    private disciplineDetailService: DisciplineDetailService,
    private disciplinesModelsService: DisciplinesModelsService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private chagesDetector: ChangeDetectorRef,
    private platModalService: PlatformModalsService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.getUrlParams();
    this.createDataForm();
    this.getTranslations();
  }

  ngAfterViewInit(): void {
    this.isLoading = true;
    this.buttonActive = false;
    this.chagesDetector.detectChanges();
    this.getLevels();
    this.getMdalities();
    this.getDataSettings();
  }

  onEventLog(event: string, data: any): void {
    let formField = event.split('-').pop();

    if (formField !== undefined) {
      if (event.startsWith('colorPickerChange')) {
        this.settingsDataForm.controls[formField].setValue(data);
      }
    }
  }

  onlyIfAllowed() {
    /* if (this.sharedService.checkPermission("disciplines", "list")) {
      this.getLevels();
    }
    if (this.sharedService.checkPermission("disciplines", "list")) {
      this.getMdalities();
    } */
  }

  getTranslations() {
    this.i18n = { ...this.sharedService.getTranslationsOf('Disciplines'), ...this.sharedService.getTranslationsOf('Modal') };
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  getUrlParams() {
    this.route?.parent?.parent?.params?.subscribe((params) => {
      this.external_id = params.id;
    });
  }

  getDataSettings() {
    this.isLoading = true;
    setTimeout(() => {
      this.settingsDisciplinesService
      .getDisciplineDataSettings(this.external_id)
      .subscribe({
        next: (settingsData) => {
          this.setValueForm(settingsData);
          this.setValueSync(settingsData.replica_sync);

          if ('replica_sync' in settingsData) {
            this.enableSync = true;
          } else {
            this.enableSync = false;
          }
          this.settingsData = settingsData;
          this.isLoading = false;
        },
        error: (err) => {
          this.isLoading = false; 
          console.log(err);
        },
        complete: () => this.buttonActive = false,
      });
    }, 900);
  }

  setValueSync(replica_sync: boolean): void {
    this.settingsDataForm.controls['replicate_sync'].setValue(!replica_sync);
  }

  getMdalities() {
    this.isLoading = true;
    this.settingsDisciplinesService.getDisciplineModalities().subscribe(
      (modalities) => {
        this.settingsModalities = modalities;
        this.isLoading = false;
      },
      (err) => console.log(err),
      () => {
        this.buttonActive = false;
      }
    );
  }

  translationsModality(modality_id: any, modality_name: any) {
    let modalityDefault = modality_name

    switch (modality_id) {
      case 'presencial':
        return this.textCut(47, this.i18n?.presencial);
      case 'semi-presencial':
        return this.textCut(47, this.i18n?.semi_presencial);
      case 'ead':
        return this.textCut(47, this.i18n?.ead);
      default:
        return this.textCut(47, modalityDefault);
    }
  }

  translationsSegment(segment_id: string, segment_name: string) {
    let segmentDefault = segment_name
    switch (segment_id) {
      case 'educacao-basica-ensino-fundamental-series-iniciais':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_fundamental_series_iniciais)
      case 'educacao-basica-ensino-fundamental-series-finais':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_fundamental_series_finais)
      case 'educacao-basica-ensino-medio':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_medio)
      case 'educacao-basica-ensino-tecnico-de-nivel-medio':
        return this.textCut(47, this.i18n?.educacao_basica_ensino_tecnico_de_nivel_medio)
      case 'educacao-superior-bacharelado':
        return this.textCut(47, this.i18n?.educacao_superior_bacharelado)
      case 'educacao-superior-licenciatura':
        return this.textCut(47, this.i18n?.educacao_superior_licenciatura)
      case 'educacao-superior-tecnologia':
        return this.textCut(47, this.i18n?.educacao_superior_tecnologia)
      case 'educacao-superior-especializacao':
        return this.textCut(47, this.i18n?.educacao_superior_especializacao)
      case 'educacao-superior-mestrado':
        return this.textCut(47, this.i18n?.educacao_superior_mestrado)
      case 'educacao-superior-doutorado':
        return this.textCut(47, this.i18n?.educacao_superior_doutorado)
      case 'projeto-especial':
        return this.textCut(47, this.i18n?.projeto_especial)
      default:
        return this.textCut(47, segmentDefault);
    }
  }

  textCut(limit: any, textAnnouncement: any) {
    let text = textAnnouncement;
    const overLimit = text.length > limit;
    const elipsisOrEmpty = overLimit ? '...' : '';
    text = text.substring(0, limit) + elipsisOrEmpty;
    return text;
  }

  getLevels() {
    this.isLoading = true;
    this.settingsDisciplinesService.getDisciplineLevels().subscribe(
      (levels) => {
        this.settingsLevels = levels;
        this.isLoading = false;
      },
      (err) => console.log(err),
      () => {
        this.buttonActive = false;
      }
    );
  }

  postDataSettings() {
    let title = this.settingsDataForm.controls.title.value;
    let external_id = this.settingsDataForm.controls.external_id.value;
    let level_external_id = this.settingsDataForm.controls.level_external_id.value;
    let modality_external_id = this.settingsDataForm.controls.modality_external_id.value;
    let workload = this.settingsDataForm.controls.workload.value;
    let period = this.settingsDataForm.controls.period.value;
    let start_date = this.settingsDataForm.controls.start_date.value;
    let end_date = this.settingsDataForm.controls.end_date.value;
    let accessibility_enabled = this.settingsDataForm.controls.accessibility_enabled.value;
    let header_text_color = this.settingsDataForm.controls.header_text_color.value;
    let card_text_color = this.settingsDataForm.controls.card_text_color.value;
    let replicate_sync = this.settingsDataForm.controls.replicate_sync.value;
    
    start_date =
      start_date == null || start_date == ''
        ? null
        : formatDate(start_date, 'yyyy-MM-dd HH:mm', 'en-US');
    end_date =
      end_date == null || end_date == ''
        ? null
        : formatDate(end_date, 'yyyy-MM-dd HH:mm', 'en-US');

    const params = {
      title: title,
      external_id: external_id,
      level_external_id: level_external_id,
      modality_external_id: modality_external_id,
      workload: workload,
      period: period,
      start_date: start_date,
      end_date: end_date,
      accessibility_enabled: accessibility_enabled,
      header_text_color: header_text_color,
      card_text_color: card_text_color
    };

    this.settingsDisciplinesService.postDisciplineDataSettings(this.external_id, params)
    .subscribe((response) => {
      this.setValueForm(response);
      this.disciplineDetailService.disciplineDetail = response;
      if (this.enableSync) this.postReplicateSync(replicate_sync);
      this.buttonActive = false;
      });
  }

  postReplicateSync(sync: boolean): void {
    const params = { sync: !sync };

    this.disciplinesModelsService.postReplicateSync(this.external_id, params)
    .subscribe({
      next: () => {
        this.settingsData.replica_sync = params.sync;
      },
    })
  }

  setValueForm(settingsData: any) {
    //let startDate:Date = new Date(settingsData.start_date);
    //let startDate:Date = new Date();

    let startDate = settingsData.start_date;
    let endDate = settingsData.end_date;

    const initProcessedDate = null;
    const start_date = new Date(settingsData.start_date);

    if (startDate == null) {
      startDate = initProcessedDate;
    } else {
      startDate = start_date;
    }

    const endProcessedDate = null;
    const end_date = new Date(settingsData.end_date);

    if (endDate == null) {
      endDate = endProcessedDate;
    } else {
      endDate = end_date;
    }

    this.settingsDataForm.controls['title'].setValue(settingsData.title);
    this.settingsDataForm.controls['external_id'].setValue(settingsData.external_id);
    this.settingsDataForm.controls['level_external_id'].setValue(
      settingsData.level_external_id
    );
    this.settingsDataForm.controls['modality_external_id'].setValue(
      settingsData.modality_external_id
    );
    this.settingsDataForm.controls['workload'].setValue(settingsData.workload);
    this.settingsDataForm.controls['period'].setValue(settingsData.period);

    this.settingsDataForm.controls['start_date'].setValue(startDate);
    this.settingsDataForm.controls['calendarStartDate'].setValue(startDate);
    this.settingsDataForm.controls['end_date'].setValue(endDate);
    this.settingsDataForm.controls['calendarEndDate'].setValue(endDate);
    this.fnPickDateToInput();

    this.settingsDataForm.controls['accessibility_enabled'].setValue(
      settingsData.accessibility_enabled
    );
    this.settingsDataForm.controls['header_text_color'].setValue(settingsData.header_text_color);
    this.settingsDataForm.controls['card_text_color'].setValue(settingsData.card_text_color);
    this.settingsData = settingsData;
  }

  private createDataForm() {
    this.settingsDataForm = this.formBuilder.group({
      title: ['', [Validators.required]],
      external_id: ['', [Validators.required]],
      level_external_id: ['', [Validators.required]],
      modality_external_id: ['', [Validators.required]],
      workload: ['', [Validators.required]],
      period: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      startDate: [new Date()],
      calendarStartDate: ['', [this.minDateValidator.bind(this)]],
      calendarEndDate: ['', [this.minDateValidator.bind(this)]],
      end_date: ['', [Validators.required]],
      accessibility_enabled: ['', [Validators.required]],
      card_text_color: [''],
      header_text_color: [''],
      replicate_sync: [false]
    });
    this.settingsDataForm.valueChanges.subscribe((val) => {
      this.buttonActive = true;
    });
  }

  minDateValidator(control: FormControl): { [key: string]: any } | null {
    const enteredDate = control;
    
    let dateMin : Date = new Date();
    let returnValue: any = null;
    
    if (this.settingsDataForm && true) {
      dateMin = this.initialDate;
      returnValue = minDateValidator(dateMin)(enteredDate);
    }
    return returnValue;
  }

  checkChangeSync(): void {
    if (this.settingsDataForm.get('replicate_sync')?.value) {
      this.platModalService.toggle(
        'decision',
        {
          message: this.i18n.disciplines_settings_sync_check,
          custom_icon: 'stash:exclamation-triangle-solid',
          icon_color: '#F36C48',
        },
        {
          backward: () => {
            this.settingsDataForm.controls['replicate_sync'].setValue(false);
          },
          finally: () => {
            this.platModalService.close('decision');
          }
        }
      );
    }
  }

  fnPickDateToInput(calendarDateValue?){
    // Coloca a data no input vindo do CalendarPicker
    if (calendarDateValue) {
      let format = this.currentLanguage === 'en' ? formatDate(calendarDateValue, 'MM/dd/yyyy HH:mm','en-US') : formatDate(calendarDateValue, 'dd/MM/yyyy HH:mm','pt-BR');
      this.settingsDataForm.controls['calendarStartDate'].setValue(format);
    }
  }

  get startDate() {
    return this.settingsDataForm.get('startDate');
  }
}
