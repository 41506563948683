<app-box-login [background_login]="backgroundLogin" [image_side_login]="imageSideLogin" [hasBackButton]="false" [i18n]="i18n">
    <img [src]="logoLogin" class="accounts__logo_width" alt="Logo" onerror="this.src='../../../assets/png/empty_img.png'"/>

    <p class="text-22 font-medium text-center">{{ i18n.login_choose_access_type }}</p>

    <ul class="grid grid-cols-3 gap-1 md:gap-3 w-full">
        <!-- Guardian -->
        <li class="border border-customBlue-light rounded px-3 py-5 flex flex-col items-center justify-center gap-4 text-customBlue-default">

            <div class="bg-customBlue-light h-9 w-9 flex items-center justify-center rounded-full">
                <span class="iconify text-22" data-icon="ph:users-three"></span>
            </div>

            <p class="font-medium text-14">{{ i18n.login_guardian }}</p>

            <button type="button" class="bg-customBlue-default text-customWhite-default font-medium rounded py-1.5 px-4 text-14" (click)="accessLogin(true)">{{ i18n.login_access }}</button>
        </li>

        <!-- School -->
        <li class="border border-customBlue-light rounded px-3 py-5 flex flex-col items-center justify-center gap-4 text-customBlue-default">

            <div class="bg-customBlue-light h-9 w-9 flex items-center justify-center rounded-full">
                <span class="iconify text-22" data-icon="teenyicons:school-outline"></span>
            </div>

            <p class="font-medium text-14">{{ i18n.login_school }}</p>

            <button type="button" class="bg-customBlue-default text-customWhite-default font-medium rounded py-1.5 px-4 text-14" (click)="accessLogin(false)">{{ i18n.login_access }}</button>
        </li>

        <!-- Student -->
        <li class="border border-customBlue-light rounded px-3 py-5 flex flex-col items-center justify-center gap-4 text-customBlue-default">

            <div class="bg-customBlue-light h-9 w-9 flex items-center justify-center rounded-full">
                <span class="iconify text-22" data-icon="ph:student-fill"></span>
            </div>

            <p class="font-medium text-14">{{ i18n.login_student }}</p>

            <button type="button" class="bg-customBlue-default text-customWhite-default font-medium rounded py-1.5 px-4 text-14" (click)="accessLogin(false)">{{ i18n.login_access }}</button>
        </li>
    </ul>

    <app-contact-support [i18n]="i18n" [support_email]="supportEmail"></app-contact-support>
</app-box-login>