import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PermissionModule } from 'src/app/directives/permission/permission.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { DisplayPanelModule } from '../display-panel/display-panel.module';
import { TranslateModule } from '../translate/translate.module';
import { UserFrameModule } from '../user-frame/user-frame.module';
import { AccessibilityComponent } from './accessibility/accessibility.component';
import { GlobalSearchComponent } from './global-search/global-search.component';
import { HeaderComponent } from './header.component';
import { NotificationTooltipComponent } from './notification-tooltip/notification-tooltip.component';
import { PushNotificationComponent } from './push-notification/push-notification.component';

@NgModule({
    declarations: [
        HeaderComponent,
        GlobalSearchComponent,
        NotificationTooltipComponent,
        AccessibilityComponent,
        PushNotificationComponent
    ],
    exports: [
        HeaderComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        SharedModule,
        UserFrameModule,
        DisplayPanelModule,
        PermissionModule
    ]
})
export class HeaderModule { }
