import {
    AfterViewInit, Component, ElementRef, OnDestroy, ViewChild
} from '@angular/core';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { PlatformModalsService } from 'src/app/services/modals/platform-modals.service';
import { SharedService } from 'src/app/shared/shared.service';

import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
    selector: 'app-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})
export class MessageModalComponent implements AfterViewInit, OnDestroy {
    @ViewChild(GenericModalComponent) generic_modal: GenericModalComponent;

    message: string;
    icon_existence = true;
    custom_icon: string;
    icon_color: string;
    handlers: any;

    modalSubscription$: Subscription;

    backward_btn: any;
    i18n: any = {};

    constructor(
        private sharedService: SharedService,
        private platModalService: PlatformModalsService,
        private element: ElementRef,
    ) {}

    ngAfterViewInit(): void {
    // gets subscriptions and reloads the modal
        this.subscribeToModalService();
    }

    OnInit(): void {
        this.getI18n();
        this.getParamsAndHandlers();
        this.processMessage();
        this.getBackwardBtn();
    }

    subscribeToModalService() {
        this.modalSubscription$ = this.platModalService.getModalState('message').pipe(delay(0)).subscribe((boolean) => {
            if (boolean) this.OnInit();
            this.generic_modal.toggle(boolean);
        });
    }

    getI18n() {
        this.i18n.modal = { ...this.sharedService.getTranslationsOf('Modal') };
        this.i18n.errors = { ...this.sharedService.getTranslationsOf('Errors') };
    }

    getParamsAndHandlers() {
        this.message = this.platModalService.messageModal.message;

        this.custom_icon = this.platModalService.messageModal.custom_icon.length
            ? this.platModalService.messageModal.custom_icon
            : 'zondicons:exclamation-solid';// default icon;

        this.icon_color = this.platModalService.messageModal.icon_color
            ? this.platModalService.messageModal.icon_color
            : '#233674';

        this.icon_existence = this.platModalService.messageModal.icon_existence;
        this.handlers = this.platModalService.messageModal.handlers;
    }

    getBackwardBtn() {
        const buttonMesssage = this.platModalService.messageModal.button_foward;

        if (buttonMesssage) {
            this.backward_btn = buttonMesssage;
            return;
        }
        this.backward_btn = this.i18n
            .modal
            .btns_generic
            .split('|')
            .filter((str: string) => str.includes('backward'))[0]
            .split(':')[1]
            .trim();
    }

    processMessage() {
        if (this.message && this.i18n.errors[this.message]) this.message = this.i18n.errors[this.message];
    }

    emitClose($event) {
        $event.stopPropagation();

        this.handlers.finally();
    }

    ngOnDestroy() {
        if (this.modalSubscription$) this.modalSubscription$.unsubscribe();
    }
}
