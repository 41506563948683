<div class="w-full fixed top-1/5 left-1/4" style="z-index:99;" cdkDrag>
    <div class="w-1/2 absolute bg-customWhite-default rounded-xl">
      <!--content-->

        <div  class="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none" style="border: 1px solid #233674;">
          <!--header-->
          <div class="absolute right-2 p-2">
            <!-- <button ><fa-icon style="color:#A9AEB3;font-size:1.5em;"  [icon]="['fal', 'arrows']"></fa-icon></button> -->
            <!-- <button (click)="closeModal()" class="ml-5"><fa-icon style="color:#A9AEB3;font-size:1.5em;" [icon]="['fal', 'times-circle']"></fa-icon></button> -->
          </div>
          <div class="text-center p-5 border-b border-solid border-customBlue-light rounded-t">
            <h3 class="text-xl font-bold text-customBlue-default">
              {{ i18n.translation_editor_title }}
            </h3>
            <p class="text-center text-sm text-customBlue-default w-2/3 mx-auto mt-3">
              {{i18n.translation_editor_description}}
            </p>
          </div>

          <div class="header-icons">
            <div class="flex items-center gap-2">
              <div cdkDragHandle>
                <span class="iconify icon-presets btn-move text-customGray-default" data-icon="bi:arrows-move"></span>
              </div>
              <button (click)="closeModal()">
                <span class="iconify icon-presets btn-close text-customGray-default" data-icon="ant-design:close-circle-outlined"></span>
              </button>
            </div>
          </div>
          <!--body-->
          <div class="relative p-6">
            <div class="flex gap-3">
              <div class="flex items-center w-11/12 rounded" style="border: 1px solid #A9AEB3;">
                  <input class="p-1 rounded-full w-full outline-none" (keyup.enter)="searchTranslations()" [placeholder]="i18n.translation_editor_search_for_translation" type="text" name="query" ngDefaultControl [(ngModel)]="query">
                  <div (click)="searchTranslations()" class="mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="#8F9BB3">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                      </svg>    
                  </div>
              </div>
              
              <div class="flex justify-center" >
                <div>
                  <select
                    class="rounded-md select-button"
                    (change)="changeLanguage()" 
                    [(ngModel)]="selectedlanguage"
                  >
                    <option *ngFor="let language of languages"  [ngValue]="language">
                      {{language?.name}}
                    </option>
                  </select>
                </div>
                <!-- <select class="rounded-md" style="border: 1px solid #A9AEB3; background-color: transparent;" (change)="changeLanguage()" [(ngModel)]="selectedlanguage">
                    <option *ngFor="let language of languages"  [ngValue]="language">
                        {{language?.name}}
                    </option>
                </select>   -->
              </div>               
              <div #btnelm (click)="clickInside($event)" class="flex justify-center">
                  <button class="rounded-md " >
                    <div [ngClass]="{ 'isOpen isOpenIcon' : filterOpen, 'isClose isCloseIcon' : !filterOpen}" class="p-2 rounded" >
                        <span class="iconify icon-presets" data-icon="carbon:filter"></span>
                    </div>
                  </button>
                  <p-overlayPanel #op [style]="{width: '450px'}">
                      <ng-template pTemplate>
                        <div class="py-2">
                          <label class="text-md font-bold text-customGray-dark">{{i18n.translation_editor_group}}</label>
                          <select class="w-full border border-customGray-default p-1 rounded-md" (change)="changeGroup()" [(ngModel)]="selectedGroup" style="background-color: transparent;" >
                            <option [value]="">

                            </option>
                              <option *ngFor="let group of groups" [ngValue]="group">
                                  {{group}}
                              </option>
                          </select>
                        </div>
                        <div class="py-2">
                          <label class="text-md font-bold text-customGray-dark mt-2">{{i18n.translation_editor_size_per_page}}</label>
                          <select class="w-full border border-customGray-default p-1 rounded-md" [(ngModel)]="per_page" style="background-color: transparent;" >
                              <option *ngFor="let per_page of [1,2,3,4,5,6,7,8,9,10]" [ngValue]="per_page">
                                  {{ per_page }}
                              </option>
                          </select>   
                        </div>
                      </ng-template>
                  </p-overlayPanel> 
              </div>            
            </div>
            <form [formGroup]="translationForm">           
              <div *ngIf="translations.length == 0" class="flex justify-center items-center">
                  <span>{{i18n.translation_editor_translations_not_found}}</span>
              </div>
              <div *ngIf="translations.length > 0">
                <div class="grid grid-cols-3 text-center gap-4 py-3">
                  <div class="text-md font-bold text-customGray-dark">
                    {{i18n.translation_editor_group}}
                  </div>
                  <div class="text-md font-bold text-customGray-dark">
                    {{i18n.translation_editor_translation}}
                  </div>
                  <div class="text-md font-bold text-customGray-dark">
                    {{i18n.translation_editor_new_translation}}
                  </div> 
                </div>
                <div style="overflow-y: scroll; height: 35vh">
                  <div class="flex items-center grid grid-cols-3 text-center gap-4 py-1" *ngFor="let translation of translations | filter:filterTerm; let i=index;">
                    <span class="text-md text-customGray-dark">
                      {{translation?.group}}    
                    </span>
                    <span class="text-md text-customGray-dark">
                      {{translation?.text}}    
                    </span>
                    <div class="rounded p-1" style="border: 1px solid #A9AEB3;">
                      <input [formControlName]="translation?.item" id="{{translation?.id}}" class="w-full text-md text-customGray-dark" (keyup)="onKey()" placeholder="" />
                    </div>     
                  </div>            
                </div>
                
                <div class="mt-8 flex justify-center" *ngIf="translations.length">
                    <app-pagination
                    #pagination
                    class="pagination"
                    [params]="paginationParams"
                    (navigation)="goToPage($event)"
                    ></app-pagination>
                </div>
              </div>                      
            </form>        
          </div>
          <!--footer-->
          <div *appCheckPermissions="'translations';type:'edit'" class="flex row justify-center pb-4">
            <button class="flex border rounded-md py-2 px-8 font-bold text-sm border-customGray-textGray" [ngClass]="{'disabled:opacity-20 cursor-not-allowed': !buttonUnlocked}" style="color: #FFFFFF;background-color: #233674" (click)="patchGeneralSettings()" [disabled]="!buttonUnlocked">
                <span *ngIf="!processing">{{i18n.translation_editor_save}}</span>
                <div *ngIf="processing">
                    <!-- <fa-icon style="font-size: 1.5em;" class="mr-2"
                        [icon]="['fad', 'spinner-third']"
                        [styles]="{ stroke: '#CCCCC', color: '#FFF' }"
                        [spin]="true"
                    ></fa-icon>  -->
                </div> 
                <span *ngIf="processing">{{i18n.translation_editor_processing}}</span>
            </button>
            <button class="text-customGray-textGray background-transparent font-semibold px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="closeModal()">
                {{i18n.translation_editor_cancel}}
            </button>                
          </div>
        </div>
    </div>
</div>

<!-- Modal de confirmação de ação -->
<app-modal-confirmation *ngIf="modalConfirmation$ | async"></app-modal-confirmation>

<p-confirmDialog #cd [style]="{width: '25vw'}" [transitionOptions]="'100ms'">
  <ng-template pTemplate="footer" class="text-center">
      <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"></button>
      <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
<p-dialog header="Erro" [(visible)]="displayBasic" [style]="{width: '50vw'}" [baseZIndex]="10000">
    <p>{{textError}}</p>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayBasic=false" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
