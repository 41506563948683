import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomTreeSelectModule } from '../custom-tree-select/custom-tree-select.module';
import { CustomOuTreeSelectComponent } from './custom-ou-tree-select.component';
import { InfoModalModule } from '../modals/info-modal/info-modal.module';

@NgModule({
    declarations: [
        CustomOuTreeSelectComponent
    ],
    imports: [
        CommonModule,
        CustomTreeSelectModule,
        InfoModalModule
    ],
    exports: [
        CustomOuTreeSelectComponent
    ]
})
export class CustomOuTreeSelectModule { }
